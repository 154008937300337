import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../lib/api.service';
import { User } from '../../../models/user';
import { UserService } from '../../../lib/user.service';
import { LogService } from '../../../lib/log.service';
import { AnalyticEvent } from 'app/lib/analytic-event';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ActionPlanList } from 'app/models/action-plan';
import { UtilityService } from 'app/lib/utility.service';
import { AssignEventList } from 'app/models/event';
import { ModalService } from 'app/lib/modal.service';

@Component({
    selector: 'app-action-plan-view',
    templateUrl: './action-plan-view.component.html',
    styleUrls: ['./action-plan-view.component.scss'],
    providers: [DatePipe],
})
export class ActionPlanViewComponent implements OnInit {
    loggedInUser: User;
    user: User;
    isLoaded: boolean = false;
    demographicDetail: any;
    id: number;
    paramsSub: any;
    actionPlanList: ActionPlanList[] = [];
    actionPlan: ActionPlanList[] = [];
    actionPlanDataCount: number = 0;
    @Input() countData: any;
    @Input() demographicData: any;
    @Input() userDetail: any;
    @Input() userActionPlans: any;
    showEventAssignModal: boolean = false;
    tabselecteddays: string = '30';
    activedays: string = '30';
    popups: any;
    assignedList: any;
    completeList: any;
    copingList: any;
    warningList: any;
    dateRange = [
        {
            title: 'All',
            value: 0,
        },
        {
            title: 'Last 30 days',
            value: 30,
        },
        {
            title: 'Last 60 days',
            value: 60,
        },
        {
            title: 'Last 90 days',
            value: 90,
        },
    ];
    dateRangeSelected: any;
    currentDate: Date;
    rangeDate: Date;
    showCompleted: boolean = false;
    showAssigned: boolean = true;
    showCoping: boolean = true;
    showWarning: boolean = false;
    assignedListLoaded: boolean = false;
    actionPlanDataLoaded: boolean = false;
    copingAndWarningListLoaded: boolean = false;
    constructor(
        private api: ApiService,
        private log: LogService,
        private translate: TranslateService,
        private userService: UserService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private utilityService: UtilityService,
        private modalService: ModalService
    ) {
        this.loggedInUser = this.userService.getUser();
        this.currentDate = new Date();
        this.rangeDate = new Date(
            new Date().setDate(this.currentDate.getDate() - 30)
        );
        this.translate.stream('actionPlan').subscribe((res: any) => {
            this.popups = res.popups;
        });
    }

    onLanguageChange() {
        this.translate.stream('lang').subscribe((res: any) => {
            if (res === 'en') {
                this.getAssignedList();
            } else {
                this.getAssignedList();
            }
        });
    }

    ngOnInit(): void {
        this.paramsSub = this.activatedRoute.params.subscribe(params => {
            this.id = parseInt(params['id'], 10);
            this.utilityService.userDetailsPageDayFilter.subscribe(data => {
                if (data != this.dateRangeSelected) {
                    this.dateRangeSelected = data;
                    this.onRangeChange(false);
                }
            });
            this.onLanguageChange();
        });
    }

    setdayschart(tab: string) {
        if (this.user.userType != 'user') {
            if (this.activedays !== tab) {
                this.activedays = tab;
                this.tabselecteddays = tab;
            }
        }
    }

    getInitials(name): string {
        return this.utilityService.getInitials(name);
    }

    onRangeChange(setFilter: boolean) {
        this.rangeDate = new Date(
            new Date().setDate(
                this.currentDate.getDate() - this.dateRangeSelected
            )
        );
        setFilter &&
            this.utilityService.setUserDetailsPageDayFilterSubject(
                this.dateRangeSelected
            );
        this.getactionPlanDataCount(this.userActionPlans);
        this.getCopingAndWarningList(this.dateRangeSelected);
    }

    getactionPlanDataCount(userActionPlans) {
        this.actionPlanDataLoaded = true;
        if (userActionPlans.length != 0) {
            this.actionPlanList = userActionPlans;
            this.getCurrentMonthActionPlanLength(this.dateRangeSelected);
            this.log.event(AnalyticEvent.event.actionPlanListingView);
        }
    }

    getCurrentMonthActionPlanLength(dayRange: number = 30): void {
        if (dayRange != 0) {
            const currentDate = new Date();
            const pastDateForActionPlan = new Date();
            pastDateForActionPlan.setDate(currentDate.getDate() - dayRange);

            const actionPlansWithinRange = this.actionPlanList.filter(
                actionPlan => {
                    const actionPlanDate = new Date(actionPlan.created);
                    return (
                        actionPlanDate >= pastDateForActionPlan &&
                        actionPlanDate <= currentDate
                    );
                }
            );
            const startOfMonth = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                1
            );
            const endOfMonth = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() + 1,
                0
            );
            const actionPlansForDataCount = this.actionPlanList.filter(
                actionPlan => {
                    const actionPlanDate = new Date(actionPlan.created);
                    return (
                        actionPlanDate >= startOfMonth &&
                        actionPlanDate <= currentDate
                    ); // Only current month
                }
            );

            this.actionPlan = actionPlansWithinRange;
            this.actionPlanDataCount = actionPlansForDataCount.length || 0;
        } else {
            this.actionPlan = this.actionPlanList;
            this.actionPlanDataCount = this.actionPlanList.length || 0;
        }
    }

    getCopingAndWarningList(dayRange) {
        let payload = {
            limit: 4,
        };

        if (dayRange != 0) {
            payload['From'] = moment()
                .subtract(dayRange, 'days')
                .format('YYYY-MM-DD');
        }
        this.copingAndWarningListLoaded = false;
        this.api
            .get(`syncheduser/actionplan/copingskills/` + this.id, payload)
            .subscribe(
                (result: any) => {
                    this.copingAndWarningListLoaded = true;
                    if (result) {
                        this.copingList = result.copingskils;
                        this.warningList = result.warningsigns;
                    }
                },
                error => {
                    this.copingAndWarningListLoaded = true;
                    this.log.error(
                        'Error getting action plan. ' + error.message
                    );
                }
            );
    }

    getAssignedList() {
        this.assignedListLoaded = false;
        this.api
            .get(
                `syncheduser/assignments/` +
                    this.id +
                    `?type=actionplan` +
                    `&limit=10`
            )
            .subscribe(
                (result: any) => {
                    this.assignedList = AssignEventList.initializeArray(
                        result.assigned
                    );
                    this.completeList = AssignEventList.initializeArray(
                        result.completed
                    );
                    this.isLoaded = true;
                    this.assignedListLoaded = true;
                },
                (error: any) => {
                    this.log.error(
                        'Error getting assessment questions. ' + error.message
                    );
                    this.isLoaded = true;
                    this.assignedListLoaded = true;
                }
            );
    }

    onTabChanged($event) {
        let tabSelected = $event.index;
        if (tabSelected == 0) {
            this.showAssigned = true;
        } else {
            this.showCompleted = true;
        }
    }

    onTabChangedList($event) {
        let tabSelected = $event.index;
        if (tabSelected == 0) {
            this.showCoping = true;
        } else {
            this.showWarning = true;
        }
    }

    goToActionPlan(plan?: any) {
        let url = '/app/practice/action-plan/create';
        this.router.navigate([url], { queryParams: { userId: this.id } });
    }

    previewActionplan(plan: any) {
        this.router.navigate(['/app/practice/action-plan/preview/' + plan.id], {
            queryParams: { userId: this.id },
        });
    }

    onCloseOptions(event: any) {
        this.showEventAssignModal = false;
        event && this.getAssignedList();
        setTimeout(function () {
            jQuery('#moodcheck-modal .featured:visible').focus();
        }, 500);
    }

    showAssignModal(val) {
        this.showEventAssignModal = val;
    }

    delete(aId) {
        this.modalService
            .showConfirmation(
                'Delete',
                'Are you sure you want to delete this assignment?'
            )
            .afterClosed()
            .subscribe(result => {
                if (result) {
                    this.api
                        .delete(
                            `syncheduser/assignment/delete/` +
                                aId +
                                `?SynchedUserID=` +
                                this.id
                        )
                        .subscribe(
                            (result: any) => {
                                this.getAssignedList();
                                this.isLoaded = true;
                                this.assignedListLoaded = true;
                                this.modalService.showAlert(
                                    'Success',
                                    'Assignment deleted successfully'
                                );
                            },
                            (error: any) => {
                                this.log.error(
                                    'Error getting delete.' + error.message
                                );
                                this.isLoaded = true;
                            }
                        );
                }
            });
    }
}
