import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ApiService } from '../../lib/api.service';
import { StorageService } from '../../lib/storage.service';
import { LogService } from '../../lib/log.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ModalService } from '../../lib/modal.service';
import { AssessmentAlert } from '../../components/assessment-alert/assessment-alert';
import { AssessmentResilienceAlert } from '../../components/assessment-resilience-alert/assessment-resilience-alert';
import { AssessmentCoreContributorAlert } from '../../components/assessment-core-contributor-alert/assessment-core-contributor-alert';
import { User } from '../../../app/models/user';
import { Organization } from '../../../app/models/organization';
import { Assessment } from '../../../app/models/assessment';
import { UserService } from '../../../app/lib/user.service';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import * as jQuery from 'jquery';
import { Subscription } from 'rxjs';
import { AssessmentService } from 'app/lib/assessment.service';
import { AnalyticEvent } from 'app/lib/analytic-event';

interface Answer {
    isAnswered: boolean;
    value: number | string; // Supports single number or text input
    values: Record<string, number>; // Object to store selected checkbox values
    name: any;
    id: any;
    type: any;
    bufferIncremented: boolean;
    fieldDisabled: boolean;
}

@Component({
    selector: 'app-mental-wellbeing-assessment',
    templateUrl: './mental-wellbeing-assessment.component.html',
    styleUrls: ['./mental-wellbeing-assessment.component.scss'],
})
export class MentalWellbeingAssessmentComponent implements OnInit {
    isLoaded = false;
    questions: Array<Object> = [];
    answers: Answer[] = [];
    active = 0;
    count = 0;
    alerts: string;
    user: User;
    popup: any;
    quizType: string = 'dass';
    showExplain: boolean = false;
    showScore: boolean = false;
    showIntro: boolean = false;
    assessment: Assessment;
    organization: Organization;
    forced: boolean;
    submitting: boolean;
    title: string;
    back: string;
    backLink: string;
    cssClass: string;
    headerImage: string;
    resiliencyLevel: string;
    resiliencyDescription: string;
    alert: boolean;
    buffer = 0;
    progress = 0;
    progressValue: any;
    optionType: any;
    showAnimation: boolean = false;
    typeAssessment: string;
    assessmenttranslation: any;
    returnPath: any;
    showEmptyHeader: boolean = false;
    existingGoal: string = '';
    goalQuestionIndex: number = -1;
    initalAssessmentQuestionIndex: number = -1;
    isInitalAssessment: number;
    selectedStarIndex: number = 0;
    rating: number = 0;
    goalSubscription: Subscription;
    textAreaValue = '';
    endUserId: any;
    popups: any;
    constructor(
        private api: ApiService,
        private modalService: ModalService,
        private log: LogService,
        private storage: StorageService,
        private router: Router,
        private userService: UserService,
        private activatedRoute: ActivatedRoute,
        private translate: TranslateService,
        private route: ActivatedRoute,
        private location: Location,
        public assessmentService: AssessmentService
    ) {
        this.user = this.userService.getUser();
        this.submitting = false;
        this.route.queryParams.subscribe(params => {
            this.endUserId = params['userId'];
        });
        this.goalSubscription =
            this.assessmentService.MWAcurrentGoalSubject.subscribe(data => {
                if (data) {
                    this.existingGoal = data;
                }
            });
    }

    ngOnInit() {
        this.translate.stream('error').subscribe((res: any) => {
            this.popup = res.title;
        });
        this.translate
            .stream('dynamicAssessment.mentalwellbeing.popup')
            .subscribe((res: any) => {
                this.popups = res;
            });
        this.isInitalAssessment = this.user.mentalWellbeingInitial;
        this.activatedRoute.params.subscribe((params: Params) => {
            if (params['assessment']) {
                this.quizType = params['assessment'];
            }
            this.translate
                .stream('assessments.' + this.quizType)
                .subscribe((res: any) => {
                    this.assessmenttranslation = res;
                    this.title = res.title;
                    this.back = res.back;
                });

            if (this.quizType === 'mentalwellbeing') {
                this.activatedRoute.queryParams.subscribe(queryParams => {
                    this.forced = queryParams['forced'];
                    this.returnPath = queryParams['path'];
                });
                if (this.returnPath === 'onboarding') {
                    this.showEmptyHeader = true;
                }
            }
        });

        this.questions = [];
        this.answers = [];
        this.api.get('/form/' + this.user.mentalWellbeingAssessment).subscribe(
            (result: any) => {
                const objs = result.data;
                objs.forEach((obj, oID) => {
                    if (
                        this.isInitalAssessment &&
                        ['rating-question', 'goaloption-question'].includes(
                            obj[0].Group
                        )
                    ) {
                        return;
                    }
                    obj[0]['questionType'] = obj[0].Type;
                    obj[0]['isAnswered'] = false;
                    obj[0]['showExistingGoal'] = ['rating-question'].includes(
                        obj[0].Group
                    );
                    obj[0].options.forEach((option, index) => {
                        option['checked'] = 0;
                    });
                    this.questions.push(obj[0]);
                    if (
                        obj[0].Group === 'goal-question' &&
                        this.goalQuestionIndex === -1
                    ) {
                        this.goalQuestionIndex = oID;
                    }
                    if (
                        obj[0].Group === 'assessment-question' &&
                        this.initalAssessmentQuestionIndex === -1
                    ) {
                        this.initalAssessmentQuestionIndex = oID;
                    }
                    this.answers.push({
                        isAnswered: false,
                        value: obj[0].Type === 5 ? '' : -1,
                        values: {},
                        name: obj[0].Type === 5 ? obj[0].options[0].ID : -1,
                        id: obj[0].ID,
                        type: obj[0].Type,
                        fieldDisabled: false,
                        bufferIncremented: false,
                    });
                    this.count++;
                });
                this.isLoaded = true;
                this.progressValue = 100 / this.count;
                this.progress = this.progressValue;
            },
            (error: any) => {
                this.log.event(
                    AnalyticEvent.event.assessmentQuestionsFetchError
                );
                this.log.error(
                    'Error getting assessment questions. ' + error.message
                );
            }
        );
    }

    setGoalData(setExistingGoal: boolean) {
        this.answers[this.goalQuestionIndex].value = setExistingGoal
            ? this.existingGoal
            : '';
        this.textAreaValue = setExistingGoal ? this.existingGoal : '';
        this.answers[this.goalQuestionIndex].fieldDisabled = setExistingGoal
            ? true
            : false;
        if (
            this.answers[this.goalQuestionIndex].value &&
            this.answers[this.goalQuestionIndex].fieldDisabled
        ) {
            this.answers[this.goalQuestionIndex].isAnswered = true;
        } else {
            this.answers[this.goalQuestionIndex].isAnswered = false;
        }
    }

    onSubmit() {
        if (!this.answers[this.active]['value']) {
            return;
        }
        let answerData = [...this.answers];
        answerData = answerData.filter(
            (obj: Answer) => 'id' in obj && obj.id !== 'none'
        );
        // this.answers.forEach(answer => {
        //     delete answer.bufferIncremented;
        // });
        this.submitting = true;
        this.showAnimation = true;
        this.api
            .post('assessment/save', {
                Type: this.quizType,
                Data: JSON.stringify(answerData),
                OrgQuestions: {},
            })
            .subscribe(
                (results: any) => {
                    this.api.get('users/me').subscribe(
                        (result: any) => {
                            this.showAnimation = false;
                            this.assessment = new Assessment(results.data);
                            this.log.event(
                                AnalyticEvent.event
                                    .mentalwellbeingAssessmentSubmitted
                            );
                            this.user.mentalWellbeingShowForm = false;
                            this.user = new User(result.data);
                            this.userService.setUser(this.user);
                            this.router.navigate([
                                '/app/assessment/result',
                                this.assessment.id,
                                this.assessment.type,
                            ]);
                            this.submitting = false;
                        },
                        (error: any) => {
                            this.log.error(
                                'Error fetching user profile: ' + error.message
                            );
                        }
                    );
                },
                (error: any) => {
                    this.log.event(AnalyticEvent.event.assessmentSubmitError);
                    this.modalService.showAlert(this.popup, error.message);
                    this.log.error('Error registering. ' + error.message);
                    this.submitting = false;
                }
            );
    }

    scrollToTop() {
        let element = document.getElementById('page-assessment');
        element.scrollIntoView();
    }

    setNextActive() {
        const isBeforeInitialAssessment =
            !this.showIntro &&
            this.active + 1 === this.initalAssessmentQuestionIndex;
        const isAtInitialAssessment =
            this.showIntro &&
            this.active === this.initalAssessmentQuestionIndex;
        if (isBeforeInitialAssessment) {
            this.showIntro = true;
        } else if (isAtInitialAssessment) {
            this.showIntro = false;
        } else {
            this.active = this.active + 1;
            this.showIntro = false;
            if (this.buffer < this.progress) {
                this.buffer += this.progressValue;
            }
            this.progress += this.progressValue;
        }
    }

    setIntro(intro) {
        this.showIntro = intro;
        // this.focusOnFirst();
    }

    setPreviousActive() {
        if (this.active === 0) {
            this.showIntro = false;
            return;
        } else {
            if (
                !this.showIntro &&
                this.active == this.initalAssessmentQuestionIndex
            ) {
                this.showIntro = true;
            } else {
                this.showIntro = false;
                this.active = this.active - 1;
                this.progress -= this.progressValue;
            }
        }
    }

    isSelected(index: number, optionID: string): boolean {
        return (
            this.answers[index]?.values[optionID] !== undefined &&
            this.answers[index].values[optionID] !== 0
        );
    }

    isAnyOptionSelected(index: number): boolean {
        return Object.values(this.answers[index]?.values || {}).some(
            value => value === 1
        );
    }

    /*
        question position, answer position,  type of question (1,2), question ID
        type 1: single answer
        type 2: multiple answers
        type 3: yes/no
    */
    onSelectionChange(i: number, value: any, question: any, optionID: string) {
        this.optionType = optionID;
        if (question.questionType === 1) {
            // Single-select (radio)
            this.answers[i].value = value;
            this.answers[i].name = optionID;
            this.answers[i].isAnswered = true;
            if (question.Group == 'goaloption-question') {
                this.setGoalData(value < 2);
            }
            !['rating-question'].includes(question.Group) &&
                setTimeout(() => {
                    this.setNextActive();
                }, 100);
        } else if (question.questionType === 2) {
            // Multi-select (checkbox)
            if (this.answers[i].values[optionID]) {
                delete this.answers[i].values[optionID];
            } else {
                this.answers[i].values[optionID] = value;
            }
            this.answers[i].isAnswered =
                Object.keys(this.answers[i].values).length > 0;
        } else if (question.questionType === 5) {
            // Text input
            this.answers[i].value = value;
            this.answers[i].name = optionID;
            this.answers[i].isAnswered = !!value;
        } else {
            // Other types
            this.answers[i].value = value;
            this.answers[i].name = optionID;
            this.answers[i].isAnswered = value ? true : false;
        }

        // Only increment the buffer the first time the question is marked as answered
        // if (this.answers[i].isAnswered && this.answers[i].bufferIncremented !== true) {
        //     this.buffer += this.progressValue;
        //     this.answers[i].bufferIncremented = true; // Avoid re-incrementing
        // };
    }

    checkAllAnswered() {
        let hasItem = this.answers.some(
            (item: Answer) => item.isAnswered == false
        );

        // let hasItem = !this.answers.some((item: Answer) => !item.isAnswered || !this.answers[this.goalQuestionIndex]?.value);
        return hasItem ? false : true;
    }

    onCancel() {
        this.router.navigateByUrl('/app/assessment');
    }

    goBack() {
        if (this.returnPath === 'onboarding') {
            this.router.navigate(['/app']);
        } else {
            this.location.back();
        }
    }

    ngOnDestroy() {
        if (this.goalSubscription) {
            this.goalSubscription.unsubscribe();
        }
    }

    backList() {
        this.modalService
            .showConfirmation(
                this.popups.exit,
                this.popups.exitMsg,
                this.popups.yes,
                this.popups.no
            )
            .afterClosed()
            .subscribe(result => {
                if (result) {
                    this.isLoaded = false;
                    if (this.user.userType != 'user' && this.endUserId) {
                        let url =
                            '/professional/user-details-dashboard/' +
                            this.endUserId;
                        this.router.navigate([url]);
                    } else {
                        let url =
                            '/app/assessment/' + this.quizType + '/listing';
                        this.router.navigate([url]);
                    }
                }
            });
    }
}
