import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ApiService } from "../lib/api.service";
import { AuthService } from "../lib/auth.service";
import { UserService } from "../lib/user.service";
import { ModalService } from "app/lib/modal.service";

@Injectable()
export class SeriesAccessGuard implements CanActivate {
  popups: any;
  constructor(
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private modalService: ModalService,
    private translate: TranslateService,
    private api: ApiService,
    // private loader: CustomLoaderService
  ) {
    this.translate.stream("theory").subscribe((res: any) => {
      this.popups = res.popups;
    });
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let url: string = state.url;
    let courseKey = route.paramMap.get("series");
    return this.checkSeriesAccess(courseKey).pipe(
      map((hasAccess: boolean) => {
        console.log("hasAccess", hasAccess);
        return hasAccess;
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  checkSeriesAccess(courseKey: string): Observable<boolean> {
    if (!this.authService.isAuthenticated()) {
      this.router.navigate(["/login"]);
      return of(false);
    }
    const user = this.userService.getUser();
    if (!user) {
      this.router.navigate(["/login"]);
      return of(false);
    }
    const primaryOrganization = user.primaryOrganization;
    if (primaryOrganization?.id) {
      const courses = primaryOrganization.courses || [];
      if (courses.length > 0) {
        const isCourseEnabled = courses.some(
          (item) => item.key === courseKey && item.enabled
        );

        if (isCourseEnabled) {
          return of(true);
        }
        this.modalService.showAlert(
          this.popups.alert,
          this.popups.notEnabledOrgSeriesMsg,
        );
        return of(false);
      }
    }
    return this.getDefaultCourses(courseKey);
  }

  getDefaultCourses(courseKey: string): Observable<boolean> {
    // this.loader.show();
    return this.api.get("series/serieslisting/defaults").pipe(
      map((result: any) => {
        // this.loader.close();

        const isCourseEnabled = result.data?.some(
          (item) =>
            item.CourseKey === courseKey && item.DefaultCourse && item.Default
        );

        if (isCourseEnabled) {
          return true;
        }

        this.modalService.showAlert(
          this.popups.alert,
          this.popups.notEnabledOrgSeriesMsg,
        );
        return false;
      }),
      catchError(() => {
        // this.loader.close();
        return of(false);
      })
    );
  }
}
