<div id="public-portal-wrapper" class="main-login-container">
    <div class="alert-announcement" translate>
        loginDesktop.reBrandHelpTitle
        <a [routerLink]="['/rename']" translate>loginDesktop.here</a>.
    </div>
    <!--  <div class="top-bar"></div> -->
    <div
        class="header-col"
        [ngClass]="{ navIn: navIn }"
        role="main"
        aria-labelledby="welcome">
        <h1 id="welcome" class="wt-access" translate>accessCode.title</h1>
        <nav class="navigation-menu">
            <div class="logo">
                <a
                    tabindex="0"
                    routerLink="/"
                    aria-label="App page"
                    alt="App page"
                    title="App page">
                    <img
                        alt="Welltrack Boost"
                        src="/assets/img/welltrack-logos/welltrackboost-logo-new.svg"
                        alt="WellTrack Boost"
                        class="portal-logo" />
                </a>
            </div>
            <ul class="header-contents login-header-container">
                <li>
                    <svg-icon icon="web-icon"></svg-icon>
                    <a
                        class="wt-auto-public-site"
                        tabindex="0"
                        aria-label="Visit welltrack-boost.com"
                        title="Visit welltrack-boost.com"
                        alt="Visit Welltrack.com"
                        href="http://welltrack-boost.com"
                        translate
                        >loginDesktop.full</a
                    >
                </li>
                <li>
                    <svg-icon icon="support-icon"></svg-icon>
                    <a
                        class="wt-auto-contact-us"
                        tabindex="0"
                        title="Contact us"
                        alt="Contact us"
                        (click)="onShowSupportPage()"
                        (keypress)="onShowSupportPage()"
                        translate
                        >loginDesktop.contact</a
                    >
                </li>
                <li class="dropdown-list-section">
                    <svg-icon *ngIf="showLanguage" icon="lang-icon"></svg-icon>
                    <language-dropdown
                        *ngIf="showLanguage"
                        [color]="'lightpurple'"
                        [page]="'public'"
                        class="login-language-dropdown-section"></language-dropdown>
                </li>
            </ul>
        </nav>
    </div>
    <main
        [ngClass]="containerClass"
        role="main"
        aria-labelledby="section-title"
        class="inputs-section"
        [ngClass]="{ 'main-container-without-footer': !showBottom }">
        <div class="form-container">
            <router-outlet
                (click)="onNavClose()"
                (keypress)="onNavClose()"></router-outlet>
        </div>
    </main>
    <footer class="bottom-container" *ngIf="showBottom">
        <div class="download-holder">
            <div class="mobile-image-container">
                <img
                    class="screen-cap"
                    alt="Mobile app"
                    src="/assets/img/login-phone-img.svg" />
            </div>
            <div class="mobile-download">
                <h3 class="app-c-body-text--14-m" translate>
                    loginDesktop.download
                </h3>
                <div class="download-icons">
                    <a
                        tabindex="0"
                        *ngIf="playStore"
                        target="_blank"
                        class="wt-auto-google-store google"
                        href="{{ playStore }}">
                        <img
                            alt="Get it on Google Play"
                            src="/assets/img/icons/google-play-badge-new.svg" />
                    </a>
                    <a
                        tabindex="0"
                        *ngIf="appStore"
                        target="_blank"
                        class="wt-auto-apple-store apple"
                        href="{{ appStore }}">
                        <img
                            alt="Download on the App Store"
                            src="/assets/img/icons/app-store-badge-new.svg" />
                    </a>
                </div>
            </div>
        </div>
    </footer>
</div>
