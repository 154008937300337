<div class="app-l-page-wrap">
    <div class="app-l-main-heading">
        <h2>Action Plan</h2>
        <div class="app-l__actions-block">
            <button
                *ngIf="assignedListLoaded"
                (click)="showAssignModal('true')"
                class="app-c-btn app-c-btn--primary-v2 app-c-btn--curve"
                translate>
                <img
                    alt="Assign"
                    src="./assets/img/icons-svg/assign-user-icon.svg" />clientList.assign
            </button>
            <div class="app-c-mat-select" *ngIf="actionPlanDataLoaded">
                <img alt="Assign" src="./assets/img/icons-svg/calendarV1.svg" />
                <mat-select
                    [(ngModel)]="dateRangeSelected"
                    class="app-l-dsh__rfp-select"
                    panelClass="app-l-dsh__rfp-panel"
                    (selectionChange)="onRangeChange(true)">
                    <mat-option
                        value="proud"
                        *ngFor="let days of dateRange"
                        translate
                        [value]="days.value"
                        >{{ days.title }}</mat-option
                    >
                </mat-select>
            </div>
        </div>
    </div>
    <div class="title-section">
        <page-user-event-assign
            *ngIf="showEventAssignModal"
            [eventType]="'actionplan'"
            [selectedUser]="userDetail"
            [disableUserList]="true"
            [disableEventList]="true"
            class="app-l-modecheck__sidepanel"
            [userList]="userDetail"
            (closeModal)="onCloseOptions($event)"></page-user-event-assign>
    </div>
    <div class="app-dm-das-assessment-list">
        <div class="app-l-summary__base">
            <div class="app-l-result-summary">
                <div class="app-l-result__card-dm">
                    <div class="app-c-card__header">
                        <div class="title-section app-l-card__h-left">
                            <h2 class="initial-image">
                                {{ getInitials(userDetail?.fullName) }}
                            </h2>
                            <h4>
                                {{ userDetail?.fullName }}<br />
                                <p>{{ userDetail?.email }}</p>
                            </h4>
                        </div>
                    </div>
                    <div class="demo-result-card">
                        <div class="demo-val">
                            <p translate>clientList.age</p>
                            <h4>
                                {{
                                    demographicData?.Age
                                        ? demographicData?.Age
                                        : '-'
                                }}
                            </h4>
                        </div>
                        <div class="demo-val">
                            <p translate>clientList.ethinicity</p>
                            <h4>
                                {{
                                    demographicData?.Ethnicity
                                        ? demographicData?.Ethnicity
                                        : '-'
                                }}
                            </h4>
                        </div>
                        <div class="demo-val">
                            <p translate>clientList.gender</p>
                            <h4>
                                {{
                                    demographicData?.Gender
                                        ? demographicData?.Gender
                                        : '-'
                                }}
                            </h4>
                        </div>
                        <div class="demo-val">
                            <p translate>clientList.gradLevel</p>
                            <h4>
                                {{
                                    demographicData?.GradeLevel
                                        ? demographicData?.GradeLevel
                                        : '-'
                                }}
                            </h4>
                        </div>
                        <div class="demo-val">
                            <p translate>clientList.referredBy</p>
                            <h4>
                                {{
                                    demographicData?.Referred
                                        ? demographicData?.Referred
                                        : '-'
                                }}
                            </h4>
                        </div>
                    </div>
                </div>
                <div class="app-l-result__card-dm">
                    <div class="app-l-result__card-header">
                        <h3 class="app-c-heading--h3" translate>
                            clientList.snapshot
                        </h3>
                    </div>
                    <ng-container
                        *ngIf="!actionPlanDataLoaded"
                        [ngTemplateOutlet]="skeletonLoader"
                        [ngTemplateOutletContext]="{
                            height: '100px'
                        }">
                    </ng-container>
                    <div
                        class="app-l-result__card-body"
                        *ngIf="actionPlanDataLoaded">
                        <div class="app-l-completed-assessment">
                            <p translate>
                                clientList.actionPlan.createdThisMonth
                            </p>
                            <h2>{{ actionPlanDataCount }}</h2>
                        </div>
                        <div class="app-l-additional-assessment">
                            <p class="assesment-p" translate>
                                clientList.actionPlan.currentActionPlans
                            </p>
                            <span *ngIf="actionPlan.length !== 0">
                                <div
                                    class="additional-val"
                                    *ngFor="
                                        let actionPlan of actionPlan
                                            | slice: 0 : 5
                                    ">
                                    <!-- <div class="actionplanimg">
                                        <img
                                            alt="Assign"
                                            src="./assets/img/actionplanimg.jpg" />
                                    </div> -->
                                    <div class="details">
                                        <h5>{{ actionPlan.title }}</h5>

                                        <div class="mood-val">
                                            <div class="icon">
                                                <svg-icon
                                                    icon="usericon"></svg-icon>
                                            </div>
                                            <h5
                                                *ngIf="
                                                    actionPlan.isAdminCreated
                                                "
                                                translate>
                                                clientList.actionPlan.counselorCreated
                                            </h5>
                                            <h5
                                                *ngIf="
                                                    !actionPlan.isAdminCreated
                                                "
                                                translate>
                                                clientList.actionPlan.clientCreated
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </span>
                            <span *ngIf="actionPlan.length === 0">
                                <div class="additional-val">
                                    <p translate>clientList.noData</p>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="app-l-result__card app-l-assessment-assignments">
                    <div class="app-l-result__card-header">
                        <div class="app-l-result__card-header-left">
                            <h3 class="app-c-heading--h3" translate>
                                clientList.actionPlan.title
                            </h3>
                        </div>
                        <div
                            class="app-l-result__card-header-right"
                            *ngIf="assignedListLoaded">
                            <button
                                class="app-c-btn app-c-btn--primary-v2 app-c-btn--curve"
                                (click)="showAssignModal('true')"
                                translate>
                                <img
                                    alt="Assign"
                                    src="./assets/img/icons-svg/assign-user-icon.svg" />
                                <span translate>clientList.assign</span>
                            </button>
                        </div>
                    </div>
                    <div class="app-l-result__card-body">
                        <div class="app-l-result-item__base">
                            <div class="app-l-result-item__card-block">
                                <ng-container
                                    *ngIf="!assignedListLoaded"
                                    [ngTemplateOutlet]="skeletonLoader"
                                    [ngTemplateOutletContext]="{
                                        height: '100px'
                                    }">
                                </ng-container>
                                <mat-tab-group
                                    *ngIf="assignedListLoaded"
                                    mat-align-tabs="center"
                                    #tabGroup
                                    (focusChange)="onTabChanged($event)"
                                    [selectedIndex]="0">
                                    <mat-tab>
                                        <ng-template mat-tab-label>
                                            <div translate>
                                                clientList.assigned
                                            </div>
                                        </ng-template>
                                        <div
                                            class="app-l-assignment-ls-container"
                                            *ngIf="
                                                showAssigned &&
                                                assignedList?.length != 0
                                            ">
                                            <div
                                                class="app-l-assignment-ls"
                                                *ngFor="
                                                    let assigned of assignedList
                                                ">
                                                <div translate>
                                                    <span
                                                        [translate]="
                                                            'actionPlan.actionPlan'
                                                        "></span>
                                                </div>
                                                <div translate>
                                                    <div class="icon">
                                                        <svg-icon
                                                            icon="time"></svg-icon>
                                                    </div>

                                                    {{
                                                        assigned.Date
                                                            | date
                                                                : 'dd MMMM YYYY'
                                                    }}
                                                </div>
                                                <div>
                                                    <button
                                                        tabindex="0"
                                                        class="app-c-btn app-c-btn--icon-primary app-c-btn--icon app-c-btn--icon-20"
                                                        (click)="delete(assigned.ID)"
                                                        (keypress)="delete(assigned.ID)">
                                                        <svg-icon
                                                            icon="trash"
                                                            class="w-100 h-100"
                                                            alt="trash"></svg-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="app-l-assignment-ls-container"
                                            *ngIf="
                                                showAssigned &&
                                                assignedList?.length === 0
                                            ">
                                            <div class="app-l-assignment-ls">
                                                <div>
                                                    <h3
                                                        translate
                                                        class="nodata-title">
                                                        clientList.noData
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-tab>
                                    <mat-tab>
                                        <ng-template mat-tab-label>
                                            <span translate
                                                >clientList.completed</span
                                            >
                                        </ng-template>
                                        <div
                                            class="app-l-assignment-ls-container"
                                            *ngIf="
                                                showCompleted &&
                                                completeList?.length != 0
                                            ">
                                            <div
                                                class="app-l-assignment-ls"
                                                *ngFor="
                                                    let completed of completeList
                                                ">
                                                <div translate>
                                                    <span
                                                        [translate]="
                                                            'actionPlan.actionPlan'
                                                        "></span>
                                                </div>
                                                <div translate>
                                                    <div class="icon">
                                                        <svg-icon
                                                            icon="time"></svg-icon>
                                                    </div>

                                                    {{
                                                        completed.UpdatedOnUtc
                                                            | date
                                                                : 'dd MMMM YYYY'
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="app-l-assignment-ls-container"
                                            *ngIf="
                                                showCompleted &&
                                                completeList?.length === 0
                                            ">
                                            <div class="app-l-assignment-ls">
                                                <div>
                                                    <h3
                                                        translate
                                                        class="nodata-title">
                                                        clientList.noData
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-tab>
                                </mat-tab-group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="app-l-assessment-results-percentage">
                <div class="app-l-result__card">
                    <div class="app-l-result__card-header">
                        <h3 class="app-l-result__card-header-left" translate>
                            clientList.actionPlan.title
                        </h3>
                        <button
                            tabindex="0"
                            (click)="goToActionPlan()"
                            class="app-c-btn app-c-btn--secondary app-c-btn--curve">
                            <svg-icon icon="add-icon"></svg-icon>
                            <span translate>actionPlan.createActionPlan</span>
                        </button>
                        <div class="app-l-card__h-right">
                            <a
                                class="action-view"
                                [routerLink]="'/app/practice/action-plan/' + id"
                                translate
                                >clientList.viewAll</a
                            >
                        </div>
                    </div>
                    <ng-container
                        *ngIf="!actionPlanDataLoaded"
                        [ngTemplateOutlet]="skeletonLoader"
                        [ngTemplateOutletContext]="{
                            height: '100px'
                        }">
                    </ng-container>
                    <div
                        class="app-l-result__card-body"
                        *ngIf="actionPlanDataLoaded">
                        <div
                            class="app-c-card app-l-action-plan-preview-header">
                            <div
                                class="app-c-card app-l-action-plan-preview-body">
                                <div class="app-l-room__pos">
                                    <div class="app-l-room__tabs">
                                        <div
                                            class="app-dm-positive-cards-wrapper"
                                            *ngIf="actionPlan.length">
                                            <div
                                                class="app-c-card app-l-activity-card"
                                                *ngFor="
                                                    let actionplan of actionPlan
                                                        | slice: 0 : 10
                                                ">
                                                <div
                                                    class="app-c-card__content">
                                                    <div
                                                        class="app-l-card__h-left">
                                                        <div
                                                            class="app-l-card__h-top-block">
                                                            <h2
                                                                class="app-l-card__cardtitle app-c-body-text--14-m"
                                                                [ngClass]="{
                                                                    'no-badge':
                                                                        !actionplan.isAdminCreated
                                                                }">
                                                                {{
                                                                    actionplan.title
                                                                }}
                                                                <span
                                                                    class="badges-container">
                                                                    <div
                                                                        class="badge app-c-body-text--12"
                                                                        *ngIf="
                                                                            actionplan.isAdminCreated
                                                                        "
                                                                        translate>
                                                                        actionPlan.createCounselor
                                                                    </div>
                                                                </span>
                                                            </h2>
                                                            <p
                                                                class="app-c-body-text--14 text-content">
                                                                <span
                                                                    [translate]="
                                                                        actionplan.isUpdated
                                                                            ? 'actionPlan.lastUpdatedOn'
                                                                            : 'actionPlan.createdOn'
                                                                    "></span>
                                                                {{
                                                                    actionplan.isUpdated
                                                                        ? (actionplan.updated
                                                                          | date
                                                                              : 'MMM dd, yyyy')
                                                                        : (actionplan.created
                                                                          | date
                                                                              : 'MMM dd, yyyy')
                                                                }}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div
                                                        class="app-l-card__h-right"
                                                        (click)="
                                                            previewActionplan(
                                                                actionplan
                                                            )
                                                        ">
                                                        <img
                                                            alt="Assign"
                                                            src="./assets/img/icons-svg/View_More.svg" />
                                                    </div>

                                                    <!-- <div
                                                        class="app-l-card__h-right"
                                                        *ngIf="
                                                        loggedInUser.userType !=
                                                                'user' &&
                                                            actionplan.isAdminCreated
                                                        ">
														<a (click)="
                                                                deleteActionPlan(
                                                                    actionplan.id
                                                                )
                                                            "
                                                            class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon">
                                                            <svg-icon
                                                                icon="delete"></svg-icon>
                                                        </a>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="actionPlan.length <= 0">
                                        <div class="empty-card">
                                            <div
                                                class="app-dm-positive-cards"
                                                [translate]="
                                                    !this.isLoading
                                                        ? 'actionPlan.noDataFound'
                                                        : ''
                                                "></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="app-l-result__card app-l-assessment-assignments app-l-result__action-plan">
                    <div class="app-l-result__card-header">
                        <div class="app-l-result__card-header-left">
                            <h3 class="app-c-heading--h3" translate>
                                clientList.actionPlan.copingSkillWarningSign
                            </h3>
                        </div>
                        <div
                            class="app-l-result__card-header-right"
                            *ngIf="copingAndWarningListLoaded">
                            <a
                                class="action-view"
                                [routerLink]="'/app/practice/action-plan/' + id"
                                translate
                                >clientList.viewAll</a
                            >
                        </div>
                    </div>

                    <ng-container
                        *ngIf="!copingAndWarningListLoaded"
                        [ngTemplateOutlet]="skeletonLoader"
                        [ngTemplateOutletContext]="{
                            height: '100%'
                        }">
                    </ng-container>
                    <div
                        class="app-l-result__card-body"
                        *ngIf="copingAndWarningListLoaded">
                        <div class="app-l-result-item__base">
                            <div class="app-l-result-item__card-block">
                                <mat-tab-group
                                    mat-align-tabs="center"
                                    #tabGroup
                                    (focusChange)="onTabChangedList($event)"
                                    [selectedIndex]="0">
                                    <mat-tab>
                                        <ng-template mat-tab-label>
                                            <span translate
                                                >clientList.actionPlan.copingSkills</span
                                            >
                                        </ng-template>
                                        <div
                                            class="app-l-assignment-ls-container"
                                            *ngIf="
                                                showCoping &&
                                                copingList?.length != 0
                                            ">
                                            <div class="app-cw-body">
                                                <div
                                                    class="app-cw-card"
                                                    *ngFor="
                                                        let coping of copingList
                                                    ">
                                                    <h2 translate>
                                                        clientList.actionPlan.copingSkills
                                                    </h2>
                                                    <p>
                                                        {{ coping.Answer }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="app-l-assignment-ls-container"
                                            *ngIf="
                                                showCoping &&
                                                copingList?.length === 0
                                            ">
                                            <div class="app-l-assignment-ls">
                                                <div>
                                                    <h3
                                                        translate
                                                        class="nodata-title">
                                                        clientList.noData
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-tab>
                                    <mat-tab>
                                        <ng-template mat-tab-label>
                                            <span translate
                                                >clientList.actionPlan.warningSigns</span
                                            >
                                        </ng-template>
                                        <div
                                            class="app-l-assignment-ls-container"
                                            *ngIf="
                                                showWarning &&
                                                warningList?.length != 0
                                            ">
                                            <div class="app-cw-body">
                                                <div
                                                    class="app-cw-card"
                                                    *ngFor="
                                                        let warning of warningList
                                                    ">
                                                    <h2 translate>
                                                        clientList.actionPlan.warningSigns
                                                    </h2>
                                                    <p>
                                                        {{ warning.Answer }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="app-l-assignment-ls-container"
                                            *ngIf="
                                                showWarning &&
                                                warningList?.length === 0
                                            ">
                                            <div class="app-l-assignment-ls">
                                                <div>
                                                    <h3
                                                        translate
                                                        class="nodata-title">
                                                        clientList.noData
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-tab>
                                </mat-tab-group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template let-height="height" #skeletonLoader>
    <mat-card class="skeleton-card" [ngStyle]="{ height: height }">
        <mat-spinner [diameter]="25"></mat-spinner>
    </mat-card>
</ng-template>
