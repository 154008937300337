import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { User } from '../../../models/user';
import { UserService } from '../../../lib/user.service';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import { ApiService } from '../../../lib/api.service';
import { StorageService } from '../../../lib/storage.service';
import { LogService } from '../../../lib/log.service';
import { RoomForPositiveCreateComponent } from '../room-for-positive-create/room-for-positive-create.component';
import { ModalService } from 'app/lib/modal.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { Moment } from 'moment';
import { RoomForPositiveViewComponent } from '../room-for-positive-view/room-for-positive-view.component';
import { MatDatepicker } from '@angular/material/datepicker';
import { ActivatedRoute, Router } from '@angular/router';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MMMM YYYY',
    monthYearLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-room-for-positive-lisiting',
  templateUrl: './room-for-positive-lisiting.component.html',
  styleUrls: ['./room-for-positive-lisiting.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class RoomForPositiveLisitingComponent implements OnInit {

  user: User;
	isLoaded: boolean;
  dateFilter: string = "";
  rfpEntryList: Array<object> = [];
  questionIndex: number = 0;
  date = new FormControl(moment());
  isLoading = true;
  title: string; 
  backLink: string; 
  back: string;
  maxDate: Date;
  @ViewChild('dp') datePicker: MatDatepicker<Date>; 
  id: number;
  paramsSub: any;
  showCreateBtn: boolean = true;
  previousUrl: any;
	type: any;
	quizId: any;
  constructor(
    private userService: UserService,
		private translate: TranslateService,
    private modalService: ModalService,
    private api: ApiService,
    private router: Router,
    private storage: StorageService,
    private log: LogService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.user = this.userService.getUser();
    this.maxDate = new Date();
    this.activatedRoute.queryParams.subscribe(params => {
      this.previousUrl = params['prevpage'];
      this.type = params['type'];
      this.quizId = params['id'];
      });
   }

  ngOnInit(): void {
    if(this.user.userType !== "user"){
			this.paramsSub = this.activatedRoute.params.subscribe((params) => {
				this.id = parseInt(params["id"], 10);
			});
      if(this.id){
				this.showCreateBtn = false;
			} else{
				this.showCreateBtn = true;
			}
		}
    this.dateFilter = moment().format();
    this.getRoomForPositiveEntryList(
      moment().format("MM"),
      moment().format("YYYY")
    );

    this.backLink = '/app/practice';
    this.translate.stream('navigation').subscribe((res: any) => {
			this.back = res.back;
		});

    this.translate.stream('practice.rfp').subscribe((res: any) => {
			this.title = res.title;
		});

  }

  openDatePicker() {
    this.datePicker.open();
  }

  getRoomForPositiveEntryList(month: string, year: string) {
	this.isLoading = true;
  let url = `roomforpositive?month=${month}&year=${year}`;
    if (this.user.userType !== "user" && this.id) {
      const userId = this.id;
      url += `&SynchedUserID=${userId}`;
    }
    this.api.get(url).subscribe(
      (result: any) => {
		this.isLoading = false;
        this.rfpEntryList = result;
      },
      (error: any) => {
		this.isLoading = false;
        this.log.error("Error getting entries. " + error.message);
        this.rfpEntryList.length = 0;
      }
    );
  }

  onTabChanged($event) {
    this.questionIndex = $event.index;
    this.rfpEntryList = [...this.rfpEntryList];

  }

  dateFilterChange($event,dp) {
    let month = moment.parseZone($event._i).format("MM");
    let year = moment.parseZone($event._i).format("YYYY");
    dp.close();
    this.dateFilter=moment(new Date(parseInt(year), parseInt(month)-1, 1).getTime()).format();
    this.getRoomForPositiveEntryList(month, year);
  }

  createNewEntry(){
    this.modalService.showComponent(RoomForPositiveCreateComponent, { mode: "create",data:{}}).afterClosed().subscribe(result => {
      this.dateFilter = moment().format();
      this.getRoomForPositiveEntryList(moment().format("MM"), moment().format("YYYY"));
    });
  }

  viewRfpEntry(data: any) {
      interface AdminData {
        questionIndex: number;
        objdata: any;
        ID?: number | null; 
      }

      let admindata: AdminData = {
        questionIndex: this.questionIndex,
        objdata: data,
        ID: null
      };
      if (this.user.userType !== 'user' && this.id) {
        admindata.ID = this.id;
      }
    this.modalService.showComponent(RoomForPositiveViewComponent, admindata).afterClosed().subscribe(result => {
      this.dateFilter = moment().format();
      this.getRoomForPositiveEntryList(moment().format("MM"), moment().format("YYYY"));
    });
  }

  close(){
		if (this.previousUrl === 'result') {
            this.router.navigate([
                "/app/assessment/result/" + this.quizId +"/"+ this.type  
            ]);
        } else if (this.user.userType === 'user' || (this.user.userType !== 'user' && this.showCreateBtn)) {
            this.router.navigateByUrl(
                '/app/practice'
            );
        } else if(this.user.userType !== 'user' && !this.showCreateBtn){
            this.router.navigateByUrl(
                '/professional/user-details-dashboard/' + this.id
            );
        }
	}
}
