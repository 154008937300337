<vg-player
    class="video"
    (onPlayerReady)="!disableControls && onPlayerReady($event)"
    tabindex="0">
    <vg-overlay-play *ngIf="!disableControls"></vg-overlay-play>
    <vg-buffering *ngIf="!disableControls"></vg-buffering>

    <vg-controls *ngIf="!disableControls" [vgAutohide]="true">
        <vg-play-pause tabindex="0"></vg-play-pause>

        <vg-time-display
            [vgProperty]="'current'"
            [vgFormat]="'mm:ss'"
            class="time-display"></vg-time-display>
        <vg-scrub-bar class="scrub-bar" [vgSlider]="false">
            <vg-scrub-bar-current-time
                [vgSlider]="true"></vg-scrub-bar-current-time>
        </vg-scrub-bar>
        <vg-time-display
            [vgProperty]="'total'"
            [vgFormat]="'mm:ss'"
            class="time-display"></vg-time-display>
        <vg-playback-button></vg-playback-button>
        <!--Speed Controller: 4 options: 0.5x, 1x, 1.5x, 2x default: 1x -->
        <vg-volume class="volume-bar"></vg-volume>
        <vg-mute></vg-mute>
        <vg-track-selector
            *ngIf="showTextTrackSelecter"
            class="track-selector"></vg-track-selector>
        <vg-fullscreen></vg-fullscreen>
    </vg-controls>
    <video
        #videoPlayer
        (play)="onVideoPlay()"
        (pause)="onVideoPause()"
        [vgMedia]="media"
        #media
        class="singlevideo"
        preload="none"
        [poster]="video.thumbnailUrl"
        type="video/mp4"
        [autoplay]="autoplay"
        crossorigin>
        <source [src]="video.mediaUrl" [type]="video.mediaType" />
    </video>
</vg-player>
