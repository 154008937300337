<page-loader *ngIf="!assessments"></page-loader>
<div class="app-l-page-wrap home-dashboard-main-container" *ngIf="assessments">
    <div
        class="app-l-dashboard-course"
        *ngIf="mentalWellbeingRcommendedResources.length">
        <div class="app-l-dc__header">
            <div class="app-l-dc__header-left">
                <h3 class="app-c-heading--h3" translate>
                    dashboard.recommendedSection.0
                </h3>
                <p class="app-c-body-text--12" translate>
                    dashboard.recommendedSection.1
                </p>
            </div>
        </div>
        <div class="app-l-dc__body">
            <div class="zen-room-main-container">
                <div class="zen-room-inner-container">
                    <div class="app-dm-zen-type">
                        <div
                            class="app-zen-owl-carousel middle"
                            *ngIf="mentalWellbeingRcommendedResources.length"
                            tabindex="1"
                            role="region"
                            aria-label="Ambiance Carousel">
                            <owl-carousel-o [options]="customOptions">
                                <ng-template
                                    carouselSlide
                                    tabindex="2"
                                    *ngFor="
                                        let resource of removeGetHelp(
                                            mentalWellbeingRcommendedResources
                                        )
                                    ">
                                    <div
                                        class="slide"
                                        (click)="onClickResource(resource)">
                                        <div
                                            class="app-dm-slide-box text-center ambiance-border">
                                            <div class="app-dm-box-image">
                                                <img
                                                    [defaultImage]="
                                                        './assets/img/dashboard/no-image.svg'
                                                    "
                                                    [lazyLoad]="resource.image"
                                                    alt="view-more-button"
                                                    class="img-fluid rounded-top" />
                                                <button
                                                    class="app-l-dc__play"
                                                    *ngIf="
                                                        resource.type ==
                                                        'series'
                                                    ">
                                                    <svg-icon
                                                        icon="play"
                                                        alt="play"
                                                        class="w-100 h-100"></svg-icon>
                                                </button>
                                                <span translate>{{
                                                    resource.name
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </owl-carousel-o>

                            <div
                                class="noData"
                                translate
                                *ngIf="
                                    !mentalWellbeingRcommendedResources.length
                                ">
                                No data found
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="cards-container"
        *ngIf="
            (isRcCourseLoaded && !mentalWellbeingRcommendedResources.length) ||
            takeMetalWellbeingAssessment ||
            (actionPlanLoaded && !userActionPlans.length)
        ">
        <div class="cards-section-header">
            <div class="cards-section-heading">
                <h2
                    *ngIf="
                        isRcCourseLoaded &&
                        !mentalWellbeingRcommendedResources.length
                    ">
                    {{ 'actionPlan.emptyCard.0' | translate }} {{ firstName }},
                </h2>
            </div>
        </div>
        <div class="empty-cards-container">
            <div class="empty-cards-section">
                <div
                    *ngIf="
                        (isRcCourseLoaded &&
                            !mentalWellbeingRcommendedResources.length) ||
                        takeMetalWellbeingAssessment
                    "
                    class="card card-1"
                    (click)="goToAssessment()">
                    <div class="card-bottom">
                        <h2 translate>dashboard.assessment</h2>
                        <p
                            [translate]="
                                !mentalWellbeingRcommendedResources.length
                                    ? 'dashboard.takeMentalWellbeingAssessmentDesc0'
                                    : 'dashboard.takeMentalWellbeingAssessmentDesc1'
                            "></p>
                        <button
                            class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon">
                            <span translate
                                >dashboard.takeMentalWellbeingAssessment</span
                            >
                            <svg-icon icon="angle-right"></svg-icon>
                        </button>
                    </div>
                </div>
                <div
                    *ngIf="actionPlanLoaded && !userActionPlans.length"
                    class="card card-2"
                    (click)="goToActionPlan('create')">
                    <div class="card-bottom">
                        <h2 translate>actionPlan.title</h2>
                        <p translate>actionPlan.emptyCard.1</p>
                        <button
                            class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon">
                            <span translate>actionPlan.createAnActionPlan</span>
                            <svg-icon icon="angle-right"></svg-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="action-plan-section"
        *ngIf="actionPlanLoaded && userActionPlans.length">
        <div class="action-plan-section-header">
            <div class="action-plan-section-heading">
                <h2 translate>actionPlan.title</h2>
            </div>
            <a
                role="tab"
                tabindex="0"
                class="view-more-button"
                translate
                (click)="goToActionPlan('list')"
                (keypress)="goToActionPlan('list')"
                >actionPlan.viewMore</a
            >
        </div>
        <div class="action-plan-container">
            <div
                tabindex="0"
                class="action-plan-card"
                *ngFor="let plan of userActionPlans"
                (click)="goToActionPlan('edit', plan)"
                (keypress)="goToActionPlan('edit', plan)">
                <div class="action-plan-card-content">
                    <h2>{{ plan.title }}</h2>
                </div>
                <div class="action-plan-card-footer" *ngIf="plan.hasAnswers">
                    <p class="footer-title-text" translate>
                        {{ plan.questionTitle }}
                    </p>
                    <p
                        class="footer-body-text"
                        *ngFor="let answer of plan.answers"
                        translate>
                        {{ answer.answer || answer.Answer }}
                    </p>
                </div>
                <div class="action-plan-card-footer" *ngIf="!plan.hasAnswers">
                    <p
                        class="footer-title-text"
                        [translate]="
                            plan.isUpdated
                                ? 'actionPlan.lastUpdatedOn'
                                : 'actionPlan.createdOn'
                        "></p>
                    <p class="footer-body-text">
                        {{
                            plan.isUpdated
                                ? (plan.updated | date: 'MMM dd, yyyy')
                                : (plan.created | date: 'MMM dd, yyyy')
                        }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <ng-template #emptyActionPlan>
        <div
            class="action-plan-section"
            *ngIf="actionPlanLoaded && !userActionPlans.length">
            <div class="action-plan-section-header">
                <div class="action-plan-section-heading">
                    <h2 translate>actionPlan.title</h2>
                </div>
                <!-- <a class="view-more-button" translate (click)="goToActionPlan('list')"
			  >actionPlan.viewMore<ion-icon
				src="../../../assets/img/icons/arrow-left-blue.svg"
			  ></ion-icon
			></a> -->
            </div>
            <div class="action-plan-container empty-container">
                <div class="action-plan-empty-card">
                    <div class="action-plan-empty-card-footer">
                        <p class="footer-title-text">
                            {{ 'actionPlan.emptyCard.0' | translate }}
                            {{ firstName }},
                        </p>
                        <p class="footer-body-text" translate>
                            {{ 'actionPlan.emptyCard.1' }}
                        </p>
                    </div>
                    <a
                        role="button"
                        tabindex="0"
                        class="app-c-btn app-c-btn--secondary app-c-btn--curve create-btn"
                        translate
                        (click)="goToActionPlan('create')"
                        (keypress)="goToActionPlan('create')">
                        actionPlan.createAnActionPlan</a
                    >
                </div>
            </div>
        </div>
    </ng-template>
    <div class="app-l-dashboard__wrap">
        <div class="app-l-dashboard__block">
            <div class="app-c-card app-l-chart__card">
                <div class="app-c-card__body">
                    <div class="app-l-bs__tab-wrap">
                        <div class="app-l-bs__tab">
                            <div
                                class="app-l-bs__tab-item negative"
                                [ngClass]="{
                                    active: selectedMode === 'summary'
                                }">
                                <a
                                    tabindex="0"
                                    (click)="setMoodPercentageMode('summary')"
                                    (keypress)="
                                        setMoodPercentageMode('summary')
                                    "
                                    translate
                                    >dashboard.summary
                                </a>
                            </div>
                            <div
                                class="app-l-bs__tab-item"
                                [ngClass]="{
                                    active: selectedMode === 'breakdown'
                                }">
                                <a
                                    tabindex="0"
                                    (click)="setMoodPercentageMode('breakdown')"
                                    (keypress)="
                                        setMoodPercentageMode('breakdown')
                                    "
                                    translate
                                    >dashboard.breakdown
                                </a>
                            </div>
                        </div>
                    </div>

                    <div
                        class="app-l-mood__day"
                        *ngIf="selectedMode === 'breakdown'">
                        <h4 class="app-c-heading--h4">
                            {{ 'dashboard.mood' + selectedMood | translate }}
                        </h4>
                        <div
                            class="app-l-mood__date"
                            (click)="openDatePicker(picker)"
                            (keypress)="openDatePicker(picker)">
                            <p
                                class="display-date"
                                *ngIf="selectedMood === 'Daily'">
                                {{
                                    selectedDate
                                        | date
                                            : 'MMM dd,
								yyyy'
                                }}
                            </p>
                            <p
                                class="display-date"
                                *ngIf="selectedMood === 'Weekly'">
                                {{
                                    getStartOfWeek(selectedDate)
                                        | date: 'MMM dd'
                                }}
                                -
                                {{
                                    getEndOfWeek(selectedDate) | date: 'MMM dd'
                                }}
                                ,{{ getEndOfWeek(selectedDate) | date: 'yyyy' }}
                            </p>
                            <p
                                class="display-date"
                                *ngIf="selectedMood === 'Monthly'">
                                {{
                                    selectedDate
                                        | date
                                            : 'MMMM
								yyyy'
                                }}
                            </p>
                            <mat-form-field>
                                <input
                                    matInput
                                    [matDatepicker]="picker"
                                    [(ngModel)]="selectedDate"
                                    style="display: none"
                                    (dateChange)="onDateSelected($event)"
                                    disabled
                                    [max]="today" />
                                <mat-datepicker-toggle
                                    matSuffix
                                    [for]="picker"
                                    color="daily">
                                    <mat-icon matDatepickerToggleIcon>
                                        <svg-icon
                                            icon="calendar"
                                            alt="calendar"
                                            class="w-100 h-100"></svg-icon>
                                    </mat-icon>
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker disabled="false">
                                    <mat-datepicker-actions>
                                        <button
                                            class="app-c-btn app-c-btn--secondary app-c-btn--sm app-c-btn--curve"
                                            matDatepickerCancel
                                            translate>
                                            dashboard.cancel
                                        </button>
                                        <button
                                            class="app-c-btn app-c-btn--primary app-c-btn--sm app-c-btn--curve"
                                            mat-raised-button
                                            color="primary"
                                            (click)="setCalendarDate()"
                                            (keypress)="setCalendarDate()"
                                            matDatepickerApply
                                            translate>
                                            dashboard.apply
                                        </button>
                                    </mat-datepicker-actions>
                                </mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>

                    <div
                        class="app-c-tab--static"
                        [hidden]="selectedMode == 'summary'">
                        <div
                            class="app-c-tab--static-header"
                            *ngIf="selectedMode === 'breakdown'">
                            <div
                                class="app-c-tab--static-item daily"
                                [ngClass]="{
                                    active: selectedMood === 'Daily'
                                }">
                                <a
                                    tabindex="0"
                                    (click)="setMoodMode('Daily')"
                                    (keypress)="setMoodMode('Daily')"
                                    translate
                                    >dashboard.dailyTab</a
                                >
                            </div>
                            <div
                                class="app-c-tab--static-item weekly"
                                [ngClass]="{
                                    active: selectedMood === 'Weekly'
                                }">
                                <a
                                    tabindex="0"
                                    (click)="setMoodMode('Weekly')"
                                    (keypress)="setMoodMode('Weekly')"
                                    translate
                                    >dashboard.weeklyTab</a
                                >
                            </div>
                            <div
                                class="app-c-tab--static-item monthly"
                                [ngClass]="{
                                    active: selectedMood === 'Monthly'
                                }">
                                <a
                                    tabindex="0"
                                    (click)="setMoodMode('Monthly')"
                                    (keypress)="setMoodMode('Monthly')"
                                    translate
                                    >dashboard.monthlyTab</a
                                >
                            </div>
                        </div>
                        <div class="app-c-tab--static-body">
                            <div
                                class="app-l-mood-tab-content"
                                [hidden]="selectedMode != 'breakdown'">
                                <app-monthly-moodcheck-view
                                    [hidden]="selectedMood !== 'Monthly'"
                                    #moodchecMonthlyViewComp></app-monthly-moodcheck-view>
                                <app-weekly-moodcheck-view
                                    [hidden]="selectedMood !== 'Weekly'"
                                    #moodcheckWeeklyViewComp></app-weekly-moodcheck-view>
                                <app-daily-moodcheck-view
                                    [hidden]="selectedMood !== 'Daily'"
                                    #moodcheckDailyViewComp></app-daily-moodcheck-view>
                            </div>
                        </div>
                    </div>

                    <div
                        class="app-l-moods__overall"
                        [hidden]="selectedMode != 'summary'">
                        <app-overall-view-moodcheck
                            #moodchecOverallViewComp></app-overall-view-moodcheck>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="app-l-dashboard__block app-l-result__block">
            <div class="app-c-card">
                <div class="app-c-card__header">
                    <div class="app-l-card__h-left">
                        <h3 class="app-l-card__title" translate>
                            das.dasResultSummary
                        </h3>
                    </div>

                    <div class="app-l-card__h-right">
                        <div *ngIf="gethelpbuttondas && contactGroupTelephone">
                            <button
                                class="app-c-btn app-c-btn--secondary app-c-btn--curve app-c-btn--sm"
                                (click)="
                                    onCallContact(
                                        $event,
                                        organization.contactGroup
                                    )
                                "
                                (keypress)="
                                    onCallContact(
                                        $event,
                                        organization.contactGroup
                                    )
                                "
                                translate>
                                <svg-icon
                                    icon="get-help"
                                    alt="get-help"></svg-icon>
                                <span>
                                    {{ 'contact.getHelpNow' | translate }}
                                </span>
                            </button>
                        </div>

                        <div tabindex="0" *ngIf="gethelpbuttondas && showOther">
                            <button
                                class="app-c-btn app-c-btn--secondary app-c-btn--curve app-c-btn--sm"
                                (click)="onHotline($event)"
                                (keypress)="onHotline($event)"
                                translate>
                                <svg-icon
                                    icon="get-help"
                                    alt="get-help"></svg-icon>
                                <span
                                    >{{ 'contact.getHelpNow' | translate }}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="app-c-card__body">
                    <div class="app-c-tab--static">
                        <div class="app-c-tab--static-header">
                            <div
                                tabindex="0"
                                class="app-c-tab--static-item daily"
                                [ngClass]="{
                                    active:
                                        activeLink == dateFilterOptions[0].key
                                }">
                                <a
                                    tabindex="0"
                                    role="button"
                                    (click)="
                                        onSelectFilterData(dateFilterOptions[0])
                                    "
                                    (keypress)="
                                        onSelectFilterData(dateFilterOptions[0])
                                    "
                                    >{{
                                        'das.dateFilter.' +
                                            dateFilterOptions[0].key | translate
                                    }}</a
                                >
                            </div>
                            <div
                                tabindex="0"
                                class="app-c-tab--static-item weekly"
                                [ngClass]="{
                                    active:
                                        activeLink == dateFilterOptions[1].key
                                }">
                                <a
                                    tabindex="0"
                                    role="tab"
                                    (click)="
                                        onSelectFilterData(dateFilterOptions[1])
                                    "
                                    (keypress)="
                                        onSelectFilterData(dateFilterOptions[1])
                                    "
                                    >{{
                                        'das.dateFilter.' +
                                            dateFilterOptions[1].key | translate
                                    }}</a
                                >
                            </div>
                            <div
                                tabindex="0"
                                class="app-c-tab--static-item monthly"
                                [ngClass]="{
                                    active:
                                        activeLink == dateFilterOptions[2].key
                                }">
                                <a
                                    tabindex="0"
                                    role="tab"
                                    (click)="
                                        onSelectFilterData(dateFilterOptions[2])
                                    "
                                    (keypress)="
                                        onSelectFilterData(dateFilterOptions[2])
                                    "
                                    >{{
                                        'das.dateFilter.' +
                                            dateFilterOptions[2].key | translate
                                    }}</a
                                >
                            </div>
                        </div>
                    </div>
                    <div class="app-l-graph__wrap">
                        <div class="app-l-graph__container">
                            <canvas
                                #dasAssessmentChart
                                id="dasAssessmentChart"></canvas>
                        </div>
                        <div class="app-l-chart__legends">
                            <button
                                tabindex="0"
                                [ngClass]="{
                                    active: selectedChartLegend === 'all'
                                }"
                                (click)="selectLegend('all')"
                                (keypress)="selectLegend('all')"
                                translate>
                                das.all
                            </button>
                            <button
                                tabindex="0"
                                class="app-l-legend__depression"
                                [ngClass]="{
                                    active: selectedChartLegend === 'depression'
                                }"
                                (click)="selectLegend('depression')"
                                translate>
                                das.depression
                            </button>
                            <button
                                tabindex="0"
                                class="app-l-legend__anxity"
                                [ngClass]="{
                                    active: selectedChartLegend === 'anxity'
                                }"
                                (click)="selectLegend('anxity')"
                                (keypress)="selectLegend('anxity')"
                                translate>
                                das.anxiety
                            </button>
                            <button
                                tabindex="0"
                                class="app-l-legend__stress"
                                [ngClass]="{
                                    active: selectedChartLegend === 'stress'
                                }"
                                (click)="selectLegend('stress')"
                                (keypress)="selectLegend('stress')"
                                translate>
                                das.stress
                            </button>
                        </div>
                    </div>
                    <div class="app-l-card__assesment">
                        <div class="app-l-card__ass-header">
                            <div class="app-l-card__assh-left">
                                <h3 class="app-c-body-text--14-m" translate>
                                    assessmentTypeListing.tabTitle
                                </h3>
                                <a
                                    role="tab"
                                    tabindex="0"
                                    class="app-c-btn app-c-btn--secondary app-c-btn--curve app-c-btn--sm"
                                    routerLink="/app/assessments/dass/new"
                                    translate
                                    >das.startAssessment</a
                                >
                            </div>
                            <div class="app-l-card__assh-right">
                                <a
                                    role="tab"
                                    tabindex="0"
                                    class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon"
                                    routerLink="/app/assessments/listing">
                                    <span translate>menu.viewAll</span>
                                    <svg-icon
                                        icon="angle-right"
                                        alt="angle-right"></svg-icon>
                                </a>
                            </div>
                        </div>
                        <div class="app-l-card__ass-body">
                            <div class="app-l-ass__wrap">
                                <div
                                    tabindex="0"
                                    class="app-l-ass__block"
                                    *ngFor="
                                        let assessmentslist of assessmentList;
                                        let i = index
                                    "
                                    (keypress)="
                                        assessmentPath(assessmentslist.key)
                                    ">
                                    <a
                                        tabindex="0"
                                        translate
                                        [routerLink]="[
                                            '/app/assessment/' +
                                                assessmentslist.key +
                                                '/listing'
                                        ]"
                                        (keydown.Tab)="
                                            focusNextItem($event, i)
                                        ">
                                        <h5
                                            class="app-c-body-text--12-m"
                                            translate>
                                            <a translate>
                                                {{ assessmentslist.title }}
                                            </a>
                                        </h5>
                                        <p
                                            class="app-c-body-text--10"
                                            translate>
                                            {{ assessmentslist.description }}
                                        </p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="app-l-dashboard__block app-l-dsh__rfp">
            <div class="app-c-card">
                <div
                    class="app-c-card__header"
                    *ngIf="rfpEntryList.length != 0">
                    <div class="app-l-card__h-left">
                        <h3 class="app-l-card__title" translate>
                            practice.rfp.title
                        </h3>
                        <a
                            role="tab"
                            tabindex="0"
                            class="app-c-btn app-c-btn--secondary app-c-btn--curve app-c-btn--sm"
                            *ngIf="rfpEntryList.length != 0"
                            (click)="createNewEntry()"
                            (keypress)="createNewEntry()">
                            <svg-icon icon="plus" alt="plus"></svg-icon>
                            <span translate>practice.rfp.addEntry</span>
                        </a>
                    </div>
                    <div
                        class="app-l-card__h-right"
                        *ngIf="rfpEntryList.length != 0">
                        <mat-select
                            [(ngModel)]="rfptab"
                            class="app-l-dsh__rfp-select"
                            disableOptionCentering
                            panelClass="app-l-dsh__rfp-panel">
                            <mat-option
                                value="proud"
                                (click)="setrfptab('proud', '0')"
                                (keypress)="setrfptab('proud', '0')"
                                translate
                                >{{
                                    'practice.rfp.shortTitleProud' | translate
                                }}</mat-option
                            >
                            <mat-option
                                value="grateful"
                                (click)="setrfptab('grateful', '1')"
                                (keypress)="setrfptab('grateful', '1')"
                                translate
                                >{{
                                    'practice.rfp.shortTitleGrateful'
                                        | translate
                                }}</mat-option
                            >
                        </mat-select>
                    </div>
                </div>
                <div class="app-c-card__body" *ngIf="rfpEntryList.length != 0">
                    <div
                        class="app-l-dsh__rp-wrap"
                        [hidden]="rfptab !== 'proud'">
                        <div
                            tabindex="0"
                            class="app-l-dsh__rp-card"
                            *ngFor="let rfpEntry of rfpEntryList"
                            (click)="viewRfpEntry(rfpEntry)"
                            (keypress)="viewRfpEntry(rfpEntry)">
                            <span>
                                <div class="app-l-dsh__rp-date">
                                    <svg-icon
                                        icon="calendar"
                                        alt="calendar"></svg-icon>
                                    <h3 class="app-c-body-text--12">
                                        {{
                                            rfpEntry.created_at
                                                | date: 'dd MMM YYYY'
                                        }}
                                    </h3>
                                </div>
                                <div class="app-l-dsh__rp-content">
                                    <h4 class="app-c-body-text--12-m">
                                        {{ rfpEntry.proudTitle }}
                                    </h4>
                                    <p class="app-c-body-text--12">
                                        {{ rfpEntry.proudDescription }}
                                    </p>
                                </div>
                            </span>
                        </div>
                    </div>
                    <div
                        class="app-l-dsh__rp-wrap"
                        [hidden]="rfptab !== 'grateful'">
                        <div
                            tabindex="0"
                            class="app-l-dsh__rp-card"
                            *ngFor="let rfpEntry of rfpEntryList"
                            (click)="viewRfpEntry(rfpEntry)"
                            (keypress)="viewRfpEntry(rfpEntry)">
                            <span>
                                <div class="app-l-dsh__rp-date">
                                    <svg-icon
                                        icon="calendar"
                                        alt="calendar"></svg-icon>
                                    <h3 class="app-c-body-text--12">
                                        {{
                                            rfpEntry.created_at
                                                | date: 'dd MMM YYYY'
                                        }}
                                    </h3>
                                </div>
                                <div class="app-l-dsh__rp-content">
                                    <h4 class="app-c-body-text--12-m">
                                        {{ rfpEntry.gratefulTitle }}
                                    </h4>
                                    <p class="app-c-body-text--12">
                                        {{ rfpEntry.gratefulDescription }}
                                    </p>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    class="app-l-no-mood-check"
                    *ngIf="rfpEntryList.length === 0">
                    <div class="no-data-image">
                        <svg-icon
                            icon="room-for-positive"
                            alt="room-for-positive"></svg-icon>
                        <span
                            class="app-l-no-mood__round app-l-no-mood__round1"></span>
                        <span
                            class="app-l-no-mood__round app-l-no-mood__round2"></span>
                        <span
                            class="app-l-no-mood__round app-l-no-mood__round3"></span>
                    </div>
                    <h5 class="app-c-body-text--16-m" translate>
                        practice.rfp.title
                    </h5>
                    <p class="app-c-body-text--14" translate>
                        practice.rfp.dashContent
                    </p>
                    <a
                        role="tab"
                        tabindex="0"
                        class="app-c-btn app-c-btn--secondary app-c-btn--curve app-c-btn--sm"
                        (click)="createNewEntry()"
                        (keypress)="createNewEntry()">
                        <svg-icon icon="plus" alt="plus"></svg-icon>
                        <span translate>practice.rfp.addEntry</span>
                    </a>
                </div>
            </div>
        </div>

        <div class="app-l-dashboard__block app-l-dsh__sa">
            <div class="app-c-card">
                <div
                    class="app-c-card__header"
                    *ngIf="activityschedules.length != 0">
                    <div class="app-l-card__h-left">
                        <h3 class="app-l-card__title" translate>
                            dashboard.scheduledActivities
                        </h3>
                        <div *ngIf="activityschedules.length != 0">
                            <a
                                role="tab"
                                tabindex="0"
                                class="app-c-btn app-c-btn--secondary app-c-btn--curve app-c-btn--sm"
                                *ngIf="activityschedules.length != 0"
                                (click)="scheduleActivity()"
                                (keypress)="scheduleActivity()">
                                <svg-icon icon="plus" alt="plus"></svg-icon>
                                <span translate>dashboard.addActivities</span>
                            </a>
                        </div>
                    </div>
                    <div class="app-l-card__h-right">
                        <a
                            role="tab"
                            tabindex="0"
                            class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon"
                            routerLink="/app/practice/activityscheduler">
                            <span translate>menu.viewAll</span>
                            <svg-icon
                                icon="angle-right"
                                alt="angle-right"></svg-icon>
                        </a>
                    </div>
                </div>
                <div
                    class="app-c-card__body"
                    *ngIf="activityschedules.length != 0">
                    <div class="app-l-dsh__sa-wrap">
                        <div
                            class="app-l-dsh__sa-card"
                            *ngFor="let activityschedule of activityschedules">
                            <h5 class="app-c-body-text--12-m">
                                {{ activityschedule.title }}
                            </h5>
                            <div class="app-l-sa__time">
                                <svg-icon icon="clock" alt="clock"></svg-icon>
                                <span class="app-c-body-text--12"
                                    >{{
                                        activityschedule.start | date: 'hh:mm a'
                                    }}
                                    to
                                    {{
                                        activityschedule.end | date: 'hh:mm a'
                                    }}</span
                                >
                            </div>

                            <div
                                class="app-l-sa__status"
                                *ngIf="activityschedule.status === 'complete'">
                                <svg-icon
                                    icon="tick-circle"
                                    alt="tick-circle"></svg-icon>
                                <span class="app-c-body-text--10" translate>
                                    <!-- {{activityschedule.status}} -->
                                    dashboard.activityCompleted
                                </span>
                            </div>
                            <button
                                tabindex="0"
                                class="app-c-btn app-c-btn--secondary app-c-btn--curve app-c-btn--sm"
                                *ngIf="
                                    activityschedule.status === 'inprogress' &&
                                    'user.id == activityschedule.userId'
                                "
                                (click)="complete(activityschedule.id)"
                                (keypress)="complete(activityschedule.id)">
                                Mark as complete
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    class="app-l-no-mood-check"
                    *ngIf="activityschedules.length === 0">
                    <div class="no-data-image">
                        <svg-icon
                            icon="scheduled-activities"
                            alt="scheduled-activities"></svg-icon>
                        <span
                            class="app-l-no-mood__round app-l-no-mood__round1"></span>
                        <span
                            class="app-l-no-mood__round app-l-no-mood__round2"></span>
                        <span
                            class="app-l-no-mood__round app-l-no-mood__round3"></span>
                    </div>
                    <H5 class="app-c-body-text--16-m" translate
                        >dashboard.scheduledActivities
                    </H5>
                    <p class="app-c-body-text--14" translate>
                        dashboard.activityContent
                    </p>
                    <a
                        role="tab"
                        tabindex="0"
                        class="app-c-btn app-c-btn--secondary app-c-btn--curve app-c-btn--sm"
                        (click)="scheduleActivity()"
                        (keypress)="scheduleActivity()">
                        <svg-icon icon="plus" alt="plus"></svg-icon>
                        <span translate>dashboard.addActivities</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
