import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../lib/api.service';
import { User } from '../../../models/user';
import { UserService } from '../../../lib/user.service';
import { LogService } from '../../../lib/log.service';
import { ModalService } from '../../../lib/modal.service';
import { AnalyticEvent } from 'app/lib/analytic-event';
import * as moment from 'moment';
import { DateService } from 'app/lib/url.service';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from 'app/lib/storage.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ActionPlanList } from 'app/models/action-plan';
import { ThoughtDiary } from 'app/models/thought-diary';
import * as _ from 'lodash';
import { ActivityScheduler } from 'app/models/activity-scheduler';
import { ProfessionalService } from '../professional.service';
import { RoomForPositiveViewComponent } from '../../practice/room-for-positive-view/room-for-positive-view.component';
import { UtilityService } from 'app/lib/utility.service';
import { TOOLS_CONFIG } from 'app/lib/constants';
import { AssignEventList } from 'app/models/event';
import { Subscription } from 'rxjs';

type ToolsCount = {
    [key: string]: number;
};

@Component({
    selector: 'app-tools-view',
    templateUrl: './tools-view.component.html',
    styleUrls: ['./tools-view.component.scss'],
    providers: [DatePipe],
})
export class ToolsViewComponent implements OnInit {
    loggedInUser: User;
    user: User;
    isLoaded: boolean = false;
    demographicDetail: any;
    id: number;
    paramsSub: any;
    actionPlanData: ActionPlanList[] = [];
    @Input() countData: any;
    @Input() demographicData: any;
    @Input() userDetail: any;
    showEventAssignModal: boolean = false;
    tabselecteddays: string = '30';
    activedays: string = '30';
    assignedList: any = [];
    completedList: any = [];
    toolsCount: ToolsCount = {};
    totalToolsCount: number = 0;
    diaries: Array<ThoughtDiary> = [];
    public filteredData: Array<ThoughtDiary> = [];
    questionIndex: number = 0;
    questionIndexRfp: number = 0;
    rfpEntryList: Array<object> = [];
    activityschedules: ActivityScheduler[];
    data: any;
    avgScore: string;
    bestScore: string;
    showScore: boolean;
    answerScore: any;
    dateRange = [
        {
            title: 'All',
            value: 0,
        },
        {
            title: 'Last 30 days',
            value: 30,
        },
        {
            title: 'Last 60 days',
            value: 60,
        },
        {
            title: 'Last 90 days',
            value: 90,
        },
    ];
    dateRangeSelected: any;
    currentDate: Date;
    rangeDate: Date;
    showCompleted: boolean = false;
    showAssigned: boolean = true;
    configListing: any;
    dateFilter: string = '';
    toolsOrder = [
        { key: 'zenroom', label: 'Zen Room' },
        { key: 'thoughtDiary', label: 'Thought Diary' },
        { key: 'roomForPositive', label: 'Room for the Positive' },
        { key: 'activityScheduler', label: 'Activity Scheduler' },
        { key: 'thoughtDistortionQuiz', label: 'Thought Distortion Quiz' },
    ];
    month: string;
    year: string;
    monthDates: any[];
    upcomingActivities = [];
    activityMonthDates: any[] = [];
    zenroom: any;
    assignedListLoaded: boolean = false;
    toolsCountListLoaded: boolean = false;
    zenroomDataLoaded: boolean = false;
    thoughtDiaryDataLoaded: boolean = false;
    roomForPositiveDataLoaded: boolean = false;
    cdQuizBestLoaded: boolean = false;
    cdQuizLastLoaded: boolean = false;
    acConfigLoaded: boolean = false;
    acListLoaded: boolean = false;
    toolsConfig = TOOLS_CONFIG.TOOLS_LIST_LANG;
    dateFilterSubscriber: Subscription;
    constructor(
        private api: ApiService,
        private log: LogService,
        private translate: TranslateService,
        private modalService: ModalService,
        private userService: UserService,
        private activatedRoute: ActivatedRoute,
        private utilityService: UtilityService
    ) {
        this.loggedInUser = this.userService.getUser();
        this.currentDate = new Date();
        this.rangeDate = new Date(
            new Date().setDate(this.currentDate.getDate() - 30)
        );
        this.month = moment().format('M');
        this.year = moment().format('YYYY');
    }
    onLanguageChange() {
        this.translate.stream('lang').subscribe((res: any) => {
            if (res === 'en') {
                this.getToolsCount();
                this.getAssignedList();
            } else {
                this.getToolsCount();
                this.getAssignedList();
            }
        });
    }

    ngOnInit(): void {
        this.paramsSub = this.activatedRoute.params.subscribe(params => {
            this.id = parseInt(params['id'], 10);
            this.dateFilterSubscriber =
                this.utilityService.userDetailsPageDayFilter.subscribe(data => {
                    if (data != this.dateRangeSelected) {
                        this.dateRangeSelected = data;
                        this.onRangeChange(false);
                    }
                });
            this.onLanguageChange();
        });
    }

    getInitials(name): string {
        return this.utilityService.getInitials(name);
    }

    onRangeChange(setFilter: boolean) {
        this.rangeDate = new Date(
            new Date().setDate(
                this.currentDate.getDate() - this.dateRangeSelected
            )
        );
        setFilter &&
            this.utilityService.setUserDetailsPageDayFilterSubject(
                this.dateRangeSelected
            );
        this.getAllToolsList(this.rangeDate, this.currentDate);
    }

    setdayschart(tab: string) {
        if (this.user.userType != 'user') {
            if (this.activedays !== tab) {
                this.activedays = tab;
                this.tabselecteddays = tab;
            }
        }
    }

    getToolsCount() {
        this.toolsCountListLoaded = false;
        this.api.get(`syncheduser/tools/viewcount/${this.id}`).subscribe(
            (result: ToolsCount) => {
                delete result.zenroom;
                this.toolsOrder = this.toolsOrder.filter(
                    tool => tool.key !== 'zenroom'
                );
                this.toolsCount = result;
                this.totalToolsCount = this.calculateTotalCount(result);
                this.toolsCountListLoaded = true;
                this.isLoaded = true;
            },
            (error: any) => {
                this.log.error('Error getting tools count. ' + error.message);
                this.toolsCountListLoaded = true;
                this.isLoaded = true;
            }
        );
    }

    getAllToolsList(rangeDate, currentDate) {
        let to = currentDate.toISOString().substring(0, 10);
        let from = rangeDate.toISOString().substring(0, 10);
        this.getZenRoomData();
        this.getThoughtDiaryList(
            this.id,
            from,
            to,
            this.dateRangeSelected == 0
        );
        this.getActivitySchedulerConfigList();
        this.getActivitySchedulerList();
        this.getRoomForPositiveEntryList(
            this.id,
            from,
            to,
            this.dateRangeSelected == 0
        );
        this.getCDQuizBestResult();
        this.getCDQuizLastResult();
    }

    getZenRoomData() {
        this.zenroomDataLoaded = false;
        this.api.get(`syncheduser/tools/zenroom/${this.id}?limit=3`).subscribe(
            (result: any) => {
                this.isLoaded = true;
                this.zenroom = result;
                this.zenroomDataLoaded = true;
            },
            (error: any) => {
                this.log.error('Error getting tools count. ' + error.message);
                this.isLoaded = true;
                this.zenroomDataLoaded = true;
            }
        );
    }

    getThoughtDiaryList(id, from, to, filterAll: boolean = false) {
        this.thoughtDiaryDataLoaded = false;
        let payload = {
            UserID: id,
            limit: 2,
        };
        if (!filterAll) {
            payload['From'] = from;
            payload['To'] = to;
        }
        this.api.get(`theory/thoughtdiary/listing`, payload).subscribe(
            (result: any) => {
                this.isLoaded = true;
                this.diaries = ThoughtDiary.initializeArray(result.data);
                this.filterThoughtData();
                this.thoughtDiaryDataLoaded = true;
            },
            (error: any) => {
                this.log.error('Error getting tools count. ' + error.message);
                this.isLoaded = true;
                this.thoughtDiaryDataLoaded = true;
            }
        );
    }

    getActivitySchedulerConfigList() {
        this.acConfigLoaded = false;
        this.api.get('config/activityscheduler').subscribe(
            (result: any) => {
                this.isLoaded = true;
                const configData = result.default;
                this.log.event(
                    AnalyticEvent.event.activitySchedulerDetailsLoaded
                );
                if (configData !== null) {
                    this.configListing = Object.values(configData);
                }
                this.acConfigLoaded = true;
            },
            (error: any) => {
                this.log.error('Error getting tools data. ' + error.message);
                this.isLoaded = true;
                this.acConfigLoaded = true;
            }
        );
    }

    getActivitySchedulerList() {
        this.acListLoaded = false;
        this.api
            .get(`practice/activityscheduler/listing?UserID=${this.id}&limit=5`)
            .subscribe(
                (result: any) => {
                    this.isLoaded = true;
                    let dataArray = ActivityScheduler.initializeArray(
                        result.data
                    );
                    this.data = dataArray;
                    this.activityschedules = [...this.data];
                    this.setMonthData(
                        this.month,
                        this.year,
                        this.dateRangeSelected == 0
                    );
                    this.acListLoaded = true;
                },
                (error: any) => {
                    this.log.error(
                        'Error getting tools count. ' + error.message
                    );
                    this.isLoaded = true;
                    this.acListLoaded = true;
                }
            );
    }

    getCDQuizBestResult() {
        this.cdQuizBestLoaded = false;
        this.api
            .get(`quizzes/cognitivebest?SynchedUserID=${this.id}`)
            .subscribe(
                (result: any) => {
                    if (result.data.result >= 0 && result.data.result != null) {
                        this.bestScore = result.data.result;
                        this.log.event(AnalyticEvent.event.cdQuizLoaded);
                        this.showScore = true;
                        this.isLoaded = true;
                    } else {
                        this.showScore = false;
                        this.isLoaded = true;
                    }
                    this.cdQuizBestLoaded = true;
                },
                (error: any) => {
                    this.log.error(
                        'Error getting tools count. ' + error.message
                    );
                    this.isLoaded = true;
                    this.cdQuizBestLoaded = true;
                }
            );
    }

    getCDQuizLastResult() {
        this.cdQuizLastLoaded = false;
        this.api
            .get(`quizzes/cognitivelast?SynchedUserID=${this.id}`)
            .subscribe(
                (result: any) => {
                    if (result.data.result >= 0 && result.data.result != null) {
                        this.avgScore = result.data.result;
                        this.answerScore = result.data.answer_results[0];
                        this.showScore = true;
                        this.isLoaded = true;
                        this.cdQuizLastLoaded = true;
                    } else {
                        this.showScore = false;
                        this.isLoaded = true;
                        this.cdQuizLastLoaded = true;
                    }
                },
                (error: any) => {
                    this.log.error(
                        'Error getting tools count. ' + error.message
                    );
                    this.isLoaded = true;
                }
            );
    }

    getLowerCaseTitle(title: string): string {
        return title ? title.toLowerCase() : '';
    }

    getRoomForPositiveEntryList(id, from, to, filterAll: boolean = false) {
        this.roomForPositiveDataLoaded = false;
        let payload = {
            SynchedUserID: id,
            limit: 3,
        };
        if (!filterAll) {
            payload['From'] = from;
            payload['To'] = to;
        }
        this.api.get(`roomforpositive`, payload).subscribe(
            (result: any) => {
                this.roomForPositiveDataLoaded = true;
                this.isLoaded = true;
                this.rfpEntryList = result;
            },
            (error: any) => {
                this.log.error('Error getting tools count. ' + error.message);
                this.roomForPositiveDataLoaded = true;
                this.isLoaded = true;
            }
        );
    }

    setMonthData(month, year, filterAll: boolean = false) {
        const arr = [];
        this.data.forEach(activity => {
            let date = new Date(activity.start);
            if (!filterAll) {
                date.getMonth() + 1 === JSON.parse(month) &&
                    date.getFullYear() === JSON.parse(year) &&
                    arr.push(activity);
            } else {
                date.getMonth() + 1 >= JSON.parse(month) &&
                    date.getFullYear() >= JSON.parse(year) &&
                    arr.push(activity);
            }
            // if (
            //     date.getMonth() + 1 === JSON.parse(month) &&
            //     date.getFullYear() === JSON.parse(year)
            // ) {
            //     arr.push(activity);
            // }
        });
        this.activityschedules = arr;
        let startDate = new Date(year, JSON.parse(month) - 1, 1); // month is 0-indexed
        let endDate = new Date(year, JSON.parse(month), 1);

        this.monthDates = [];
        while (startDate < endDate) {
            this.upcomingActivities = this.activityschedules.filter(
                activity => new Date(activity.start) > new Date()
            );
            this.monthDates.push({
                date: new Date(startDate),
                activities: this.activityschedules.filter(
                    activity =>
                        activity.start.getDate() ===
                        new Date(startDate).getDate()
                ),
                currentDate:
                    new Date(startDate).setHours(0, 0, 0, 0) ===
                    new Date().setHours(0, 0, 0, 0)
                        ? true
                        : false,
            });
            startDate.setDate(startDate.getDate() + 1);
        }
        this.activityMonthDates = this.monthDates.filter(
            date => date.activities.length > 0
        );
    }

    filterThoughtData() {
        this.filteredData = _.filter(this.diaries, {
            status: this.questionIndex == 0 ? 'complete' : 'inprogress',
        });
    }

    getType(type) {
        return this.configListing?.find(
            (activity: any) => activity.Value === JSON.stringify(type)
        )['Key'];
    }

    isMissed(activity) {
        const date = new Date();
        return activity.status === 'inprogress' && date > activity.end
            ? true
            : false;
    }

    isNotStarted(activity) {
        const now = new Date();
        const activityStart = new Date(activity.end);
        const isSameDate = now.toDateString() <= activityStart.toDateString();
        const isFutureTime = now <= activityStart;
        return isSameDate && isFutureTime ? true : false;
    }

    viewRfpEntry(data: any) {
        interface AdminData {
            questionIndex: number;
            objdata: any;
            ID?: number | null;
        }

        let admindata: AdminData = {
            questionIndex: this.questionIndexRfp,
            objdata: data,
            ID: null,
        };
        if (this.loggedInUser.userType !== 'user' && this.id) {
            admindata.ID = this.id;
        }
        this.modalService
            .showComponent(RoomForPositiveViewComponent, admindata)
            .afterClosed()
            .subscribe(result => {
                this.dateFilter = moment().format();
                //   this.getRoomForPositiveEntryList(moment().format("MM"), moment().format("YYYY"));
            });
    }

    onTabChanged($event) {
        this.questionIndexRfp = $event.index;
        this.rfpEntryList = [...this.rfpEntryList];
    }

    calculateTotalCount(tools: ToolsCount): number {
        return Object.values(tools).reduce((sum, value) => sum + value, 0);
    }

    onCloseOptions(event: any) {
        this.showEventAssignModal = false;
        event && this.getAssignedList();
        setTimeout(function () {
            jQuery('#moodcheck-modal .featured:visible').focus();
        }, 500);
    }

    showAssignModal(val) {
        this.showEventAssignModal = val;
    }

    getAssignedList() {
        this.assignedListLoaded = false;
        this.api
            .get(
                `syncheduser/assignments/` +
                    this.id +
                    `?type=tools` +
                    `&limit=10`
            )
            .subscribe(
                (result: any) => {
                    this.assignedList = AssignEventList.initializeArray(
                        result.assigned
                    );
                    this.completedList = AssignEventList.initializeArray(
                        result.completed
                    );
                    this.isLoaded = true;
                    this.assignedListLoaded = true;
                },
                (error: any) => {
                    this.log.error(
                        'Error getting assessment questions. ' + error.message
                    );
                    this.isLoaded = true;
                    this.assignedListLoaded = true;
                }
            );
    }

    onTabAssignChanged($event) {
        let tabSelected = $event.index;
        if (tabSelected == 0) {
            this.showAssigned = true;
        } else {
            this.showCompleted = true;
        }
    }

    deleteAssign(aId) {
        this.modalService
            .showConfirmation(
                'Delete',
                'Are you sure you want to delete this assignment?'
            )
            .afterClosed()
            .subscribe(result => {
                if (result) {
                    this.api
                        .delete(
                            `syncheduser/assignment/delete/` +
                                aId +
                                `?SynchedUserID=` +
                                this.id
                        )
                        .subscribe(
                            (result: any) => {
                                this.getAssignedList();
                                this.isLoaded = true;
                                this.assignedListLoaded = true;
                                this.modalService.showAlert(
                                    'Success',
                                    'Assignment deleted successfully'
                                );
                            },
                            (error: any) => {
                                this.log.error(
                                    'Error getting delete.' + error.message
                                );
                                this.isLoaded = true;
                            }
                        );
                }
            });
    }
    ngOnDestroy() {
        if (this.dateFilterSubscriber) {
            this.dateFilterSubscriber.unsubscribe();
        }
    }
}
