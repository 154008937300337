import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PracticeComponent } from './practice/practice.component';
import { ThoughtDiaryListingPage } from './thoughtdiary/thoughtdiary.component';
import { ThoughtDiaryDetailsPage } from './thoughtdiary-details/thoughtdiary-details.component';
import { FunAchievementListingPage } from './fun-achievement-listing/fun-achievement-listing.component';
import { FunAchievementDetailsPage } from './fun-achievement-details/fun-achievement-details.component';
import { ActivitySchedulerListingPage } from './activity-scheduler-listing/activity-scheduler-listing.component';
import { ActivitySchedulerDetails } from './activity-scheduler-details/activity-scheduler-details';
import { AddictionListingPage } from './addiction-listing/addiction-listing';
import { AddictionDetailsPage } from './addiction-details/addiction-details';
import { ZenRoomPage } from './zen-room/zen-room.component';
import { CognitiveQuiz } from './cd-quiz/cd-quiz.component';
import { RoomForPositiveLisitingComponent } from './room-for-positive-lisiting/room-for-positive-lisiting.component';
import { ActionPlanListingPage } from './action-plan/action-plan.component';
import { ActionPlanDetailComponent } from './action-plan-detail/action-plan-detail.component';
import { ActionPlanPreviewComponent } from './action-plan-preview/action-plan-preview.component';
import { ResultsComponent } from 'app/components/results-modal/results-modal.component';

const practiceRoutes = [
    {
        path: '',
        component: PracticeComponent,
        data: { title: 'practice' },
    },
    {
        path: 'thoughtdiary',
        component: ThoughtDiaryListingPage,
        data: { title: 'thoughtdiary' },
    },
    {
        path: 'thoughtdiary/new',
        component: ThoughtDiaryDetailsPage,
        data: { title: 'thoughtdiarycreate' },
    },
    {
        path: 'thoughtdiary/:id',
        component: ThoughtDiaryDetailsPage,
        data: { title: 'thoughtdiaryedit' },
    },
    // {
    // 	path: 'funachievement',
    // 	component: FunAchievementListingPage,
    // 	data: { title: 'funachievementlisting' }
    // },
    // {
    // 	path: 'funachievement/new',
    // 	component: FunAchievementDetailsPage,
    // 	data: { title: 'funachievementcreate' }
    // },
    // {
    // 	path: 'funachievement/:id',
    // 	component: FunAchievementDetailsPage,
    // 	data: { title: 'funachievementedit' }
    // },
    {
        path: 'activityscheduler',
        component: ActivitySchedulerListingPage,
        data: { title: 'activityschedulerlisting' },
    },
    {
        path: 'activityscheduler/new',
        component: ActivitySchedulerDetails,
        data: { title: 'activityschedulercreate' },
    },
    {
        path: 'room-for-positive/listing',
        component: RoomForPositiveLisitingComponent,
        data: { title: 'roomforpositivelisiting' },
    },
    {
        path: 'activityscheduler/:id',
        component: ActivitySchedulerDetails,
        data: { title: 'activityscheduleredit' },
    },
    {
        path: 'addiction',
        component: AddictionListingPage,
        data: { title: 'activityschedulerlisting' },
    },
    {
        path: 'addiction/new',
        component: AddictionDetailsPage,
        data: { title: 'activityschedulercreate' },
    },
    {
        path: 'addiction/:id',
        component: AddictionDetailsPage,
        data: { title: 'activityscheduleredit' },
    },
    {
        path: 'zen-room',
        component: ZenRoomPage,
        data: { title: 'zenroom' },
    },
    {
        path: 'action-plan',
        component: ActionPlanListingPage,
        data: { title: 'actionplan' },
    },
    // {
    // 	path: 'action-plan/listing',
    // 	component: ActionPlanListingComponent,
    // 	data: { title: 'actionplan' }
    // },
    {
        path: 'action-plan/create',
        component: ActionPlanDetailComponent,
        data: { title: 'actionplan' },
    },
    {
        path: 'action-plan/create/:questionId',
        component: ActionPlanDetailComponent,
        data: { title: 'actionplan' },
    },
    {
        path: 'action-plan/create/:id/:questionId',
        component: ActionPlanDetailComponent,
        data: { title: 'actionplan' },
    },
    {
        path: 'action-plan/preview',
        component: ActionPlanPreviewComponent,
        data: { title: 'actionplan' },
    },
    {
        path: 'cd-quiz',
        component: CognitiveQuiz,
        data: { title: 'cognitivedistortionsquiz' },
    },
    {
        path: 'cd-quiz/result',
        component: ResultsComponent,
        data: { title: '' },
    },
    {
        path: 'action-plan/preview/:id',
        component: ActionPlanPreviewComponent,
        data: { title: '' },
    },
    {
        path: 'action-plan/:id',
        component: ActionPlanListingPage,
        data: { title: 'actionplan' },
    },
    {
        path: 'cd-quiz/:id',
        component: CognitiveQuiz,
        data: { title: 'cognitivedistortionsquiz' },
    },
    {
        path: 'action-plan/:id',
        component: ActionPlanListingPage,
        data: { title: 'actionplan' },
    },
    {
        path: 'room-for-positive/listing/:id',
        component: RoomForPositiveLisitingComponent,
        data: { title: 'roomforpositivelisiting' },
    },
    {
        path: 'thoughtdiary-listing/:id',
        component: ThoughtDiaryListingPage,
        data: { title: 'thoughtdiary' },
    },
    {
        path: 'activityscheduler-listing/:id',
        component: ActivitySchedulerListingPage,
        data: { title: 'activityschedulerlisting' },
    },
];

@NgModule({
    imports: [RouterModule.forChild(practiceRoutes)],
    exports: [RouterModule],
})
export class PracticeRoutingModule {}
