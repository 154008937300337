<div class="app-l-page-wrap home-dashboard-main-container">
    <div class="app-l-dashboard__wrap">
        <div class="app-l-dashboard__block">
            <div class="app-c-card app-l-chart__card">
                <div class="app-c-card__body">
                    <div class="app-l-bs__tab-wrap">
                        <div class="app-l-bs__tab">
                            <div
                                class="app-l-bs__tab-item negative"
                                [ngClass]="{
                                    active: selectedMode === 'summary'
                                }">
                                <a
                                    tabindex="0"
                                    (click)="setMoodPercentageMode('summary')"
                                    (keypress)="
                                        setMoodPercentageMode('summary')
                                    "
                                    translate
                                    >dashboard.summary
                                </a>
                            </div>
                            <div
                                class="app-l-bs__tab-item"
                                [ngClass]="{
                                    active: selectedMode === 'breakdown'
                                }">
                                <a
                                    tabindex="0"
                                    (click)="setMoodPercentageMode('breakdown')"
                                    (keypress)="
                                        setMoodPercentageMode('breakdown')
                                    "
                                    translate
                                    >dashboard.breakdown
                                </a>
                            </div>
                        </div>
                    </div>

                    <div
                        class="app-l-mood__day"
                        *ngIf="selectedMode === 'breakdown'">
                        <h4 class="app-c-heading--h4">
                            {{ 'dashboard.mood' + selectedMood | translate }}
                        </h4>
                        <div
                            class="app-l-mood__date"
                            (click)="openDatePicker(picker)"
                            (keypress)="openDatePicker(picker)">
                            <p
                                class="display-date"
                                *ngIf="selectedMood === 'Daily'">
                                {{
                                    selectedDate
                                        | date
                                            : 'MMM dd,
								yyyy'
                                }}
                            </p>
                            <p
                                class="display-date"
                                *ngIf="selectedMood === 'Weekly'">
                                {{
                                    getStartOfWeek(selectedDate)
                                        | date: 'MMM dd'
                                }}
                                -
                                {{
                                    getEndOfWeek(selectedDate) | date: 'MMM dd'
                                }}
                                ,{{ getEndOfWeek(selectedDate) | date: 'yyyy' }}
                            </p>
                            <p
                                class="display-date"
                                *ngIf="selectedMood === 'Monthly'">
                                {{
                                    selectedDate
                                        | date
                                            : 'MMMM
								yyyy'
                                }}
                            </p>
                            <mat-form-field>
                                <input
                                    matInput
                                    [matDatepicker]="picker"
                                    [(ngModel)]="selectedDate"
                                    style="display: none"
                                    (dateChange)="onDateSelected($event)"
                                    disabled
                                    [max]="today" />
                                <mat-datepicker-toggle
                                    matSuffix
                                    [for]="picker"
                                    color="daily">
                                    <mat-icon matDatepickerToggleIcon>
                                        <svg-icon
                                            icon="calendar"
                                            alt="calendar"
                                            class="w-100 h-100"></svg-icon>
                                    </mat-icon>
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker disabled="false">
                                    <mat-datepicker-actions>
                                        <button
                                            class="app-c-btn app-c-btn--secondary app-c-btn--sm app-c-btn--curve"
                                            matDatepickerCancel
                                            translate>
                                            dashboard.cancel
                                        </button>
                                        <button
                                            class="app-c-btn app-c-btn--primary app-c-btn--sm app-c-btn--curve"
                                            mat-raised-button
                                            color="primary"
                                            (click)="setCalendarDate()"
                                            (keypress)="setCalendarDate()"
                                            matDatepickerApply
                                            translate>
                                            dashboard.apply
                                        </button>
                                    </mat-datepicker-actions>
                                </mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>

                    <div
                        class="app-c-tab--static"
                        [hidden]="selectedMode == 'summary'">
                        <div
                            class="app-c-tab--static-header"
                            *ngIf="selectedMode === 'breakdown'">
                            <div
                                class="app-c-tab--static-item daily"
                                [ngClass]="{
                                    active: selectedMood === 'Daily'
                                }">
                                <a
                                    tabindex="0"
                                    (click)="setMoodMode('Daily')"
                                    (keypress)="setMoodMode('Daily')"
                                    translate
                                    >dashboard.dailyTab</a
                                >
                            </div>
                            <div
                                class="app-c-tab--static-item weekly"
                                [ngClass]="{
                                    active: selectedMood === 'Weekly'
                                }">
                                <a
                                    tabindex="0"
                                    (click)="setMoodMode('Weekly')"
                                    (keypress)="setMoodMode('Weekly')"
                                    translate
                                    >dashboard.weeklyTab</a
                                >
                            </div>
                            <!-- <div
                                class="app-c-tab--static-item monthly"
                                [ngClass]="{
                                    active: selectedMood === 'Monthly'
                                }">
                                <a
                                    tabindex="0"
                                    (click)="setMoodMode('Monthly')"
                                    (keypress)="setMoodMode('Monthly')"
                                    translate
                                    >dashboard.monthlyTab</a
                                >
                            </div> -->
                        </div>
                        <div class="app-c-tab--static-body">
                            <div
                                class="app-l-mood-tab-content"
                                [hidden]="selectedMode != 'breakdown'">
                                <!-- <app-monthly-moodcheck-view
                                    [hidden]="selectedMood !== 'Monthly'"
                                    #moodcheckMonthlyViewComp></app-monthly-moodcheck-view> -->
                                <app-weekly-moodcheck-view
                                    [hidden]="selectedMood !== 'Weekly'"
                                    #moodcheckWeeklyViewComp></app-weekly-moodcheck-view>
                                <app-daily-moodcheck-view
                                    [hidden]="selectedMood !== 'Daily'"
                                    #moodcheckDailyViewComp></app-daily-moodcheck-view>
                            </div>
                        </div>
                    </div>

                    <div
                        class="app-l-moods__overall"
                        [hidden]="selectedMode != 'summary'">
                        <app-overall-view-moodcheck
                            #moodcheckOverallViewComp></app-overall-view-moodcheck>
                    </div>
                </div>
            </div>
        </div>
        <div class="app-l-dashboard__block app-l-result__block">
            <div class="app-c-card">
                <div class="app-c-card__header">
                    <div class="app-l-card__h-left">
                        <h3 class="app-l-card__title" translate>
                            {{
                                'assessments.' + typeAssessment + '.titleResult'
                                    | translate: { value: typeAssessment }
                            }}
                        </h3>
                    </div>

                    <div class="app-l-card__h-right">
                        <div *ngIf="gethelpbutton && contactGroupTelephone">
                            <button
                                class="app-c-btn app-c-btn--secondary app-c-btn--curve app-c-btn--sm"
                                (click)="
                                    onCallContact(
                                        $event,
                                        organization.contactGroup
                                    )
                                "
                                (keypress)="
                                    onCallContact(
                                        $event,
                                        organization.contactGroup
                                    )
                                "
                                translate>
                                <svg-icon
                                    icon="get-help"
                                    alt="get-help"></svg-icon>
                                <span>
                                    {{ 'contact.getHelpNow' | translate }}
                                </span>
                            </button>
                        </div>

                        <div tabindex="0" *ngIf="gethelpbutton && showOther">
                            <button
                                class="app-c-btn app-c-btn--secondary app-c-btn--curve app-c-btn--sm"
                                (click)="onHotline($event)"
                                (keypress)="onHotline($event)"
                                translate>
                                <svg-icon
                                    icon="get-help"
                                    alt="get-help"></svg-icon>
                                <span
                                    >{{ 'contact.getHelpNow' | translate }}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="app-c-card__body">
                    <div class="app-c-tab--static">
                        <div class="app-c-tab--static-header">
                            <div
                                tabindex="0"
                                class="app-c-tab--static-item daily"
                                [ngClass]="{
                                    active:
                                        activeLink == dateFilterOptions[0].key
                                }">
                                <a
                                    tabindex="0"
                                    role="button"
                                    (click)="
                                        onSelectFilterData(dateFilterOptions[0])
                                    "
                                    (keypress)="
                                        onSelectFilterData(dateFilterOptions[0])
                                    "
                                    >{{
                                        'das.dateFilter.' +
                                            dateFilterOptions[0].key | translate
                                    }}</a
                                >
                            </div>
                            <div
                                tabindex="0"
                                class="app-c-tab--static-item weekly"
                                [ngClass]="{
                                    active:
                                        activeLink == dateFilterOptions[1].key
                                }">
                                <a
                                    tabindex="0"
                                    role="tab"
                                    (click)="
                                        onSelectFilterData(dateFilterOptions[1])
                                    "
                                    (keypress)="
                                        onSelectFilterData(dateFilterOptions[1])
                                    "
                                    >{{
                                        'das.dateFilter.' +
                                            dateFilterOptions[1].key | translate
                                    }}</a
                                >
                            </div>
                            <div
                                tabindex="0"
                                class="app-c-tab--static-item monthly"
                                [ngClass]="{
                                    active:
                                        activeLink == dateFilterOptions[2].key
                                }">
                                <a
                                    tabindex="0"
                                    role="tab"
                                    (click)="
                                        onSelectFilterData(dateFilterOptions[2])
                                    "
                                    (keypress)="
                                        onSelectFilterData(dateFilterOptions[2])
                                    "
                                    >{{
                                        'das.dateFilter.' +
                                            dateFilterOptions[2].key | translate
                                    }}</a
                                >
                            </div>
                        </div>
                    </div>
                    <div class="app-l-graph__wrap">
                        <!-- <div class="app-c-datebox">
                        <button class="app-c-datebox__nav">
                            <svg-icon icon="angle-left" class="w-100 h-100"></svg-icon>
                        </button>
                        <div class="app-l-summary__date-label">
                            <span>Sep, Week 1</span>
                        </div>
                        <button class="app-c-datebox__nav">
                            <svg-icon icon="angle-right" class="w-100 h-100"></svg-icon>
                        </button>
                    </div> -->
                        <div class="app-l-graph__container">
                            <canvas
                                #dasAssessmentChart
                                id="dasAssessmentChart"></canvas>
                        </div>
                        <div class="app-l-chart__legends">
                            <button
                                class="app-l-legend__stress"
                                *ngIf="typeAssessment === 'mentalwellbeing'"
                                [ngClass]="{
                                    actve:
                                        selectedChartLegend ===
                                        'mentalwellbeing'
                                }"
                                (click)="selectLegend('mentalwellbeing')"
                                translate>
                                {{ assessmentTranslation.mentalwellbeing }}
                            </button>
                        </div>
                    </div>
                    <div class="app-l-card__assesment">
                        <div class="app-l-card__ass-header">
                            <div class="app-l-card__assh-left">
                                <h3 class="app-c-body-text--14-m" translate>
                                    assessmentTypeListing.tabTitle
                                </h3>
                                <a
                                    role="tab"
                                    tabindex="0"
                                    class="app-c-btn app-c-btn--secondary app-c-btn--curve app-c-btn--sm"
                                    routerLink="/app/wellbeing-assessments/mentalwellbeing/new"
                                    translate
                                    >das.startAssessment</a
                                >
                            </div>
                            <div class="app-l-card__assh-right">
                                <a
                                    role="tab"
                                    tabindex="0"
                                    class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon"
                                    routerLink="/app/assessments/listing">
                                    <span translate>menu.viewAll</span>
                                    <svg-icon
                                        icon="angle-right"
                                        alt="angle-right"></svg-icon>
                                </a>
                            </div>
                        </div>
                        <div class="app-l-card__ass-body">
                            <div class="app-l-ass__wrap">
                                <div
                                    tabindex="0"
                                    class="app-l-ass__block"
                                    *ngFor="
                                        let assessmentslist of listingAssessments
                                            | slice: 0 : 3;
                                        let i = index
                                    "
                                    (keypress)="
                                        assessmentPath(assessmentslist.key)
                                    ">
                                    <a
                                        tabindex="0"
                                        translate
                                        style="outline: none"
                                        [routerLink]="[
                                            '/app/assessment/' +
                                                assessmentslist.key +
                                                '/listing'
                                        ]"
                                        (keydown.Tab)="
                                            focusNextItem($event, i)
                                        ">
                                        <h5
                                            class="app-c-body-text--12-m"
                                            translate>
                                            <a translate>
                                                {{ assessmentslist.title }}
                                            </a>
                                        </h5>
                                        <p
                                            class="app-c-body-text--10"
                                            translate>
                                            {{ assessmentslist.description }}
                                        </p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
