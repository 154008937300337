import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/lib/auth.service';
import { ModalService } from 'app/lib/modal.service';
import { StorageService } from 'app/lib/storage.service';
import { UserService } from 'app/lib/user.service';
import { User } from 'app/models/user';
import { LoginAPIService } from 'app/modules/auth/lib/login-api.services';
import { ConsentModalComponent } from 'app/components/consent-modal/consent-modal.component';

@Component({
    selector: 'app-user-onboarding',
    templateUrl: './user-onboarding.component.html',
    styleUrls: ['./user-onboarding.component.scss'],
})
export class UserOnboardingComponent implements OnInit {
    user: User;
    activeslideStep: number = 0;
    onBoardingStep: number;
    showImgcard: boolean = false;
    showSurveyCard: boolean = false;
    showConsent: boolean;
    isShowAssessment: boolean = true;
    showDemographic: boolean;

    constructor(
        private loginApi: LoginAPIService,
        private router: Router,
        public storage: StorageService,
        private userService: UserService,
        private modalService: ModalService,
        public auth: AuthService
    ) {
        this.user = this.userService.getUser();
        this.showConsent = this.userService.isShowConsentInOnboarding(
            this.user
        );
        // this.isShowAssessment = this.user && this.user.forceAssessment;
        this.showDemographic =
            this.user &&
            (this.user.showDemographic || this.user.demographicCount > 0);
    }

    ngOnInit(): void {
        if (this.showDemographic && this.user.onboardingStep < 1) {
            this.onBoardingStep = 0;
        } else if (this.showConsent && this.user.onboardingStep < 2) {
            this.onBoardingStep = 1;
        } else if (this.isShowAssessment && this.user.onboardingStep < 3) {
            this.onBoardingStep = 2;
        } else {
            this.onBoardingStep = this.user.onboardingStep;
        }
        // this.onBoardingStep = this.user.onboardingStep;
        if (!this.storage.checkFlag('finished-tutorial-welcome')) {
            this.showImgcard = true;
        } else if (
            this.user.onboardingStep <= 3 &&
            this.storage.checkFlag('finished-tutorial-welcome')
        ) {
            this.showSurveyCard = true;
        }
    }

    get loginApiService() {
        return this.loginApi;
    }

    skipToLogin() {
        if (this.auth.isAuthenticated()) {
            if (this.loginApiService.redirect) {
                this.router.navigate([this.loginApiService.redirect]);
            } else {
                // this.router.navigate(['app']);
                this.storage.setFlag('finished-tutorial-welcome');
                this.showImgcard = false;
                if (this.user.onboardingStep <= 3 && !this.showImgcard) {
                    this.showSurveyCard = true;
                }
            }
        }
    }

    nextSlide() {
        if (this.activeslideStep >= 2) {
            this.showImgcard = false;
            this.storage.setFlag('finished-tutorial-welcome');
            if (this.user.onboardingStep < 3 && !this.showImgcard) {
                this.showSurveyCard = true;
            } else {
                this.router.navigate(['app']);
            }
        } else {
            this.activeslideStep = (this.activeslideStep + 1) % 3; // Adjust the number of slides as needed
        }
    }

    onBoardingNextStep() {
        switch (this.onBoardingStep) {
            case 0:
                this.openDemographicSurveyPage();
                break;
            case 1:
                this.showConsent
                    ? this.showConsentModal()
                    : this.showAssessment();
                break;
            case 2:
                this.showAssessment();
                break;
            default:
                this.router.navigate(['app']);
        }
    }

    async showConsentModal() {
        this.router.navigate(['/onboarding-consentpage'], {
            queryParams: { path: 'onboarding' },
        });
    }

    showAssessment() {
        this.router.navigate(['/onboarding-assessments/mentalwellbeing/new'], {
            queryParams: { forced: 1, path: 'onboarding' },
        });
    }

    openDemographicSurveyPage() {
        setTimeout(() => {
            this.router.navigate(
                [
                    '/onboarding-surveys',
                    this.user.demographicFormId,
                    'Demographic',
                ],
                { queryParams: { navPath: '/user-onboarding' } }
            );
        }, 500);
    }
    skipAssessment() {
        this.router.navigate(['/app']);
    }
    showSkip() {
        if (this.showConsent && this.onBoardingStep > 1) {
            return true;
        } else if (!this.showConsent && this.onBoardingStep > 0) {
            return true;
        } else {
            return false;
        }
    }
}
