import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DashboardPageExecutive } from './dashboard-executive/dashboard-executive.component';
import { UserListingPage } from './user-listing/user-listing.component';
import { UserDetailsPage } from './user-details/user-details.component';
import { InviteProfessionalListingComponent } from 'app/pages/invite-professional-listing/invite-professional-listing.component';
import { UserDetailsDashboardComponent } from './user-details-dashboard/user-details-dashboard.component';
import { UserSectionDashboardComponent } from './user-section-dashboard/user-section-dashboard.component';

const publicRoutes = [
    {
        path: '',
        component: DashboardPageExecutive,
        data: { title: 'dashboard-executive' },
    },
    {
        path: 'users',
        component: UserListingPage,
        data: { title: 'users' },
    },
    {
        path: 'user-details/:id',
        component: UserDetailsPage,
        data: { title: 'userdetails' },
    },
    {
        path: 'invites',
        component: InviteProfessionalListingComponent,
        data: { title: 'Profession Invitations' },
    },
    // {
    //     path: 'user-details-dashboard/:id',
    //     component: UserDetailsDashboardComponent,
    //     data: {
    //         title: 'Overview',
    //     },
    // },
    {
        path: 'user-section/dashboard',
        component: UserSectionDashboardComponent,
        data: {
            title: 'Dashboard',
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(publicRoutes)],
    exports: [RouterModule],
})
export class ProfessionalRoutingModule {}
