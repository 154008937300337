<div class="app-works-main-container" *ngIf="showEmptyHeader">
    <div class="app-works-header">
        <img src="../../../assets/img/welltrack-logos/well-track-white-logo.svg" alt="welltrack-logo" />
        <div class="app-works-header-bg">
            <svg-icon icon="app-works-header-bg"></svg-icon>
        </div>
    </div>
</div>
<div class="app-l-page-wrap">
    <div id="page-assessment" class="page-assessment-section" [ngClass]="{'submitLoader': showAnimation}">
        <!-- <page-header [headerText]="title" [cssClass]="cssClass" [backLink]="backLink" [backText]="back"
			[headerImage]="headerImage"></page-header> -->

        <div class="app-l-assesment__question-base" *ngIf="isLoaded && !showIntro">
            <div class="app-l-assesment__q-header" *ngIf="isLoaded">
                <h3 class="app-c-heading--h3" *ngIf="!showIntro">
                    <span class="questionTitle" translate>das.question</span>
                    <span class="questionNumber">{{active + 1 }}/{{count}}</span>
                </h3>
                <div class="app-l-assesment__q-progress">
                    <mat-progress-bar class="app-l-assesment__q-progress-bar" *ngIf="!showIntro" mode="buffer"
                        [bufferValue]="buffer" [value]="progress"></mat-progress-bar>
                </div>
            </div>



            <div class="app-l-assesment__q-body page-section-content">
                <div class="app-l-assesment__question">
                    <div class="app-l-assesment__qes-block" *ngFor="let question of questions; let i = index"
                        [ngClass]="{'active': (active === i)}">
                        <div class="intro-content" *ngIf="question.showExistingGoal">
                            <span class="question-title">{{
                                "dynamicAssessment.mentalwellbeing.introQuestionGoal"
                                | translate
                                }}:</span>
                            <p class="description">{{ existingGoal }}</p>
                        </div>
                        <!-- This section moved outside of the option loop -->
                        <div *ngIf="question['Type'] == 1 && question['Group'] == 'rating-question'">                           
                            <div class="app-l-vd__f-rate">
                                <h4 class="app-c-heading--h4" translate>{{ question.Comment }}</h4>
                                <p translate>dynamicAssessment.mentalwellbeing.rateQuestionDesc</p>
                                <div class="app-l-vd__rate-wrap">
                                    <button class="app-c-btn app-c-btn--icon app-c-btn--icon-secondary app-c-btn--icon-25" aria-label="link" 
                                        *ngFor="let option of question.options; let j = index" [class.active]="j < this.answers[i].value"
                                        (click)="onSelectionChange(i, option.OptionValue, question, option.ID)" (keypress)="onSelectionChange(i, option.OptionValue, question, option.ID)">
                                        <svg-icon icon="star-fill" class="w-100 h-100" alt="star-fill"></svg-icon>
                                        <!-- Unicode character for a star -->
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h4 aria-live="polite" tabindex="0" class="app-c-heading--h4"
                                *ngIf="question['Group'] != 'rating-question'">
                                {{ question.Comment }}
                            </h4>
                            <div [ngClass]="'wt-auto-question-buttons-' + i" class="app-l-question__wrap">

                                <div class="app-l-question__block"
                                    *ngFor="let option of question.options; let j = index;">

                                    <!-- Checkbox for Type = 2 (Multiple Options) -->
                                    <label *ngIf="question['Type'] == 2" [for]="option.ID" class="app-c-custom__select"
                                        [ngClass]="{'active': isSelected(i, option.ID)}">

                                        <input type="checkbox" [id]="option.ID" [name]="question.ID"
                                            [checked]="isSelected(i, option.ID)"
                                            (click)="onSelectionChange(i, option.OptionValue, question, option.ID)"
                                            style="display: none;" />

                                        <span class="app-c-custom__select-text">{{ option.Comment }}</span>
                                        <svg-icon icon="tick-circle" class="app-c-custom__select-icon"></svg-icon>
                                    </label>

                                    <!-- Radio Button for Type = 1 (Single Option) -->
                                    <label *ngIf="question['Type'] == 1 && question['Group'] != 'rating-question'"
                                        [for]="option.ID" class="app-c-custom__select"
                                        [ngClass]="{'active': answers[i].value === option.OptionValue}">

                                        <input type="radio" [id]="option.ID" [name]="question.ID"
                                            [checked]="answers[i].value === option.OptionValue"
                                            (click)="onSelectionChange(i, option.OptionValue, question, option.ID)"
                                            style="display: none;" />

                                        <span class="app-c-custom__select-text">{{ option.Comment }}</span>
                                        <svg-icon icon="tick-circle" class="app-c-custom__select-icon"></svg-icon>
                                    </label>

                                    <!-- Textarea for Type = 5 -->
                                    <div *ngIf="question['Type'] == 5" class="app-c-form-control-wrapper">
                                        <textarea [id]="option.ID" class="app-c-form-control"
                                            [(ngModel)]="answers[this.active].value"
                                            placeholder="{{ 'thoughtDiary.steps.0.inputText' | translate }}"
                                            [disabled]="answers[this.active].fieldDisabled" [ngClass]="{
                                                'field-disabled':answers[this.active].fieldDisabled
                                            }" (input)="onSelectionChange(i, answers[i].value, question, option.ID)">
                                        </textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="app-l-assesment__question-footer">
                    <div class="app-l-aq__f-wrap">
                        <button tabindex="0" class="app-c-btn app-c-btn--secondary app-c-btn--curve"
                            (click)="setPreviousActive()" (keypress)="setPreviousActive()"
                            *ngIf="active > 0" translate>
                            <svg-icon icon="arrow-left"></svg-icon>
                            <span translate>das.previous</span>
                        </button>

                        <button *ngIf="active === 0" class="app-c-btn app-c-btn--secondary app-c-btn--curve" (click)="backList()">
                            <svg-icon icon="arrow-left"></svg-icon>
                            <span translate>das.back</span>
                        </button>

                        <button tabindex="0" *ngIf="active < (count - 1)"
                            [ngClass]="{'btn-disabled': !answers[active].isAnswered }"
                            class="app-c-btn app-c-btn--primary app-c-btn--curve" (click)="setNextActive()"
                            (keypress)="setNextActive()">
                            <span translate>das.next</span>
                            <svg-icon icon="arrow-right"></svg-icon>
                        </button>

                        <button tabindex="0" *ngIf="!showIntro && active === count - 1"
                            class="app-c-btn app-c-btn--primary app-c-btn--curve" (click)="onSubmit()"
                            [ngClass]="checkAllAnswered() ? 'btn-primary':'fn-btn-disabled'">
                            <span translate>{{'assessments.'+quizType+'.finish'}}</span>

                            <svg-icon icon="tick"></svg-icon>
                        </button>
                    </div>
                </div>
            </div>




        </div>

        <div class="app-l-assessment__instruction" *ngIf="showIntro">
            <div class="app-l-assessment__i-body">
                <div class="app-l-assessment__icon">
                    <img [src]="'./assets/img/icons-svg/assessments/assessment_type_' +quizType+ '.svg'" />
                </div>
                <div class="app-l-assessment__content">
                    <h3 class="app-c-heading--h3" translate>
                        {{'assessments.'+quizType+'.title'}}
                    </h3>
                    <div class="app-l-assessment__content-block">
                        <h4 translate>assessments.instructions</h4>
                        <ng-container *ngIf="quizType !== 'dass'">
                            <p translate aria-live="polite" tabindex="0">
                                {{ 'assessments.' + quizType + '.intro'}}
                            </p>
                            <p *ngIf="assessmenttranslation?.subIntro" translate aria-live="polite" tabindex="0">
                                {{'assessments.' + quizType + '.subIntro'}}
                            </p>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="app-l-assessment__i-footer">
                <button class="app-c-btn app-c-btn--secondary app-c-btn--curve" (click)="setPreviousActive()">
                    <svg-icon icon="arrow-left"></svg-icon>
                    <span translate>das.previous</span>
                </button>
                <button autofocus aria-live="polite" tabindex="0" class="app-c-btn app-c-btn--primary app-c-btn--curve"
                    (click)="setNextActive()" (keypress)="setNextActive()" translate>
                    das.next
                </button>
            </div>
        </div>
    </div>
</div>

<div class="app-c-loader" *ngIf="showAnimation">
    <div class="app-c-loader__inside">
        <div class="app-c-loader__image">
            <img alt="select" src="/assets/img/loader.gif" />
        </div>
        <div class="app-c-loader__content">
            <h4 class="app-c-heading--h4">Hold on!</h4>
            <p>Your assessment is almost complete</p>
        </div>
    </div>
</div>