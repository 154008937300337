<!-- <page-loader *ngIf="!isloaded"></page-loader> -->
<div id="emergency-contact-page" [ngClass]="cssClass" *ngIf="isloaded">
	<div class="page-section" *ngIf="!showAsButton">
		<div *ngIf="contactGroupTelephone">
			<h2 aria-live="polite" role="alert" tabindex="0">
				{{ organization.contactGroup.title }}
			</h2>
			<p *ngIf="organization.contactGroup.description" aria-live="polite" role="alert" tabindex="0" translate>
				{{ organization.contactGroup.description }}
			</p>
			<p *ngIf="!organization.contactGroup.description" aria-live="polite" role="alert" tabindex="0" translate>
				contact.profesionals
			</p>

			<p aria-live="polite" role="alert" tabindex="0" translate>
				<a class="btn btn-primary share-footer-button btn-inline"
					(click)="onCallContact($event, organization.contactGroup)" translate>
					<i class="fa fa-phone icon-margin"></i>
					contact.getHelpNow
				</a>
			</p>
		</div>

		<div *ngIf="emergencyContact">
			<h2 translate>contact.need</h2>
			<div aria-live="polite" role="alert" tabindex="0" [innerHTML]="emergencyContact"></div>
		</div>

		<div *ngIf="showOther">
			<div class="row">
				<div class="col-sm-6" *ngIf="hotline && hotline.title">
					<div style="margin-bottom: 32px">
						<div class="col-sm-6" *ngIf="hotline && hotline.title">
							<h2 translate>contact.need</h2>
							<p class="mb" translate aria-live="polite" role="alert" tabindex="0"
								[innerHTML]="'contact.profesionals' | translate"></p>
							<a tabindex="0" class="btn btn-bold btn-inline" (click)="onHotline($event)"
								(keypress)="onHotline($event)" aria-live="polite">{{
								hotline.title }}</a>
						</div>
					</div>
					<!--
                        <div class="col-sm-6">
                            <h4 translate aria-live="polite" role="alert" tabindex="0">contact.subtitle</h4>
                            <p class="mb" aria-live="polite" role="alert" tabindex="0" translate>contact.body.0<strong
                                    translate>contact.body.1</strong></p>
                            <a tabindex="0" href="tel:911" class="btn btn-bold btn-inline" (click)="on911($event)"
                                (keypress)="on911($event)" translate aria-live="polite">contact.call</a>
                        </div>
                    -->
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="showAsButton">
		<ng-container *ngIf="isTemplateView" [ngTemplateOutlet]="getHelpTemplate"> </ng-container>
		<div *ngIf="!isTemplateView">
			<a class="emergncy-btn" *ngIf="showOther && hotline && hotline.title"
				(click)="hotline && onHotline(hotline.url)" translate>
				<img src="../../../assets/img/icons-svg/support.svg" alt="support icon" />
				<!-- <span translate>contact.getHelpNow</span> -->
			</a>
			<a class="emergncy-btn" *ngIf="contactGroupTelephone"
				(click)="onCallContact(organization.contactGroup.telephone)" translate>
				<img src="../../../assets/img/icons-svg/support.svg" />
			</a>
		</div>
	</div>
</div>
<ng-template let-title="title" #getHelpTemplate>
	<div *ngIf="templateType == 'actionPlan'">
		<li>
			<p class="action-plan-text">
				{{ "actionPlan.emergency.getHelp.0" | translate }}
				<a *ngIf="showOther" class="emergncy-btn-text" (click)="hotline && onHotline($event)"><b
						translate>actionPlan.emergency.getHelpNow</b></a>
				<a *ngIf="contactGroupTelephone" class="emergncy-btn-text"
					(click)="onCallContact($event, organization.contactGroup)"><b
						translate>actionPlan.emergency.getHelpNow</b></a>{{ "actionPlan.emergency.getHelp.1" | translate
				}}
			</p>
		</li>
	</div>
	<div *ngIf="templateType == 'assessmentDisclaimer'">
		<a *ngIf="showOther" class="link-title" (click)="hotline && onHotline($event)" translate>contact.getHelpNow</a>
		<a *ngIf="contactGroupTelephone" class="link-title" (click)="onCallContact($event, organization.contactGroup)"
			translate>contact.getHelpNow</a>
	</div>
	<div *ngIf="templateType == 'assessmentResult-resource'" class="resources-container">
		<a *ngIf="showOther"  class="resource-link-title" (click)="hotline && onHotline($event)" (keypress)="hotline && onHotline($event)" translate>contact.getHelpNow</a>
		<a *ngIf="contactGroupTelephone"  class="resource-link-title" (click)="onCallContact($event, organization.contactGroup)" (keypress)="onCallContact($event, organization.contactGroup)"
			translate>contact.getHelpNow</a>
	</div>
	<div class="btn-center" *ngIf="templateType == 'assessmentResult'">
		<!-- <a *ngIf="showOther" class="link-title" (click)="hotline && onHotline($event)" translate>contact.getHelpNow</a>
		<a *ngIf="contactGroupTelephone" class="link-title" (click)="onCallContact($event, organization.contactGroup)"
			translate>contact.getHelpNow</a> -->

		<button class="app-c-btn app-c-btn--secondary app-c-btn--curve" *ngIf="contactGroupTelephone"
			(click)="onCallContact($event, organization.contactGroup)"
			(keypress)="onCallContact($event, organization.contactGroup)">
			<svg-icon icon="get-help" alt="get-help"></svg-icon>
			<span translate>{{'contact.getHelpNow' }}</span>
		</button>
		
		<button class="app-c-btn app-c-btn--secondary app-c-btn--curve" (click)="hotline && onHotline($event)" *ngIf="showOther"
			(keypress)="hotline && onHotline($event)" translate>
			<svg-icon icon="get-help" alt="get-help"></svg-icon>
			<span translate>{{'contact.getHelpNow' }}</span>
		</button>
	</div>
</ng-template>
