import { Injectable } from '@angular/core';
import { ApiService } from 'app/lib/api.service';
import { LogService } from 'app/lib/log.service';
import { StorageService } from 'app/lib/storage.service';
import { UtilityService } from 'app/lib/utility.service';
@Injectable()
export class ProfessionalService {
    constructor(
        private logService: LogService,
        private storage: StorageService,
        private api: ApiService,
        private utilService: UtilityService
    ) {}

    getCounts(userID, activityId, from, to, filterAll: boolean = false) {
        let payload = {
            SynchedUserID: userID,
            Activity: activityId,
        };
        if (!filterAll) {
            let currentDate = to.toISOString().substring(0, 10);
            let rangeDate = from.toISOString().substring(0, 10);
            payload['From'] = rangeDate;
            payload['To'] = currentDate;
        }
        return this.api.get(`syncheduser/activitycount`, payload);
        // .subscribe(
        //     (result: any) => {
        //         console.log(result);

        //         return result;
        //     },
        //     error => {}
        // );
    }
    getMoodcheck(userID, from, to) {
        let currentDate = to.toISOString().substring(0, 10);
        let rangeDate = from.toISOString().substring(0, 10);
        return this.api.get('moodcheck/' + userID, {
            From: rangeDate,
            To: currentDate,
        });
    }
    getWorkSheet(userID, from, to, filterAll: boolean = false) {
        let payload = {};
        if (!filterAll) {
            let currentDate = to.toISOString().substring(0, 10);
            let rangeDate = from.toISOString().substring(0, 10);
            payload['From'] = rangeDate;
            payload['To'] = currentDate;
        }

        return this.api.get(
            'syncheduser/series/worksheetclicks/' + userID,
            payload
        );
    }
}
