import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'app/lib/api.service';
import { LogService } from 'app/lib/log.service';
import { ModalService } from 'app/lib/modal.service';
import { StorageService } from 'app/lib/storage.service';
import { UserService } from 'app/lib/user.service';
import { User } from 'app/models/user';

@Component({
    selector: 'app-consent-modal',
    templateUrl: './consent-modal.component.html',
    styleUrls: ['./consent-modal.component.scss'],
})
export class ConsentModalComponent implements OnInit {
    surveyconfirm: boolean = false;
    enableAgree: boolean = false;
    user: User;
    constructor(
        public dialogRef: MatDialogRef<ConsentModalComponent>,
        private api: ApiService,
        private log: LogService,
        private modalService: ModalService,
        private translate: TranslateService,
        public storage: StorageService,
        private userService: UserService
    ) {}

    ngOnInit(): void {
        this.enableAgree = false;
        this.user = this.userService.getUser();
    }

    // onClose() {
    // 	this.dialogRef.close();
    // }

    showOptions(event) {
        this.enableAgree = true;
    }

    consentSave() {
        this.api
            .put('users/preferences', {
                Consent: this.surveyconfirm,
            })
            .subscribe(result => {
                this.user.preferences.consent = this.surveyconfirm ? 1 : 0;
                this.userService.setUser(this.user);
                console.log('dd', this.user);
                this.dialogRef.close({ consent: this.surveyconfirm });
            });
    }
}
