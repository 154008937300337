import { DataRowOutlet } from '@angular/cdk/table';
import { UtilityService } from '../lib/utility.service';

export class Assessment {
    id: number;
    userQuizId: number;
    Name: string;
    Email: string;
    usershareStatus: boolean;
    anxiety: number;
    stress: number;
    depression: number;
    anxietylevel: number;
    depressionlevel: number;
    stresslevel: number;
    anxietylevellabel: string;
    depressionlevellabel: string;
    stresslevellabel: string;
    intrusive: number = null;
    avoidance: number = null;
    hyperarousal: number = null;
    ptsd: number = null;
    type: string;
    userId: number;
    score: number;
    rank1: number;
    rank2: number;
    rank3: number;
    rank4: number;
    rank5: number;
    rank6: number;
    quizType: string;
    created: Date;
    resourceSet: resourceSet[] = [];
    resultScore: number;
    totalCount: number;
    metricScore: number;
    answers: Array<object> = [];

    public static initializeArray(objects: any): Assessment[] {
        let results: Assessment[] = [];
        for (let i = 0; i < objects.length; i++) {
            let obj = new Assessment(objects[i]);
            results.push(obj);
        }

        return results;
    }

    constructor(data: any) {
        if (data) {
            this.id = data.ID || data.id;
            this.userQuizId = data.userQuizId || data.UserQuizID;
            this.type = data.Type || data.type;
            this.anxiety = data.Anxiety || data.anxiety;
            this.depression = data.Depression || data.depression;
            this.stress = data.Stress || data.stress;
            this.anxietylevel = data.AnxietyLevel || data.anxietylevel;
            this.depressionlevel = data.DepressionLevel || data.depressionlevel;
            this.stresslevel = data.StressLevel || data.stresslevel;
            this.anxietylevellabel =
                data.AnxietyLevelLabel || data.anxietylevellabel;
            this.depressionlevellabel =
                data.DepressionLevelLabel || data.depressionlevellabel;
            this.stresslevellabel =
                data.StressLevelLabel || data.stresslevellabel;
            this.Name = data.UserDetails?.Name;
            this.Email = data.UserDetails?.Email;
            this.usershareStatus = data.UserShareStatus || data.usershareStatus;
            this.intrusive = data.intrusive;
            this.avoidance = data.avoidance;
            this.hyperarousal = data.hyperarousal;
            this.ptsd = data.ptsd;
            this.score = data.Score || data.score;
            this.rank1 = data.Rank1;
            this.rank2 = data.Rank2;
            this.rank3 = data.Rank3;
            this.rank4 = data.Rank4;
            this.rank5 = data.Rank5;
            this.rank6 = data.Rank6;
            this.totalCount = data.totalCount;
            this.userId = data.UserID || data.userid;
            this.type = data.Type || data.type;
            this.resultScore = data.Score || data.score;
            this.metricScore = data.MetricScore || data.metricScore;
            this.created = UtilityService.convertToDate(
                data.Created || data.created
            );
            this.initializeResourcesets(data.resourceSet);
            this.initializeAnswers(data.Answers || data.answers);
        }
    }

    public initializeAnswers(answers?: any) {
        if (answers) {
            this.answers = Object.keys(answers).map(key => {
                return { id: key, ...answers[key] };
            });
        }
    }

    public initializeResourcesets(resourceSets?: any) {
        if (resourceSets) {
            for (let i = 0; i < resourceSets.length; i++) {
                this.resourceSet.push(new resourceSet(resourceSets[i]));
            }
        } else {
            for (let i = 0; i < 6; i++) {
                this.resourceSet.push(new resourceSet());
            }
        }
    }
}
export class resourceSet {
    id: number;
    title: string = '';
    categories: string[];
    summary: string = '';
    number: number;
    resourcesetGroup: ResourceSetGroup[] = [];
    language: string;
    className: string;
    constructor(data?: any) {
        if (data) {
            this.id = data.ID || data.id;
            this.title = data.Title || data.title;
            this.categories = data.Categories || data.categories;
            this.summary = data.Summary || data.summary;
            this.number = data.Number || data.number;
            this.language = data.Language || data.language;
            this.initializeGroups(data.resourcesetGroup);
        } else {
            this.initializeGroups();
        }
    }

    public initializeGroups(resourceSetGroups?: any) {
        if (resourceSetGroups) {
            resourceSetGroups.forEach(resourceSetGroup => {
                this.resourcesetGroup.push(
                    new ResourceSetGroup(resourceSetGroup)
                );
            });
        } else {
            for (let i = 0; i < 1; i++) {
                this.resourcesetGroup.push(new ResourceSetGroup());
            }
        }
    }
}
export class ResourceSetGroup {
    id: number;
    title: string = '';
    contact: string = '';
    alternateContact: string = '';
    address: string = '';
    websiteTitle: string = '';
    website: string = '';
    description: string = '';
    internal: string = '';
    active: number;
    language: string;
    constructor(data?: any) {
        if (data) {
            this.id = data.ID || data.id;
            this.title = data.Title || data.title;
            this.contact = data.Contact || data.contact;
            this.alternateContact =
                data.AlternateContact || data.alternateContact;
            this.websiteTitle = data.WebsiteTitle || data.websiteTitle;
            this.website = data.Website || data.website;
            this.address = data.Address || data.address;
            this.description = data.Description || data.description;
            this.internal = data.Internal || data.internal;
            this.active = data.Active !== '' ? data.Active : data.active;
            this.language = data.Language || data.language;
        }
    }
}

export class AssessmentPDF {
    questionId: number;
    userQuizId: number;
    userId: number;
    quizId: number;
    questions: questions[] = [];

    public static initializeArray(objects: any): AssessmentPDF[] {
        let results: AssessmentPDF[] = [];
        for (let i = 0; i <= objects.length; i++) {
            let obj = new AssessmentPDF(objects[0]);
            results.push(obj);
        }

        return results;
    }

    constructor(data: any) {
        if (data) {
            this.questionId = data.QuestionID || data.questionID;
            this.userQuizId = data.userQuizId || data.UserQuizID;
            this.userId = data.UserID || data.userId;
            this.quizId = data.QuizID || data.quizID;
            this.initializeQuestionsets(data.Questions);
        }
    }

    public initializeQuestionsets(questionsList?: any) {
        if (questionsList) {
            for (let i = 0; i < questionsList.length; i++) {
                this.questions.push(new questions(questionsList[i]));
            }
        } else {
            for (let i = 0; i < 6; i++) {
                this.questions.push(new questions());
            }
        }
    }
}

export class questions {
    answerOptionId: number;
    answerValue: string = '';
    group: string;
    question: string;
    questionType: number;
    optionComment: string;
    constructor(data?: any) {
        if (data) {
            this.answerOptionId = data.AnswerOptionID || data.answerOptionID;
            this.answerValue = data.AnswerValue || data.answerValue;
            this.group = data.Group || data.group;
            this.question = data.Question || data.question;
            this.questionType = data.QuestionType || data.questionType;
            this.optionComment = data.OptionComment || data.optionComment;
        }
    }
}

export class ASSESSMENT_TYPES_CONFIG {
    public static ASSESSMENT_TYPE_LIST = [
        {
            title: 'assessmentTypeListing.types.mentalWellBeingAssessment.title',
            key: 'mentalwellbeing',
            description:
                'assessmentTypeListing.types.mentalWellBeingAssessment.description',
            enabled: 1,
        },
        {
            title: 'assessmentTypeListing.types.dasAssessment.title',
            key: 'dass',
            description:
                'assessmentTypeListing.types.dasAssessment.description',
            enabled: 1,
        },
        {
            title: 'assessmentTypeListing.types.resAssessment.title',
            key: 'resilience',
            description:
                'assessmentTypeListing.types.resAssessment.description',
            enabled: 1,
        },
        {
            title: 'assessmentTypeListing.types.auAssessment.title',
            key: 'alcoholuse',
            description: 'assessmentTypeListing.types.auAssessment.description',
            enabled: 1,
        },
        {
            title: 'assessmentTypeListing.types.anxietyAssessment.title',
            key: 'anxiety',
            description:
                'assessmentTypeListing.types.anxietyAssessment.description',
            enabled: 1,
        },
        {
            title: 'assessmentTypeListing.types.depressionAssessment.title',
            key: 'depression',
            description:
                'assessmentTypeListing.types.depressionAssessment.description',
            enabled: 1,
        },
    ];
}

export class ASSESSMENT_TYPES_CONFIG_HOME {
    public static ASSESSMENT_TYPE_LIST = [
        {
            title: 'assessmentTypeListing.types.mentalWellBeingAssessment.dashTitle',
            key: 'mentalwellbeing',
            description:
                'assessmentTypeListing.types.mentalWellBeingAssessment.dashDescription',
        },
        {
            title: 'assessmentTypeListing.types.dasAssessment.dashTitle',
            key: 'dass',
            description:
                'assessmentTypeListing.types.dasAssessment.dashDescription',
        },
        {
            title: 'assessmentTypeListing.types.anxietyAssessment.dashTitle',
            key: 'anxiety',
            description:
                'assessmentTypeListing.types.anxietyAssessment.dashDescription',
        },
    ];
}
