import {
    Component,
    OnInit,
    Input,
    Output,
    Inject,
    EventEmitter,
    ViewChild,
    TemplateRef,
} from '@angular/core';
import { Moodcheck } from '../../models/moodcheck';
import * as moment from 'moment';
import { MoodcheckService } from '../../lib/moodcheck.service';
import { UserService } from '../../lib/user.service';
import { User } from '../../models/user';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../lib/api.service';
import { LogService } from '../../lib/log.service';
import { DateService } from '../../lib/url.service';
import {
    MatDialog,
    MAT_DIALOG_DATA,
    DialogPosition,
    MatDialogRef,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-weekly-moodcheck-view',
    templateUrl: './weekly-moodcheck-view.component.html',
    styleUrls: ['./weekly-moodcheck-view.component.scss'],
})
export class WeeklyMoodcheckViewComponent implements OnInit {
    weeklyMoodcheckCategory = [
        { key: 'fantastic', value: 0 },
        { key: 'veryHappy', value: 0 },
        { key: 'happy', value: 0 },
        { key: 'good', value: 0 },
        { key: 'fine', value: 0 },
        { key: 'ok', value: 0 },
        { key: 'notGreat', value: 0 },
        { key: 'bad', value: 0 },
        { key: 'veryBad', value: 0 },
        { key: 'awful', value: 0 },
    ];
    @Output() dateChanged: EventEmitter<Date> = new EventEmitter<Date>();
    @ViewChild('moodcheckDailyModal') modalTemplate!: TemplateRef<any>;
    @Input() moodcheckData: Moodcheck[];
    @Input() receivedDate: Date;
    user: User;
    weeklyMoodcheckData: Moodcheck[];
    hasData: boolean = false;
    isLoading: boolean = true;
    dashboardLangKey: any;
    weekStartDate: any;
    weekEndDate: any;
    weekDays: any = [];
    isCurrentWeek: boolean = true;
    hasSelectedDayData: boolean = false;
    isSelectedDayToday: boolean = false;
    selectedDate: any = moment.parseZone().format();
    today: any = moment.parseZone().format();
    modalDailySelectedDate: any = moment.parseZone().format();
    selectedMonth: any = moment().format('MM');
    displayDate: any = moment().format('MMM');
    weekNumber: any;
    showLegends: boolean = true;
    noDatavalue: boolean = true;
    classDate: string;
    paramsSub: any;
    id: number;
    constructor(
        private mcService: MoodcheckService,
        private api: ApiService,
        private userService: UserService,
        private activatedRoute: ActivatedRoute,
        private translate: TranslateService,
        private log: LogService,
        private dateservice: DateService,
        public dialog: MatDialog
    ) {
        this.translate.stream('dashboard').subscribe((res: any) => {
            this.dashboardLangKey = res;
        });
    }
    private dialogRef!: MatDialogRef<any, any>;

    ngOnInit(): void {
        this.user = this.userService.getUser();
        this.weekStartDate = moment().startOf('week').format();
        this.weekEndDate = moment().endOf('week').format();
        this.weekNumber = this.getWeekNumber();
    }

    getWeekNumber() {
        const dateMoment = moment(this.selectedDate);
        const firstDayOfMonth = dateMoment.clone().startOf('month');
        const weekNumber = dateMoment.diff(firstDayOfMonth, 'weeks') + 1;
        return weekNumber;
    }

    filterWeeklyMoodcheckData(data: any, currentDate?: any) {
        this.moodcheckData = data;
        this.selectedDate = moment(currentDate).format('YYYY-MM-DD');
        this.classDate = moment(currentDate).format('YYYY-MM-DD');
        this.weekNumber = this.getWeekNumber();
        this.displayDate = moment(this.selectedDate).format('MMM');
        this.isCurrentWeek = this.checkCurrentWeek(this.selectedDate);
        this.weekDays = this.filterArrayByDateRange(data, this.selectedDate);
        Object.values(this.weekDays).forEach((item: any) => {
            const totalDataCount = item.data.length;
            if (totalDataCount > 0) {
                this.weeklyMoodcheckData = this.mcService.categorizeMoodcheck(
                    item.data
                );
                for (const property in this.weeklyMoodcheckData) {
                    let data: any = this.weeklyMoodcheckData[property];
                    let index = 11 - data.value;
                    item.moodcheckCategory[index - 1].value = data.data.length
                        ? (data.data.length / totalDataCount) * 100
                        : 0;
                }
            } else {
                item.moodcheckCategory.forEach(item => {
                    item.value = 0;
                });
            }
        });
        const areAllZerosForAllDates = this.checkIfAllZerosForAllDates(
            this.weekDays
        );
        if (areAllZerosForAllDates) {
            this.noDatavalue = true;
        } else {
            this.noDatavalue = false;
        }
    }

    checkIfAllZerosForAllDates(dates) {
        for (const date in dates) {
            if (dates.hasOwnProperty(date)) {
                if (
                    !dates[date].moodcheckCategory.every(
                        category => category.value === 0
                    )
                ) {
                    return false;
                }
            }
        }
        return true;
    }

    checkCurrentWeek(date: any) {
        let start = moment().startOf('week').format();
        let end = moment().endOf('week').format();
        const createdDate = moment(date);
        return createdDate.isBetween(start, end, null, '[]');
    }

    setCalendarDate(date) {
        this.selectedDate = date;
        var currentMonth = moment(this.selectedDate).format('MM');
        if (currentMonth == this.selectedMonth) {
            this.filterWeeklyMoodcheckData(
                this.moodcheckData,
                this.selectedDate
            );
        } else {
            this.selectedMonth = moment(this.selectedDate).format('MM');
            let checkdate = moment(this.selectedDate).startOf('month').format();
            this.getMoodcheckData(checkdate);
        }
    }

    onClickPrevBtn() {
        let date = moment(this.selectedDate).add(-7, 'days').format();
        this.checkMonth(date);
        let passdate: Date = new Date(date);
        this.dateservice.setSelectedDate(passdate);
    }

    onClickNextBtn() {
        let date = moment(this.selectedDate).add(7, 'days').format();
        this.checkMonth(date);
        let passdate: Date = new Date(date);
        this.dateservice.setSelectedDate(passdate);
    }

    checkMonth(date: any) {
        var weekStartMonth = moment(date).startOf('week').format('MM');
        var weekEndMonth = moment(date).endOf('week').format('MM');
        if (weekStartMonth == weekEndMonth) {
            this.filterWeeklyMoodcheckData(this.moodcheckData, date);
        } else {
            this.getMoodcheckData(date);
        }
    }

    getMoodcheckData(date) {
        if (this.user.userType != 'user') {
            this.paramsSub = this.activatedRoute.params.subscribe(params => {
                const tempId = parseInt(params['id'], 10);
                if (tempId) {
                    this.id = tempId;
                } else {
                    this.id = this.user.id;
                }
            });
        } else {
            this.id = this.user.id;
        }
        this.isLoading = true;
        this.hasData = false;
        let startDate: any = moment(date)
            .subtract(1, 'months')
            .startOf('month')
            .format('YYYY-MM-DD');
        let endDate: any = moment(date)
            .endOf('week')
            .endOf('month')
            .format('YYYY-MM-DD');
        this.mcService.getMoodcheckData(startDate, endDate, this.id).then(
            result => {
                this.filterWeeklyMoodcheckData(result, date);
            },
            error => {
                this.filterWeeklyMoodcheckData([], date);
            }
        );
    }

    setWeekDates(startDate: any) {
        var dates = {};
        var dayOfWeek = moment(startDate).startOf('week');
        for (var i = 0; i < 7; i++) {
            dates[dayOfWeek.format('L')] = {
                shortDate: dayOfWeek.format('MMM DD,ddd'),
                date: dayOfWeek.format('YYYY-MM-DD'),
                data: [],
                isToday: this.checkToday(dayOfWeek),
                moodcheckCategory: [
                    { key: 'fantastic', value: 0, iconIndex: 10 },
                    { key: 'veryHappy', value: 0, iconIndex: 9 },
                    { key: 'happy', value: 0, iconIndex: 8 },
                    { key: 'good', value: 0, iconIndex: 7 },
                    { key: 'fine', value: 0, iconIndex: 6 },
                    { key: 'ok', value: 0, iconIndex: 5 },
                    { key: 'notGreat', value: 0, iconIndex: 4 },
                    { key: 'bad', value: 0, iconIndex: 3 },
                    { key: 'veryBad', value: 0, iconIndex: 2 },
                    { key: 'awful', value: 0, iconIndex: 1 },
                ],
            };
            dayOfWeek.add(1, 'd');
        }
        return dates;
    }

    checkToday(date: any): boolean {
        const today = moment().startOf('day');
        const inputDate = moment(date).startOf('day');
        return today.isSame(inputDate);
    }

    filterArrayByDateRange(data: any, date: any) {
        let weekDays = this.setWeekDates(date);
        this.weekStartDate = moment(date).startOf('week').format();
        this.weekEndDate = moment(date).endOf('week').format();
        data.forEach(item => {
            const createdDate = moment(item.created);
            if (
                createdDate.isBetween(
                    this.weekStartDate,
                    this.weekEndDate,
                    null,
                    '[]'
                )
            ) {
                weekDays[createdDate.format('L')].data.push(item);
            }
        });
        return weekDays;
    }

    openMoodcheckDailyModal(dailyData: any, event: any): void {
        this.hasSelectedDayData = dailyData.value.data.length ? true : false;
        this.isSelectedDayToday = dailyData.value.isToday;
        this.weeklyMoodcheckCategory = dailyData.value.moodcheckCategory;
        this.modalDailySelectedDate = dailyData.value.date;
        // let positiondialog: DialogPosition = { left: event.clientX + 'px', top: event.clientY + 'px'};
        const dialogRef = this.dialog.open(this.modalTemplate, {
            width: '480px',
            maxWidth: '100%',
            panelClass: ['app-l-modal__moods-days', 'app-c-modal--cure'],
            data: {
                hasSelectedDayData: dailyData.value.data.length ? true : false,
                isSelectedDayToday: dailyData.value.isToday,
                weeklyMoodcheckCategory: dailyData.value.moodcheckCategory,
                modalDailySelectedDate: dailyData.value.date,
            },
            //  position: positiondialog,
        });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
