import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Assessment, AssessmentPDF } from '../models/assessment';
import { StorageService } from 'app/lib/storage.service';
import { AssessmentService } from 'app/lib/assessment.service';
import { User } from '../models/user';
import { AnalyticEvent } from 'app/lib/analytic-event';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { UtilityService } from 'app/lib/utility.service';
import * as moment from 'moment';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
import * as _ from "lodash";
import { LogService } from "./log.service";

@Injectable({
    providedIn: "root",
})
export class AssessmentPdfService {
    base64LogoImage: any;
    base64HeaderBarImage: any;
    pdfObj = null;
    popups: any;
    assessmentType: string;
    assessment: Assessment;
    assessmentPdfView: AssessmentPDF[];
    assessmentAnswerList: any;
    resourcesResult: Array<any> = [];
    assessmentResult: Array<any> = [];
    translationData: any;
    translationResData: any;
    resourcesQuestions: any[];
    currentLanguage: any;
    translationResources: any;
    content: any[];
    public dd = {
        content: [],
        styles: {
            header: {
                bold: true,
                fontSize: 15,
                margin: [0, 15]
            }
        },
        defaultStyle: {
            fontSize: 12
        }
    }
    constructor(
        private utilityService: UtilityService,
        private translate: TranslateService,
        public storage: StorageService,
        private log: LogService,
    ) {
        this.translate.stream('dynamicAssessment').subscribe((res: any) => {
            this.translationData = res;
        });
        this.translate.stream('dynamicAssessments').subscribe((res: any) => {
            this.translationResData = res;
        });
        this.translate.stream('resources').subscribe((res: any) => {
            this.translationResources = res;
        });
    }

    getLangForResourceList() {
        let selectedLang = this.storage.get('lang');
        if (
            (selectedLang === 'fr' || selectedLang === 'es') &&
            !this.hasResourceByLangage(selectedLang, this.assessment[0].resourceSet)
        ) {
            selectedLang = 'en';
        }
        return selectedLang;
    }

    hasResourceByLangage(lang: string, resourceSet: any): boolean {
        return resourceSet.some(r => r['language'] === lang);
    }

    initialize(assessmentData: any, assessmentType: string) {
        this.assessmentType = assessmentType;
        this.assessment = assessmentData;
        if (this.assessment[0].resourceSet.length > 0) {
            let lang = this.getLangForResourceList();
            this.resourcesResult = this.assessment[0].resourceSet.filter(
                (item) => item.language == lang
            );
                this.resourcesQuestions = [];
                const questionObject = {
                    Question: this.translationResData.resourceQuestionTitle,
                    OptionComments: []
                };
                this.resourcesResult.forEach(qs => {
                    questionObject.OptionComments.push(qs.title);
                });
                this.resourcesQuestions.push(questionObject);
        }
    }

    async exportAssessemntResult(assessmentResult: Array<any>, assessmentAnswerList: any) {

        this.assessmentPdfView = assessmentAnswerList;
        this.assessmentResult = assessmentResult;

        const base64LogoImage = await this.utilityService.loadImageFromAsset(
            "./assets/header-logo-1.jpg"
        );
        const base64HeaderBarImage = await this.utilityService.loadImageFromAsset(
            "./assets/header-bar.jpg"
        );

        let logoImage = `data:image/svg;base64,${base64LogoImage}`;
        let headerBarImage = `data:image/svg;base64,${base64HeaderBarImage}`;
        let content = [
            {
                image: logoImage,
                alignment: "center",
                margin: [0, 0, 0, 10],
                width: 300,
            },
            {
                image: headerBarImage,
                alignment: "center",
                width: 600,
            },

            {
                stack: [
                    {
                        text: this.translationData[this.assessmentType].title,
                        style: "header",
                        color: "#3a0165",
                    },
                    {
                        text:
                            this.translate.instant(
                                "dynamicAssessment.createdOn"
                            )  +
                            ": " +
                            moment(
                                this.assessment[0].created
                            ).format("MM-DD-YYYY"),
                        style: "subheader",
                        color: "#3a0165",
                    },
                ],
                alignment: "center",
                margin: [0, 20, 0, 20],
            },
        ];
        this.createPdfData(content)
        var data = {
            footer: function (currentPage, pageCount) {
                return [
                    {
                        text: currentPage.toString() + " of " + pageCount,
                        alignment: "center",
                        opacity: 0.5,
                    },
                ];
            },

            content: content,

            styles: {
                mainheader: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 20, 0, 10],
                },
                header: {
                    fontSize: 16,
                    bold: true,
                },
                subheader: {
                    fontSize: 8,
                    bold: true,
                },
                sectionHeader: {
                    fontSize: 14,
                    bold: true,
                },
                subSectionHeader: {
                    fontSize: 12,
                    bold: true,
                    margin: [0, 7, 0, 6],
                },
                subContentData: {
                    fontSize: 10,
                    bold: false,
                    margin: [0, 0, 0, 2],
                },
                subContentDataTitle: {
                    fontSize: 10,
                    bold: false,
                    margin: [0, 1, 0, 2],
                },
                indentedContent: {
                    fontSize: 10,
                    alignment: 'left',
                    margin: [0, 10, 0, 3]
                },
                subAnswerTitle: {
                    fontSize: 10,
                    bold: true,
                    margin: [0, 0, 0, 2],
                },
            },
        };
        const fileName = this.translationData[this.assessmentType].title +
            "_" +
            moment().format("MM-DD-YYYY_HH.mm.ss") +
            ".pdf";
        pdfMake.createPdf(data).open();
    }

    createPdfData(content: any) {
        this.log.event(AnalyticEvent.event.pdfDownloaded);
        if (this.assessmentType === 'mentalwellbeing') {
            this.generateMWAQuestionAnswerPdfContent(content);
            this.generateMWAResultPdfContent(content);
        } else {
            this.generateQuestionAnswerPdfContent(content);
        }
    }

    generateQuestionAnswerPdfContent(content){
        const questionTitleConfig = this.createSectionHeader("\n\n$questionTitle$\n\n", -25);
        const sectionTitleConfig = this.createSectionHeader("\n\n$sectionTitle$\n\n", -25);
        const resourceTitleConfig = this.createSectionHeader("\n\n$resourceTitle$\n\n", -15, 0);

        const dynamicTitle = this.translationData[this.assessmentType].questionTitle;

        // Add question title
        const questionTitle = { ...questionTitleConfig, text: questionTitleConfig.text.replace("$questionTitle$", dynamicTitle) };
        content.push(questionTitle);

        // Initialize the combined list for questions and resources
        let combinedQuestions = [];

        // Generate regular questions (with answers) and add to the combined list
        if (this.assessmentPdfView && this.assessmentPdfView[0].questions) {
            combinedQuestions = combinedQuestions.concat(
                this.generateQuestions(this.assessmentPdfView[0].questions, 'question', 'optionComment', 'answer')
            );
        }

        // Generate resource questions (with answers) and append to the combined list
        if (this.resourcesQuestions && this.assessmentType === 'dass') {
            combinedQuestions = combinedQuestions.concat(
                this.generateQuestions(this.resourcesQuestions, 'Question', 'OptionComments', 'Answer')
            );
        }

        // Add the combined list of questions (regular + resource) as a numbered list
        content.push({
            ol: combinedQuestions,  // 'ol' ensures the list is numbered
            start: 1  // Start numbering from 1 (will continue through the combined list)
        });

        const assessmentResultTitle = this.translationResData.assessmentResults;
        // Add section title for results
        const sectionTitle = { ...sectionTitleConfig, text: sectionTitleConfig.text.replace("$sectionTitle$", assessmentResultTitle) };
        content.push(sectionTitle);

        // Add assessment results
        content.push(...this.generateAssessmentResults(this.assessmentResult));

        // Add resources if available
        if (this.assessment[0].resourceSet.length !== 0) {
            const recommendedResourceTitle = this.translationResources.recommendedResources;
            const resourceTitle = { ...resourceTitleConfig, text: resourceTitleConfig.text.replace("$resourceTitle$", recommendedResourceTitle) };
            content.push(resourceTitle);
            content.push(...this.generateResourceSet(this.assessment[0].resourceSet));
        }
    }

    // Helper function to create section headers
    createSectionHeader(text: string, marginTop: number, marginBottom = 0) {
        return {
            text,
            style: "sectionHeader",
            color: "#3a0165",
            margin: [0, marginTop, 0, marginBottom]
        };
    }

    // Helper function to generate questions
    generateQuestions(questions: any[], questionKey = 'question', optionKey = 'optionComment', answerKey = 'answer') {
        const questionsList = [];

        // Loop through each question set
        for (const questionSet of questions) {
            const questionStack = [
                {
                    text: questionSet[questionKey],
                    style: "subContentData",
                    alignment: 'left',
                    bold: false,
                    margin: [0, 5, 0, 0]
                }
            ];

            // Handle options (could be an array or a string)
            const optionsList = Array.isArray(questionSet[optionKey]) ? questionSet[optionKey] : [questionSet[optionKey]];
            optionsList.forEach(option => {
                if (option) {
                    questionStack.push({
                        text: option,
                        style: "subContentData",
                        alignment: 'left',
                        bold: true,
                        margin: [10, 2, 0, 0]
                    });
                }
            });

            // Handle the answer (if available)
            if (questionSet[answerKey]) {
                questionStack.push({
                    text: `Answer: ${questionSet[answerKey]}`,
                    style: "subContentData",
                    alignment: 'left',
                    bold: true,
                    margin: [10, 5, 0, 10]  // Adjust margin for the answer
                });
            }

            questionsList.push({ stack: questionStack });
        }

        return questionsList;
    }

    // Helper function to generate assessment results
    generateAssessmentResults(results: any[]) {
        const subSectionData = [];


        results.forEach(result => {
            const translateVariable = this.assessmentType === 'dass' ? 'dynamicAssessments.' : '';
            // Wrap the content in `columns` array
            const subSectionContentData = {
                text: [
                    {
                        text: this.translate.instant(translateVariable + result.title),
                        style: "subSectionHeader"
                    },
                    {
                        text: '\u00A0' + '-' + '\u00A0',
                    },
                    {
                        text: this.translate.instant(result.label),
                        style: "subSectionHeader"
                    }
                ],
                margin: [0, 5, 0, 7]
            };

            subSectionData.push(subSectionContentData);

            // Add additional content if available
            if (result.levelText) subSectionData.push(this.createSubContent(translateVariable, result.levelText, result.label));
            if (result.content1) subSectionData.push(this.createSubContent(translateVariable, result.content1));
            if (result.content2) subSectionData.push(this.createSubContent(translateVariable, result.content2, undefined, true));
            if (result.content3) subSectionData.push(this.createSubContent(translateVariable, result.content3));
        });

        return subSectionData;
    }

    // Helper function to create sub content
    createSubContent(translateVariable: string, content: string, label?: string, indented = false) {
        return {
            style: indented ? "indentedContent" : "subContentDataTitle",
            text: this.parseFormattedString(this.translate.instant(translateVariable + content, { value: label }))
        };
    }

    // Helper function to generate resource set
    generateResourceSet(resourceSet: any[]): any[] {
        const resourceSectionData = [];

        resourceSet.forEach(result => {
            // Create the resource header (e.g., "Académique") as the first item in the stack
            const subResourceContentData = [
                {
                    stack: [
                        {
                            text: result.title,
                            style: "subAnswerTitle",
                            alignment: 'left',
                            margin: [0, 7, 0, 7],  // Adjusted margin for better spacing
                            lineHeight: 0.8
                        }
                    ]
                }
            ];

            // Loop through each resource group and add its title and contact details
            result.resourcesetGroup.forEach(resourceSetGrp => {
                const relevantProperties = [
                    { value: resourceSetGrp.title },
                    { value: resourceSetGrp.description },
                    { value: resourceSetGrp.address },
                    { value: resourceSetGrp.contact },
                    { value: resourceSetGrp.alternateContact },
                    { value: resourceSetGrp.websiteTitle || resourceSetGrp.website }
                ];

                // Create a new array to hold the resource group details
                const subResourceSetGrp = relevantProperties.reduce((acc, { value }) => {
                    if (value) {
                        acc.push({
                            text: value, // Just use the value directly
                            style: "subContentData",
                            alignment: "left",
                            margin: [10, 2, 0, 0],
                            lineHeight: 1
                        });
                    }
                    return acc;
                }, []);

                // Push the collected resource group details into the stack
                if (subResourceSetGrp.length > 0) {
                    subResourceContentData.push(...subResourceSetGrp);
                }
            });

            // Add the fully constructed resource content into the main data array
            resourceSectionData.push(...subResourceContentData);
        });

        return resourceSectionData;
    }

    generateMWAQuestionAnswerPdfContent(content: any) {
        let questionTitleConfig = {
          text:
            "\n\n" +
            this.translationData[this.assessmentType].questionTitle +
            "\n\n",
          style: "sectionHeader",
          color: "#3a0165",
          margin: [0, -25, 0, 0],
        };
        content.push(questionTitleConfig);
        if (this.assessment[0]) {
          let questionSectionContentData = [];
          let questionsList = [];
          if (this.assessment[0].answers) {
            this.assessment[0].answers.forEach((questionSet: any) => {
              const questionStack = [
                {
                  text: questionSet.Question,
                  style: "subContentData",
                  alignment: "left",
                  bold: false,
                  margin: [0, 5, 0, 0],
                },
              ];
              questionSet.Answers.forEach((option) => {
                questionStack.push({
                  text: option.OptionComment || option.AnswerValue,
                  style: "subContentData",
                  alignment: "left",
                  bold: true,
                  margin: [10, 2, 0, 0],
                });
              });
              questionsList.push({
                stack: questionStack,
              });
            });
          }
          questionSectionContentData.push({
            ol: questionsList,
          });
          content.push(...questionSectionContentData);
        }
      }

    generateMWAResultPdfContent(content: any) {
        let sctionTitleConfig = {
          text:
            "\n\n" +
            this.translate.instant(
              "dynamicAssessments.assessmentResults"
            ) +
            "\n\n",
          style: "sectionHeader",
          color: "#3a0165",
          margin: [0, -20, 0, -15],
        };
        let subContent1 = {
          style: "subContentData",
          lineHeight: 1,
          margin: [0, 5, 0, 0],
        };
        let subContent2 = {
          style: "subContentData",
          lineHeight: 1,
          margin: [0, 5, 0, 0],
        };
        let recommended = {
          style: "subContentData",
          lineHeight: 1,
          bold: true,
          margin: [0, 5, 0, 0],
        //   decoration: "underline",
        };
    
        content.push(sctionTitleConfig);
    
        for (let result of this.assessmentResult) {
          let subSectionContentData = [];
    
          subSectionContentData.push({
            text:
              this.translate.instant(result.title) +
              " - " +
              this.translate.instant(result.label),
            style: "subSectionHeader",
            alignment: "left",
            margin: [0, 10, 0, -10],
          });
    
          subSectionContentData.push({
            text:
            "\n\n" +
            this.translationData[this.assessmentType].thanksForSharing +
            "\n\n",
            style: "subContentData",
            // lineHeight: 1,
            margin: [0, 0, 0, -10],
            bold: true,
          });
    
          if (result.levelText) {
            let subSectionContent = { ...subContent1 };
            let text = this.translate.instant(result.levelText, {
              value: result.level,
            });
            subSectionContent["text"] = this.parseFormattedString(text);
            subSectionContentData.push(subSectionContent);
          }
          if (result.content1_1) {
            let subSectionContent = { ...subContent1 };
            let text = this.translate.instant(result.content1_1);
            subSectionContent["text"] = this.parseFormattedString(text);
            subSectionContentData.push(subSectionContent);
          }
          if (result.content1) {
            let subSectionContent = { ...subContent1 };
            let text = this.translate.instant(result.content1);
            subSectionContent["text"] = this.parseFormattedString(text);
            subSectionContentData.push(subSectionContent);
          }
          if (result.content2) {
            let subSectionContent = { ...subContent2 };
            let text = this.translate.instant(result.content2);
            subSectionContent["text"] = this.parseFormattedString(text);
            subSectionContentData.push(subSectionContent);
          }
          if (result.recommendationList.length) {
            let subSectionContent = { ...recommended };
            let list = [];
            result.recommendationList.forEach((option) => {
              let subSectionContent = { ...recommended };
              //   let text = this.translate.instant(option.name);
              //   list.push(this.parseFormattedString(text));
              subSectionContent["text"] = this.translate.instant(option.name);
              subSectionContentData.push(subSectionContent);
            });
            // subSectionContent["text"] = list.join(", ");
            // subSectionContentData.push(subSectionContent);
          }
          if (result.content3) {
            let subSectionContent = { ...subContent2 };
            let text = this.translate.instant(result.content3);
            subSectionContent["text"] = this.parseFormattedString(text);
            subSectionContentData.push(subSectionContent);
          }
          content.push(subSectionContentData);
        }
      }

    parseFormattedString(input: string): (string | { text: string; fontSize: number; bold?: boolean; decoration?: string })[] {
        const result: (string | { text: string; fontSize: number; bold?: boolean; decoration?: string })[] = [];

        const regex = /(<strong>|<\/strong>|<u>|<\/u>)/g;
        const parts = input.split(regex);

        let isBold = false;
        let isUnderline = false;

        parts.forEach(part => {
            switch (part) {
                case '<strong>':
                    isBold = true;
                    break;
                case '</strong>':
                    isBold = false;
                    break;
                case '<u>':
                    isUnderline = true;
                    break;
                case '</u>':
                    isUnderline = false;
                    break;
                default:
                    if (part) {
                        if (isBold || isUnderline) {
                            result.push({
                                text: part,
                                fontSize: 10,
                                bold: isBold || undefined,
                                decoration: isUnderline ? 'underline' : undefined,
                            });
                        } else {
                            result.push(part);
                        }
                    }
                    break;
            }
        });

        return result;
    }
}
