import { Component, ViewChild, OnInit, ViewEncapsulation } from '@angular/core';
// import { MoodTab } from './mood-tab/mood-tab';
// import { ActivityTab } from './activity-tab/activity-tab';
import { StorageService } from '../../lib/storage.service';
//import { TutorialPage } from '../tutorial/tutorial';
// import { PlaceTab } from './place-tab/place-tab';
// import { PeopleTab } from './people-tab/people-tab';
// import { NoteTab } from './note-tab/note-tab';
import { MoodcheckService } from '../../lib/moodcheck.service';
import { ApiService } from '../../lib/api.service';
import { Moodcheck } from '../../models/moodcheck';
import { LogService } from '../../lib/log.service';

declare var jQuery;
import * as _ from 'lodash';
import { ModalService } from '../../lib/modal.service';
import { MoodcheckOptionsPage } from './moodcheck-options/moodcheck-options';
import { MoodcheckOptionsService } from '../../lib/moodcheck-options.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';

import { EventManagerService } from '../../lib/event-manager.service';
import { UtilityService } from '../../lib/utility.service';
import { UserService } from '../../lib/user.service';
import { AnalyticEvent } from 'app/lib/analytic-event';
import { User } from 'app/models/user';

@Component({
    selector: 'app-moodcheck-modal',
    templateUrl: './moodcheck-modal.component.html',
    styleUrls: ['./moodcheck-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class MoodcheckModalComponent implements OnInit {
    modalVariable = true;
    tabIndex: number;
    activeTab: string;
    saveEnabled: boolean;
    showOptions: boolean;
    tabs = ['mood', 'activity', 'people', 'place', 'notes'];
    popup: any;
    demo: boolean = false;
    submitting: boolean;
    moodLabels: any;
    hideNotes: boolean;
    moodOptions: any = {
        feelings: '',
        emotions: '',
        activities: '',
        places: '',
        people: '',
    };
    lang: any;
    moreOptions: any;
    category: any;
    moodcheck: Moodcheck;
    isLoaded: boolean;
    moodcheckLangKey: any;
    successPopup: any;
    user: User;
    constructor(
        public storage: StorageService,
        public api: ApiService,
        private log: LogService,
        private mcService: MoodcheckService,
        private mcOptions: MoodcheckOptionsService,
        private modalService: ModalService,
        // public dialogRef: MatDialogRef<MoodcheckModalComponent>,
        private router: Router,
        private translate: TranslateService,
        private utilityService: UtilityService,
        private userService: UserService,
        private eventService: EventManagerService
    ) {
        this.isLoaded = false;
        this.tabIndex = 0;
        this.activeTab = this.tabs[this.tabIndex];
        this.saveEnabled = false;
        this.showOptions = false;
        this.lang = this.storage.get('lang');

        // events.subscribe('mood-tab-slide:next', (tabId) => {
        //     this.superTabs.onToolbarTabSelect(tabId);
        // });

        this.mcService.updates.subscribe((isValid: boolean) => {
            this.saveEnabled = isValid;
        });

        this.demo = this.utilityService.isDemoMode();
        this.submitting = false;
        this.getAllMoodOptions(this.lang);
    }

    ngOnInit() {
        this.log.screen('MoodCheck');
        this.log.event(AnalyticEvent.event.moodCheckOpen);
        this.translate.stream('moodcheck').subscribe((res: any) => {
            this.popup = res.popups;
            this.moodcheckLangKey = res;
            this.successPopup = res.completePopup;
        });

        this.hideNotes = false;
        this.user = this.userService.getUser();
        if (
            this.user.primaryOrganization &&
            this.user.primaryOrganization.settings &&
            this.user.primaryOrganization.settings.hideNotes
        ) {
            this.hideNotes = this.user.primaryOrganization.settings.hideNotes;
            this.tabs.pop();
        }
    }

    isTabActive(tabId) {
        return tabId === this.activeTab;
    }

    isTabLast() {
        if (this.tabIndex === this.tabs.length - 1) {
            return true;
        } else {
            return false;
        }
    }

    onChangeTab(tabId) {
        this.tabIndex = _.indexOf(this.tabs, tabId);
        this.activeTab = tabId;
        this.moodcheck = this.mcService.getMoodcheck();
    }

    onNextTab() {
        //do nothing if we are on the last tab
        if (this.tabIndex === this.tabs.length - 1) {
            return;
        }

        this.tabIndex++;
        this.activeTab = this.tabs[this.tabIndex];
        this.moodcheck = this.mcService.getMoodcheck();
    }
    onPreviousTab() {
        this.tabIndex--;
        this.activeTab = this.tabs[this.tabIndex];
        this.moodcheck = this.mcService.getMoodcheck();
    }
    onDismiss() {
        this.log.event(AnalyticEvent.event.moodCheckCancel);
        this.mcService.reset();
        // this.dialogRef.close();
    }

    onMore(data: any) {
        this.log.debug(data);
        // const moreOptionsData = this.mcOptions.formatData(data.options);
        const moreOptionsData = data.options;
        this.moreOptions = moreOptionsData;
        this.category = data.category;
        this.showOptions = true;
        // let modal = this.modalService.showComponent(MoodcheckOptionsPage, {
        // 	category: this.category,
        // 	moreOptions: this.moreOptions,
        // 	 overlayPanelClass: 'customClass'
        // },
        // 	'app-l-mood',
        // 	true);

        // modal.beforeClosed().subscribe((responseData: any) => {
        // 	this.onCloseOptions();
        // });

        // setTimeout(function () {
        // 	jQuery('#moodcheck-modal input:visible').first().focus();
        // }, 500);
    }

    onCloseOptions() {
        this.getAllMoodOptions(this.lang);
        this.showOptions = false;

        setTimeout(function () {
            jQuery('#moodcheck-modal .featured:visible').focus();
        }, 500);
    }

    onSave() {
        if (!this.saveEnabled) {
            let checkMood: Moodcheck = this.mcService.getMoodcheck();
            if (checkMood.value === undefined) {
                let alert = this.modalService.showAlert(
                    this.popup.incomplete,
                    this.popup.dial
                );
                alert.afterClosed().subscribe(result => {
                    if (this.tabIndex === 0) {
                        this.eventService.emit('animateMoodSlider');
                    }
                    if (this.tabIndex === 1) {
                        if (checkMood.activity === '') {
                            this.eventService.emit('animateGridActivity');
                        } else {
                            this.tabIndex = 0;
                            this.onChangeTab('mood');
                            this.eventService.emit('animateMoodSlider');
                        }
                    }
                });
            } else {
                this.tabIndex = 1;
                this.eventService.emit('animateGridActivity');
                this.onChangeTab('activity');
            }
            return;
        } else {
            this.modalService
                .showConfirmation(
                    this.moodcheckLangKey.submit,
                    this.moodcheckLangKey.moodcheckSubmitMsg
                )
                .afterClosed()
                .subscribe(result => {
                    if (result) {
                        this.saveMoodCheckData();
                    }
                });
        }
    }

    saveMoodCheckData() {
        this.saveEnabled = false;
        let moodcheck: Moodcheck = this.mcService.getMoodcheck();

        //make call to server
        if (this.demo) {
            /*
             * If demo mode is detected, do not save the note.
             */
            moodcheck.notes = null;
        }
        this.submitting = true;
        this.api.post('moodcheck', moodcheck.forApi()).subscribe(
            (data: any) => {
                if (data) {
                    let user = this.userService.getUser();
                    user.moodcheckCount += 1;
                    this.userService.setUser(user);
                    user.moodcheckCount == 1 &&
                        this.mcService.moodcheckCountWatcherUpdate(user);
                }
                this.log.event(
                    AnalyticEvent.event.moodCheckCancel,
                    'MoodCheck',
                    { moodValue: moodcheck.value }
                );
                this.storage.setFlag('first-moodcheck');
                this.modalService.showAlert(
                    this.successPopup.thankYou,
                    this.successPopup.moodcheckSavedSuccessfully
                );
                this.mcService.reset();

                //Trigger a data refresh
                this.mcService.triggerRefresh();
                if (this.user.userType === 'user') {
                    this.router.navigateByUrl('/app');
                } else {
                    this.router.navigateByUrl(
                        '/professional/user-section/dashboard'
                    );
                }
                this.submitting = false;
            },
            (error: any) => {
                this.log.error('moodcheck_error');
                this.modalService.showAlert(this.popup.error, error.message);
                this.submitting = false;
            }
        );
    }
    onTabSelect(ev: any) {
        this.tabIndex = ev.index;
    }

    /**
     * Get all Mood Options
     */
    getAllMoodOptions(language: string) {
        this.mcOptions.initOptions(language).then(options => {
            this.moodOptions = options;
            this.mcOptions.refreshData();
            this.isLoaded = true;
        });
    }
}
