import {
    Component,
    OnInit,
    Inject,
    Input,
    Output,
    EventEmitter,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'app/lib/api.service';
import { LogService } from 'app/lib/log.service';
import { ModalService } from 'app/lib/modal.service';

import { Video } from '../../models/course';
import { VideoPlayerComponent } from '../video-player/video-player.component';
@Component({
    selector: 'course-video-card',
    templateUrl: 'course-video-card.component.html',
    styleUrls: ['./course-video-card.component.scss'],
})
export class CourseVideoCardComponent implements OnInit {
    @ViewChild('videoPlayer') videoPlayer: VideoPlayerComponent;
    @Input() video: any;
    @Input() callbackUrl: string;
    @Input() disableControls: boolean;
    @Input() autoplay: boolean = false;

    @Output() onVideoCompleted = new EventEmitter();
    @Output() onVideoEnded = new EventEmitter();
    @Output() onRate = new EventEmitter();
    @Output() onClickAttachment = new EventEmitter<any>();
    @Output() onPause = new EventEmitter<any>();
    @Output() progressData = new EventEmitter<any>();
    favouriteText: any;
    courseKey: string;
    courseId: string;
    selectedStarIndex: number = 0;
    rating: number = 0;
    constructor(
        private api: ApiService,
        private router: Router,
        private log: LogService,
        private modalService: ModalService,
        private translate: TranslateService,
        private activatedRoute: ActivatedRoute
    ) {
        this.translate.stream('favourite').subscribe((res: any) => {
            this.favouriteText = res;
        });
    }

    ngOnInit() {
        this.courseKey = this.activatedRoute.snapshot.paramMap.get('course');
        this.courseId = this.activatedRoute.snapshot.paramMap.get('courseId');
        this.setRating();
    }

    videoCompleted() {
        this.onVideoCompleted.emit();
    }

    videoEnded(video: any) {
        video.courseProgress['Status'] = 'completed';
        this.updateProgress({ current: 0, left: 0 }, video);
    }

    onRateItem() {
        this.onRate.emit();
    }

    attachmentClicked(attachment: any) {
        if (this.videoPlayer.isPlaying) {
            this.videoPlayer.api.pause();
        }
        if (attachment.fileFullPath) {
            window.open(attachment.fileFullPath, '_blank');
            this.api
                .post('analytics/seriesattachmentclick', {
                    attachment: attachment,
                })
                .subscribe(
                    (result: any) => {
                        this.onClickAttachment.emit(attachment);
                    },
                    (error: any) => {
                        this.log.error('Error logging link click');
                    }
                );
            //this.download(attachment.FullFilePath, title);
        } else {
            this.modalService.showAlert(
                'Error',
                'Could not load this link. Please contact info@welltrack.com'
            );
        }
    }

    onInternaLink(link: string) {
        this.router.navigate([link]);
    }

    /**
     * Set Favourite
     */
    setFavourite(videoDetails) {
        this.api
            .post('favorite/' + videoDetails.id, {
                type: 'course',
                status: videoDetails.isFavorite ? 0 : 1,
            })
            .subscribe(
                (result: any) => {
                    this.video.isFavorite = !videoDetails.isFavorite;
                    this.modalService.showAlert(
                        this.favouriteText.success,
                        result.message
                    );
                },
                (error: any) => {
                    this.modalService.showAlert(
                        this.favouriteText.error,
                        error.message
                    );
                }
            );
    }
    gotoVideo(videoId, autoplay) {
        this.router.navigateByUrl(
            'app/series-details/' +
                this.courseKey +
                '/' +
                videoId +
                '/' +
                autoplay
        );
    }
    setRating() {
        this.rating = this.video?.rating[0]?.Helpful || 0;
        this.selectedStarIndex = Math.floor(this.rating);
    }
    rateVideo(index: number) {
        this.selectedStarIndex = index;
        this.rating = index;
        if (this.video.videoRating == this.rating) {
            return;
        }
        // if (this.videoPlayer.isPlaying) {
        //   this.videoPlayer.api.pause();
        // }
        this.api
            .post('series/feedback', {
                helpful: this.rating,
                courseMenuId: this.video.id,
            })
            .subscribe(
                (result: any) => {
                    this.video.videoRating = this.rating;
                    this.modalService.showAlert(
                        'Success',
                        'Thank you for rating this video'
                    );
                },
                (error: any) => {
                    this.modalService.showAlert(
                        'Error',
                        'There was an error saving the information. Please try again later'
                    );
                }
            );
    }
    updateProgress(progressData: any, video: any) {
        let time = progressData.current;
        let status =
            Object.keys(video.courseProgress).length > 0
                ? video.courseProgress.Status
                : 'inprogress';
        if (status == 'inprogress' && progressData.left <= 10000) {
            status = 'completed';
            time = 0;
            video.courseProgress['Status'] = status;
        }
        let payload = {
            ID: video.id,
            Status: status,
            Time: time.toString(),
        };
        this.api.post('series/updateseriesprogress', payload).subscribe(
            (result: any) => {
                this.log.debug('Series progress saved successfully.');
                //this.log.debug(this.diaries);
            },
            (error: any) => {
                this.log.error('Error issuing callback. ');
            }
        );
    }

    changeCourseVideo(video: any) {
        let progressData: any = this.videoPlayer.api.time;
        this.progressData.emit(progressData);
        this.video = JSON.parse(JSON.stringify(video));
        this.videoPlayer.changeVideo(video);
    }
}
