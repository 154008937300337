import {
	Component,
	OnInit,
	ViewChild,
	ElementRef,
	ViewEncapsulation,
	AfterViewInit,
	Renderer2,
	Inject,
} from "@angular/core";
import {
	ActivityScheduler,
	ActivitySchedulerEvent,
} from "../../../../app/models/activity-scheduler";
import { TranslateService } from "@ngx-translate/core";
import { ApiService } from "../../../../app/lib/api.service";
import { LogService } from "../../../../app/lib/log.service";
import { ModalService } from "app/lib/modal.service";
import { StorageService } from "app/lib/storage.service";
import { UtilityService } from "app/lib/utility.service";
import { ActivatedRoute, Router } from "@angular/router";

import * as moment from "moment";
import { AnalyticEvent } from "app/lib/analytic-event";
import { User } from "app/models/user";
import { UserService } from "app/lib/user.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ActivitySelect } from "app/components/activity-select/activity-select";
import { ApiError } from "app/lib/api-error";
declare var jQuery: any;

@Component({
	selector: "activity-scheduler",
	templateUrl: "./activity-scheduler-details.html",
	styleUrls: ["./activity-scheduler-details.scss"],
})
export class ActivitySchedulerDetails implements OnInit, AfterViewInit {
	@ViewChild("timeFormat") timeFormat;
	date: any;
	currentStep = 0;
	// activityType = 0;
	active: boolean[];
	startFormatted = "";
	endFormatted = "";
	months = "";
	id = 0;
	events: any[] = [];
	activity: ActivityScheduler;
	errorPopups: any;
	confirmPopup: any;
	deletePopup: any;
	successPopups: any;
	editPopUps: any;
	viewMode: string;
	isDisabled = false;
	isLoaded = false;
	first = true;
	last = false;
	isFrench = false;
	backLink: string;
	backText: string;
	title: string;
	configListing: any;
	startDate = "";
	startTimeFormatted: string;
	min: Date;
	currentDate: Date;
	minTime: Date;
	currentTime;
	activityType: any = "";
	activities: any;
	selected: any;

	start = "";
	end = "";
	minDate = moment().format();
	isEndDateValid = true;
	maxDate: string = "";
	minEndDate: string = "";
	user: any;
	activityData: any;
	activityId = "";
	customPopUps: any;

	constructor(
		private modalService: ModalService,
		private translate: TranslateService,
		private api: ApiService,
		private log: LogService,
		private renderer: Renderer2,
		private storage: StorageService,
		private utilityService: UtilityService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private userService: UserService,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<ActivitySchedulerDetails>
	) {
		this.activity = new ActivityScheduler();
		this.currentDate = new Date();
		this.getConfig();
		this.minDateSet();
		this.maxDateSet();

		this.activityId = data.data?.activityId ? data.data?.activityId : "";
		if (!this.activityId) {
			this.viewMode = "create";
		} else {
			this.viewMode = "edit";
			this.getActivity();
		}
		this.start = moment().format();
		this.end = moment().add(30, "minutes").format();
	}

	ngOnInit() {
		this.min = new Date(
			this.currentDate.getFullYear(),
			this.currentDate.getMonth(),
			this.currentDate.getDate()
		);
		this.backLink = "/app/practice/activityscheduler";
		this.active = [false, false, false];
		this.translate.stream("as").subscribe((res: any) => {
			this.months = res.month;
			this.errorPopups = res.errorPopups;
			this.successPopups = res.successPopups;
			this.customPopUps = res.customPopUps;
			this.editPopUps = res.editPopUps;
			this.backText = res.back;
			this.title = res.title;
			this.confirmPopup = res.confirmPopup;
			this.deletePopup = res.deleteActivityPopup;
		});

		// this.activatedRoute.params.subscribe(params => {
		// 	if (!params['id']) {
		// 		this.viewMode = 'create';
		// 	} else {
		// 		this.id = params['id'];
		// 		this.viewMode = 'edit';
		// 		this.getActivity();
		// 	}
		// });
	}

	ngAfterViewInit() {}

	minDateSet() {
		this.minDate = moment().format();
		this.minEndDate = this.minDate;
	}
	maxDateSet() {
		this.maxDate = moment().add(3, "years").format("YYYY");
	}
	getConfig() {
		this.api.get("config/activityscheduler").subscribe((result: any) => {
			this.isLoaded = true;

			const configData = result.default;
			this.log.event(AnalyticEvent.event.activitySchedulerDetailsLoaded);
			if (configData !== null) {
				this.configListing = Object.values(configData);
			}
		});
	}

	getActivity() {
		this.api
			.get(`practice/activityscheduler/${this.activityId}`)
			.subscribe((res: any) => {
				this.activity = new ActivityScheduler(res.data);
				this.activityType = this.activity.type;
				this.changeStartDateTime(this.activity.start);
				this.changeEndDateTime(this.activity.end);
			});
	}

	selectType(type) {
		type = typeof type == "string" ? JSON.parse(type) : type;
		this.activityType = type;
		if (this.viewMode !== "readonly") {
			this.activity.type = type;
			this.activity.title = "";
			// this.next();
			this.getActivities();
		}
	}

	isType(type) {
		if (this.activity.type === JSON.parse(type)) {
			return true;
		} else {
			return false;
		}
	}

	onSchedule() {
		// if (!this.start || !this.startDate) {
		// 	this.modalService.showAlert(this.errorPopups.error, this.errorPopups.dateTime);
		// 	return;
		// }

		// if (this.utilityService.demoMode()) {
		// 	return;
		// }
		if (!this.activity.type) {
			this.modalService.showAlert(
				this.errorPopups.error,
				this.errorPopups.activityType
			);
			return;
		} else if (!this.activity.title) {
			this.modalService.showAlert(
				this.errorPopups.error,
				this.errorPopups.activity
			);
			return;
		} else {
			const startTime = moment.parseZone(this.start).format("h:mm a");
			const endTime = moment.parseZone(this.end).format("h:mm a");
			const popupBody = this.confirmPopup.body.replace(
				"AT on DT from T1 to T2",
				`<strong>${this.activity.title}</strong> on <strong>${moment
					.parseZone(this.start)
					.format(
						"DD MMM YYYY"
					)}</strong> from <strong>${startTime}</strong> to <strong>${endTime}</strong>`
			);

			this.modalService
				.showConfirmation(
					this.confirmPopup.title,
					popupBody,
					this.confirmPopup.ok,
					this.confirmPopup.cancel
				)
				.afterClosed()
				.subscribe((result) => {
					if (result) {
						this.saveActivity(false, "save");
					}
				});
		}
	}

	goBack() {
		this.router.navigate(["app/practice/activityscheduler"]);
	}

	activityChange(event) {
		if (this.viewMode !== "readonly") {
			this.activity.title = event.data.Value;
			// this.next();
		}
	}

	// next() {
	// 	// if (!this.startDate && this.currentStep === 2) {
	// 	// 	this.modalService.showAlert(this.errorPopups.error, this.errorPopups.date);
	// 	// 	return;
	// 	// }
	// 	// if (this.currentStep === 2) {
	// 	// 	this.formatTime();;
	// 	// }

	// }

	cancel() {
		this.dialogRef.close();
	}

	onDateChange(startDate) {
		const date = new Date();
		const startTime = moment
			.parseZone(startDate)
			.add(date.getHours(), "hours")
			.add(date.getMinutes(), "minutes")
			.format();
		this.currentTime = new Date(startTime);
	}
	getActivities() {
		// let activityData: any;
		// this.isLoaded = false;
		this.api.get("config/activityscheduler").subscribe((result: any) => {
			// this.isLoaded = true;
			let configListing = [];
			const configData = result.default;
			if (result.default !== null) {
				// const defaultListing = Object.values(result.default);
				for (const key of Object.keys(result.default)) {
					const defaultActivities = result.default[key].Activities;
					const customActivities = result.custom
						? result.custom[key] || []
						: [];

					defaultActivities.forEach(
						(activity) => (activity.isDefault = true)
					);
					customActivities.forEach(
						(activity) => (activity.isDefault = false)
					);

					result.default[key].Activities = [
						...defaultActivities,
						...customActivities,
					];
				}

				configListing = Object.values(result.default);
				this.activityData = configListing.find(
					(activity: any) =>
						JSON.parse(activity.Value) === this.activityType
				);
				this.activities = this.activityData.Activities;
				this.activities.forEach((element) => {
					if (element.Value === this.activity.title) {
						element.isSelected = true;
					} else {
						element.isSelected = false;
					}
				});
			}
		});
	}

	toggleOption(activity: any) {
		for (let i = 0; i < this.activities.length; i++) {
			if (activity.Value === this.activities[i].Value) {
				this.activities[i].isSelected = true;
			} else {
				this.activities[i].isSelected = false;
			}
		}
		activity.isSelected = true;
		this.selected = activity;
		if (this.viewMode !== "readonly") {
			this.activity.title = this.selected.Value;
			// this.next();
		}
		this.storage.set("selectedActivities", this.activities);
		// this.activity.emit({ 'data': this.selected });
		// this.sync();
	}
	changeStartDateTime(start) {
		// this.start = start;
		let startDate = moment.parseZone(start).format();
		this.activity.start = new Date(startDate);
		this.start = startDate;
		const date = moment(startDate);

		this.isEndDateValid = moment(this.end).diff(date) <= 0 ? false : true;
	}

	changeEndDateTime(end) {
		let endDate = moment.parseZone(end).format();
		this.activity.end = new Date(endDate);
		this.end = endDate;
		const date = moment(this.start);

		this.isEndDateValid = moment(this.end).diff(date) <= 0 ? false : true;
	}

	onAddCustomOption() {
		this.modalService
			.showComponent(
				ActivitySelect,
				{
					activityType: this.activityType,
					activityData: this.activityData,
				},
				"app-c-modal--cure"
			)
			.afterClosed()
			.subscribe((result) => {
				this.activity.title = result?.Value;
				this.getActivities();
			});
	}
	save() {
		this.modalService
			.showConfirmation(
				this.confirmPopup.title,
				this.confirmPopup.editActivityBody,
				this.confirmPopup.ok,
				this.confirmPopup.cancel
			)
			.afterClosed()
			.subscribe((result) => {
				if (result) {
					this.saveActivity(false, "edit");
				}
			});
	}

	saveActivity(forcedSave: boolean = false, action) {
		let startDate = new Date(this.start);
		let endDate = new Date(this.end);

		this.activity.start = startDate;
		this.activity.end = endDate;

		this.activity.status = "inprogress";
		let payload = this.activity.forApi();

		if (forcedSave) {
			payload["forcedsave"] = true;
		}
		this.api.post("practice/activityscheduler2", payload).subscribe(
			(data: any) => {
				this.activityData = new ActivityScheduler(data.data);
				if (action === "edit") {
					this.modalService.showAlert(
						this.editPopUps.success,
						this.editPopUps.body
					);
				} else {
					this.modalService.showAlert(
						this.successPopups.success,
						this.successPopups.body
					);
				}

				this.log.event(
					AnalyticEvent.event.activitySchedulerDetailsUpdated
				);
				this.dialogRef.close({
					action: "save",
					activityData: this.activityData,
				});
				this.api.get("users/me").subscribe((result: any) => {
					this.user = new User(result.data);
					this.userService.setUser(this.user);
				});
			},
			(error: ApiError) => {
				if (error.status === 409) {
					this.modalService
						.showConfirmation(
							this.confirmPopup.title,
							this.confirmPopup.overlapActivityTxt,
							this.confirmPopup.ok,
							this.confirmPopup.cancel
						)
						.afterClosed()
						.subscribe((result) => {
							if (result) {
								this.saveActivity(true, action);
							}
						});
				} else {
					this.modalService.showAlert(
						this.errorPopups.title,
						error.message
					);
				}
			}
		);
	}
	deleteActivity(id) {
		this.modalService
			.showConfirmation(
				this.deletePopup.deleteConfirmTitle,
				this.deletePopup.deleteConfirmBody,
				this.deletePopup.ok,
				this.deletePopup.cancel
			)
			.afterClosed()
			.subscribe((data) => {
				if (data) {
					this.api.delete("practice/" + id).subscribe(
						(result: any) => {
							this.dialogRef.close({
								action: "delete",
								activityData: this.activityData,
							});
							this.log.event(
								AnalyticEvent.event
									.activitySchedulerDetailsDeleted
							);
							this.modalService.showAlert(
								this.deletePopup.deleteSuccessTitle,
								this.deletePopup.deleteSuccessBody
							);
						},
						(error: any) => {
							this.log.error("Error deleting. ");
						}
					);
				}
			});
	}
}
