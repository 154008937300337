import {
    Component,
    OnInit,
    Input,
    Output,
    Inject,
    EventEmitter,
    ViewChild,
    TemplateRef,
} from '@angular/core';
import { Moodcheck, WT_CONSTANTS } from '../../models/moodcheck';
import {
    CalendarEvent,
    CalendarEventAction,
    CalendarEventTimesChangedEvent,
    CalendarMonthViewDay,
} from 'angular-calendar';
import * as moment from 'moment';
import { MoodcheckService } from '../../lib/moodcheck.service';
import { UserService } from '../../lib/user.service';
import { User } from '../../models/user';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../lib/api.service';
import { LogService } from '../../lib/log.service';
import { DateService } from '../../lib/url.service';
import { Subject } from 'rxjs';
import {
    MatDialog,
    MAT_DIALOG_DATA,
    DialogPosition,
    MatDialogRef,
} from '@angular/material/dialog';
import { UtilityService } from 'app/lib/utility.service';
import { CalendarView } from 'angular-calendar';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-monthly-moodcheck-view',
    templateUrl: './monthly-moodcheck-view.component.html',
    styleUrls: ['./monthly-moodcheck-view.component.scss'],
})
export class MonthlyMoodcheckViewComponent implements OnInit {
    @Input() moodcheckData: Moodcheck[];
    @ViewChild('moodcheckDailyModal') modalTemplate!: TemplateRef<any>;
    calendarData: any = [];
    hasSelectedDayData: boolean = false;
    isSelectedDayToday: boolean = false;
    user: User;
    dailyMoodcheckData: Moodcheck[];
    events$: Array<CalendarEvent<any>>;
    hasData: boolean = false;
    isLoading: boolean = true;
    dashboardLangKey: any;
    isCurrentMonth: boolean = true;
    viewDate: Date = new Date();
    moodcheckCategoryValue = WT_CONSTANTS.MOODCHECK_CATEGORY_CONFIG;
    dailyMoodcheckCategory = [
        { key: 'fantastic', value: 0, iconIndex: 10 },
        { key: 'veryHappy', value: 0, iconIndex: 9 },
        { key: 'happy', value: 0, iconIndex: 8 },
        { key: 'good', value: 0, iconIndex: 7 },
        { key: 'fine', value: 0, iconIndex: 6 },
        { key: 'ok', value: 0, iconIndex: 5 },
        { key: 'notGreat', value: 0, iconIndex: 4 },
        { key: 'bad', value: 0, iconIndex: 3 },
        { key: 'veryBad', value: 0, iconIndex: 2 },
        { key: 'awful', value: 0, iconIndex: 1 },
    ];

    selectedDate: any = moment.parseZone().format();
    today: any = moment.parseZone().format();
    currentMonth: any = moment().format('MM');
    modalDailySelectedDate: any = moment.parseZone().format();
    showLegends: boolean = true;

    view: CalendarView = CalendarView.Month;
    CalendarView = CalendarView;
    events: CalendarEvent[] = [];
    refreshCalendar: Subject<void> = new Subject();
    paramsSub: any;
    id: number;

    constructor(
        private mcService: MoodcheckService,
        private api: ApiService,
        private userService: UserService,
        private activatedRoute: ActivatedRoute,
        private translate: TranslateService,
        private log: LogService,
        private dateservice: DateService,
        public dialog: MatDialog
    ) {}
    private dialogRef!: MatDialogRef<any, any>;
    ngOnInit(): void {
        this.user = this.userService.getUser();
    }
    filterMonthlyMoodcheckData(data: any, currentDate?: any) {
        this.moodcheckData = data;
        this.selectedDate = currentDate;
        const groupedDateArray = this.groupMoodcheckByDates();
        this.generateDate(groupedDateArray);
    }

    groupMoodcheckByDates() {
        const groupedArray = this.moodcheckData.reduce((result, current) => {
            const date = moment(current.created).format('YYYY-MM-DD');
            if (!result[date]) {
                result[date] = [];
            }
            result[date].push(current);

            return result;
        }, {});

        return groupedArray;
    }

    generateDate(groupedDateArray) {
        let calendarData: any = [];
        for (const [key, value] of Object.entries(groupedDateArray)) {
            let currentDateData = {
                mood: '',
                categorizeMoodcheckData: {},
                totalDataCount: 0,
                start: UtilityService.convertToDate(
                    moment(key).format('YYYY-MM-DD')
                ),
                startDate: UtilityService.convertToDate(
                    moment(key).format('YYYY-MM-DD')
                ),
            };
            const totalDataCount = groupedDateArray[key].length;
            if (totalDataCount > 0) {
                let categorizeMoodcheckData: any[] =
                    this.mcService.categorizeMoodcheck(groupedDateArray[key]);
                let totalValue: number = 0;
                for (const property in categorizeMoodcheckData) {
                    let dataCount =
                        categorizeMoodcheckData[property].data.length;
                    if (totalDataCount > 0) {
                        totalValue +=
                            categorizeMoodcheckData[property].value * dataCount;
                    }
                }
                const value = Math.round(totalValue / totalDataCount);
                currentDateData.mood = this.moodcheckCategoryValue[value];
                currentDateData.categorizeMoodcheckData =
                    categorizeMoodcheckData;
                currentDateData.totalDataCount = totalDataCount;
                calendarData.push(currentDateData);
            }
        }
        this.calendarData = [...calendarData];
    }

    onClickNextBtn() {
        let date = moment(this.selectedDate).add(1, 'month').format();
        this.checkMonth(date);
        let passdate: Date = new Date(date);
        this.dateservice.setSelectedDate(passdate);
    }

    onClickPrevBtn() {
        let date = moment(this.selectedDate).add(-1, 'month').format();
        this.checkMonth(date);
        let passdate: Date = new Date(date);
        this.dateservice.setSelectedDate(passdate);
    }

    checkMonth(date: any) {
        var month = moment(date).format('MM');
        this.isCurrentMonth = this.currentMonth == month ? true : false;
        if (this.currentMonth == month) {
            this.isCurrentMonth = true;
            this.viewDate = UtilityService.convertToDate(
                moment().format('YYYY-MM-DD')
            );
        } else {
            this.isCurrentMonth = false;
            this.viewDate = UtilityService.convertToDate(
                moment(date).format('YYYY-MM-DD')
            );
        }
        this.getMoodcheckData(date);
    }

    setCalendarDate() {
        this.checkMonth(this.selectedDate);
    }

    getMoodcheckData(date) {
        if (this.user.userType != 'user') {
            this.paramsSub = this.activatedRoute.params.subscribe(params => {
                const tempId = parseInt(params['id'], 10);
                if (tempId) {
                    this.id = tempId;
                } else {
                    this.id = this.user.id;
                }
            });
        } else {
            this.id = this.user.id;
        }
        this.isLoading = true;
        this.hasData = false;
        let startDate: any = moment(date)
            .add(-1, 'months')
            .startOf('month')
            .format('YYYY-MM-DD');
        let endDate: any = moment(date)
            .add(1, 'months')
            .endOf('month')
            .format('YYYY-MM-DD');
        this.mcService.getMoodcheckData(startDate, endDate, this.id).then(
            result => {
                this.filterMonthlyMoodcheckData(result, date);
            },
            error => {
                this.filterMonthlyMoodcheckData([], date);
            }
        );
    }

    showMoodcheckDailyData(data: any, isToday: boolean) {
        this.isSelectedDayToday = isToday;
        if (data.events.length) {
            for (const property in data.events[0]['categorizeMoodcheckData']) {
                let dailyMoodcheckData: any =
                    data.events[0]['categorizeMoodcheckData'][property];
                let index =
                    11 -
                    data.events[0]['categorizeMoodcheckData'][property].value;
                this.dailyMoodcheckCategory[index - 1].value =
                    dailyMoodcheckData.data.length
                        ? (dailyMoodcheckData.data.length /
                              data.events[0]['totalDataCount']) *
                          100
                        : 0;
            }
            this.hasSelectedDayData = true;
        } else {
            this.hasSelectedDayData = false;
            this.dailyMoodcheckCategory.forEach(item => {
                item.value = 0;
            });
        }
        this.modalDailySelectedDate = data.date;
        const dialogRef = this.dialog.open(this.modalTemplate, {
            width: '480px',
            maxWidth: '100%',
            panelClass: ['app-l-modal__moods-days', 'app-c-modal--cure'],
            data: {},
        });

        dialogRef.afterClosed().subscribe(result => {});
    }
}
