import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "../../lib/api.service";
import { StorageService } from "../../lib/storage.service";
import { LogService } from "../../lib/log.service";
import { ModalService } from "../../lib/modal.service";
import { Router } from "@angular/router";
import { User } from "../../models/user";
import { Moodcheck } from "../../models/moodcheck";
import { Activity } from "../../models/activity";
import { UserService } from "../../lib/user.service";
import { ThoughtDiary } from "../../models/thought-diary";
import { TranslateService } from "@ngx-translate/core";

import * as _ from "lodash";
import { AnalyticEvent } from "app/lib/analytic-event";
import { MatDatepicker } from "@angular/material/datepicker";
import * as moment from "moment";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";

export const MY_FORMATS = {
	parse: {
	  dateInput: 'LL',
	},
	display: {
	  dateInput: 'MMMM YYYY',
	  monthYearLabel: 'MMMM YYYY',
	},
  };
@Component({
	selector: "thought-diary-listing",
	templateUrl: "thoughtdiary.component.html",
	styleUrls: ["./thoughtdiary.component.scss"],
	providers: [
		{
		  provide: DateAdapter,
		  useClass: MomentDateAdapter,
		  deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
		},
	
		{provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
	  ],
})
export class ThoughtDiaryListingComponent implements OnInit {
	diaries: Array<ThoughtDiary> = [];
	public filteredData: Array<ThoughtDiary> = [];
	questionIndex: number = 0;
	isLoaded: boolean;
	backLink: string;
	title: string;
	back: string;
	currentUser: User;
	@Input() user: User;
	@Input() queryParams: any;
	@Input() showIncomplete: boolean = true;
	@ViewChild("dp") datePicker: MatDatepicker<Date>;
	dateFilter: string = "";
	maxDate: Date;
	id: number;
	paramsSub: any;
	showCreateBtn: boolean = true;

	constructor(
		private api: ApiService,
		private storage: StorageService,
		private router: Router,
		private log: LogService,
		private userService: UserService,
		private modalService: ModalService,
		private translate: TranslateService,
		private activatedRoute: ActivatedRoute,
	) {
		this.currentUser = this.userService.getUser();
		this.isLoaded = false;
		this.backLink = "/app/practice";
		this.translate.stream("thoughtDiary").subscribe((res: any) => {
			this.title = res.title;
			this.back = res.back;
		});
		this.maxDate = new Date();
	}

	ngOnInit() {
		if(this.currentUser.userType !== "user"){
			this.paramsSub = this.activatedRoute.params.subscribe((params) => {
				this.id = parseInt(params["id"], 10);
			});
			if(this.id){
				this.showCreateBtn = false;
			} else{
				this.showCreateBtn = true;
			}
		}
		this.dateFilter = moment().format();
		this.refreshContent(
			moment().format("MM"),
      		moment().format("YYYY")
		);
	}

	refreshContent(month: string, year: string) {
		if (!this.user) {
			this.user = this.currentUser;
		}
		const userId = this.currentUser.userType === 'user'  || (this.currentUser.userType != 'user' && this.showCreateBtn)  ? this.user.id : this.id;
		this.api
			.get("theory/thoughtdiary/listing", {
				UserID: userId,
				month: month,
				year: year,
			})
			.subscribe(
				(result: any) => {
					this.diaries = ThoughtDiary.initializeArray(result.data);
					this.log.event(
						AnalyticEvent.event.thoughtDairyDetailsLoaded
					);
					this.filterThoughtData();
				},
				(error: any) => {
					this.log.error(
						"Error getting thought diaries. " + error.message
					);
					this.isLoaded = true;
				},
				() => {
					this.isLoaded = true;
				}
			);
	}

	delete(id) {
		this.modalService
			.showConfirmation(
				"Delete",
				"Are you sure you want to delete your thought diary entry?"
			)
			.afterClosed()
			.subscribe((result) => {
				if (result) {
					this.api.delete("practice/" + id).subscribe(
						(result: any) => {
							let index = _.findIndex(this.diaries, { id: id });
							this.diaries.splice(index, 1);
						},
						(error: any) => {
							this.log.error("Error deleting. ");
						}
					);
				}
			});
	}
	openDatePicker() {
		this.datePicker.open();
	}
	getThinkingStyles(diary) {
		const selectedStyle = diary.entries[0]?.thinkingStyles.filter(
			(style) => style.isSelected
		);
		return selectedStyle;
	}
	dateFilterChange($event, dp) {
		let month = moment.parseZone($event._i).format("MM");
		let year = moment.parseZone($event._i).format("YYYY");
		dp.close();
		this.dateFilter=moment(new Date(parseInt(year), parseInt(month)-1, 1).getTime()).format();
		this.refreshContent(month, year);
	  }
	filterThoughtData() {
		this.filteredData = _.filter(this.diaries, {
		  status: this.questionIndex == 0 ? "complete" : "inprogress",
		});
		
	}
	onTabChanged(event) {
		this.questionIndex = event.index;
    	this.filterThoughtData();
	}
	onId(id: number) {
		this.router.navigate(["/app/practice/thoughtdiary/" + id]);
	}
	newThought() {
		if(this.queryParams.previousUrl === "result"){
			const queryParamsData = { 
				prevpage: this.queryParams.previousUrl, 
				type: this.queryParams.type, 
				id: String(this.queryParams.id) 
			};	
			this.router.navigate(['/app/practice/thoughtdiary/new'], { queryParams: queryParamsData });
		}else{
			this.router.navigate(["/app/practice/thoughtdiary/new"]);
		}
	}
}
