import { Component, ViewChild, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../lib/api.service';
import { LogService } from '../../../lib/log.service';
import { Router } from '@angular/router';
import { User } from '../../../models/user';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ModalService } from '../../../lib/modal.service';
import { Assessment } from '../../../models/assessment';
import * as Chart from 'chart.js';
import { ChartData } from 'chart.js';
import { Organization } from 'app/models/organization';
import { ProfessionalService } from '../professional.service';
import { OverallViewMoodcheckComponent } from 'app/components/overall-view-moodcheck/overall-view-moodcheck.component';
import { UtilityService } from 'app/lib/utility.service';
import { AssignEventList } from 'app/models/event';
import { Subscription } from 'rxjs';
import { AssessmentService } from 'app/lib/assessment.service';
@Component({
    selector: 'app-user-details-overview',
    templateUrl: './user-details-overview.component.html',
    styleUrls: ['./user-details-overview.component.scss'],
})
export class UserDetailsOverviewComponent implements OnInit {
    isloaded: boolean = false;
    user: User;
    userViewed: User;
    fullName: string;
    loginOn: string = '';
    id: number;
    paramsSub: any;
    activity: Array<Object> = [];
    moodchecks: Array<Object> = [];
    moodcheckimage: string = '';
    avgmoodcheck: number = 0;
    error: any;
    showMore: boolean = false;
    appointment: boolean = false;
    alerts: number = 0;
    avg: number = 0;
    emotion: string = '';
    isAdmin = false;
    userType = '';
    organization: Organization;
    popup: any;
    assessments: Assessment[];
    showAssessment: boolean = false;
    isOrgadmin: boolean = false;
    showAssigned: boolean = true;
    showCompleted: boolean = false;
    @ViewChild('dasAssessmentChart') canvasRef;
    dasAssessmentChart: Chart;
    seriesList = [];
    userActionPlansData = [];
    chartData: ChartData;
    chartOptions;
    isLoaded: boolean = false;
    @Input() userActionPlans: any;
    @Input() countData: any = {};
    @Input() demographicData: any;
    @Input() userDetail: any;
    @ViewChild('dashboardMoodchecOverallViewComp')
    dashboardMoodchecOverallViewComp: OverallViewMoodcheckComponent;
    showEventAssignModal: boolean = false;
    dateRange = [
        {
            title: 'All',
            value: 0,
        },
        {
            title: 'Last 30 days',
            value: 30,
        },
        {
            title: 'Last 60 days',
            value: 60,
        },
        {
            title: 'Last 90 days',
            value: 90,
        },
    ];
    assignmentTypes = [
        {
            title: 'clientList.overview.all',
            key: 'all',
        },
        {
            title: 'clientList.overview.assessment',
            key: 'assessment',
        },
        {
            title: 'clientList.overview.moodCheck',
            key: 'moodcheck',
        },
        {
            title: 'clientList.overview.series',
            key: 'series',
        },
        {
            title: 'clientList.overview.tools',
            key: 'tools',
        },
    ];
    dateRangeSelected: any;
    nameInitial: any;
    currentDate: Date;
    rangeDate: Date;
    assignmentList: any;
    assignedListLoaded: boolean = false;
    resourceData: Array<object> = [];
    selectedType: any = 'all';
    assigned = [];
    completed = [];
    latestDassDataLoaded: boolean = false;
    seriesLoaded: boolean = false;
    resourceClicksLoaded: boolean = false;
    countDataLoaded: boolean = true;
    latestDassData: any;
    dateFilterSubscriber: Subscription;
    assessmentMostRecent: any;
    assessmentInitial: any;
    typeAssessment: string = 'mentalwellbeing';
    myCurrentGoal: any;
    results: any = null;
    mentalWellBeingChange: any | null = null;
    mentalWellBeingDifference: number | null = null;
    constructor(
        private api: ApiService,
        private router: Router,
        private log: LogService,
        private activatedRoute: ActivatedRoute,
        private translate: TranslateService,
        private modalService: ModalService,
        private professionalService: ProfessionalService,
        private utilityService: UtilityService,
        private assessmentService: AssessmentService
    ) {
        this.currentDate = new Date();
        this.rangeDate = new Date(
            new Date().setDate(this.currentDate.getDate() - 30)
        );
        this.setLanguageData();
    }

    ngOnInit() {
        this.paramsSub = this.activatedRoute.params.subscribe(params => {
            this.id = parseInt(params['id'], 10);
            this.translate.stream('lang').subscribe((res: any) => {
                this.getAssignment();
                setTimeout(() => {
                    this.getUserActionPlans(this.userActionPlans);
                }, 100);
            });
            if (this.userDetail.primaryOrganization) {
                this.organization = this.userDetail.primaryOrganization;
                this.getSeries();
                // this.getResourseData();
                this.getFirstLastAssessment();

                this.translate.onLangChange.subscribe(
                    (event: LangChangeEvent) => {
                        this.getResourseData();
                    }
                );
            }
            this.isLoaded = true;
        });
    }

    ngAfterViewInit() {
        this.dateFilterSubscriber =
            this.utilityService.userDetailsPageDayFilter.subscribe(data => {
                if (data != this.dateRangeSelected) {
                    this.dateRangeSelected = data;
                    this.onRangeChange(false);
                }
            });
    }

    setLanguageData() {
        this.translate.stream('error').subscribe((res: any) => {
            this.error = res.title;
        });

        this.translate.stream('userDetails.popup').subscribe((res: any) => {
            this.popup = res;
        });
    }

    onCloseOptions(event: any) {
        this.showEventAssignModal = false;
        event && this.getAssignment();
        setTimeout(function () {
            jQuery('#moodcheck-modal .featured:visible').focus();
        }, 500);
    }

    getSeries() {
        let payload = {
            lastviewed: 1,
        };
        if (this.dateRangeSelected != 0) {
            payload['From'] = this.rangeDate.toISOString().substring(0, 10);
            payload['To'] = this.currentDate.toISOString().substring(0, 10);
        }
        this.api
            .get(
                `syncheduser/series/${this.organization.id}/${this.id}`,
                payload
            )
            .subscribe(
                (result: any) => {
                    this.seriesLoaded = true;
                    let seriesData = [];
                    let courseList = result.data.filter(
                        series => series[0].courseprogress !== 'notstarted'
                    );
                    courseList.forEach(course => {
                        seriesData.push(course[0]);
                    });
                    this.sortSeries(seriesData);
                },
                error => {}
            );
    }

    sortSeries(seriesData) {
        seriesData.sort(function (a, b) {
            return (
                new Date(b.lastviewed).getTime() -
                new Date(a.lastviewed).getTime()
            );
        });
        this.seriesList = seriesData.slice(0, 3);
    }
    getUserActionPlans(actionPlan) {
        if (actionPlan.length != 0) {
            this.userActionPlansData = actionPlan;
        }
    }

    onTabChanged($event) {
        let tabSelected = $event.tab.textLabel;
        if (tabSelected == 'Assigned') {
            this.showAssigned = true;
        } else {
            this.showCompleted = true;
        }
    }

    getInitials(name): string {
        return this.utilityService.getInitials(name);
    }

    onCancelShare(userId) {
        let confirmResult;
        this.modalService
            .showConfirmation('Cancel', this.popup.cancelSharing)
            .afterClosed()
            .subscribe(result => {
                if (result) {
                    this.api.delete('counselors/' + userId).subscribe(
                        (result: any) => {
                            this.modalService.showAlert(
                                this.popup.successtitle,
                                this.popup.success
                            );
                            this.router.navigate(['professional/users']);
                        },
                        (error: any) => {
                            this.modalService.showAlert(
                                this.popup.errortitle,
                                this.popup.error
                            );
                        }
                    );
                }
            });
    }
    getFirstLastAssessment() {
        this.api.get('syncheduser/getlastfirstassessment/' + this.id).subscribe(
            (result: any) => {
                this.assessmentInitial = result.first[0];
                this.assessmentMostRecent = result.last[0];
                if (
                    this.typeAssessment == 'mentalwellbeing' &&
                    this.assessmentMostRecent
                ) {
                    this.setMyCurrentGoal(this.assessmentMostRecent);
                }
                this.getDassPercentage();
                this.latestDassDataLoaded = true;
                this.latestDassData = result.last[0];
            },
            (error: any) => {
                this.log.error(
                    'Error getting assessment count. ' + error.message
                );
                this.isLoaded = true;
            }
        );
    }
    getLevel(type, score) {
        if (score === null || score === undefined) {
            return '';
        }
        let value = this.assessmentService.getAssessmentScoreLevelForChart(
            type,
            score
        );
        const translationKey =
            'dynamicAssessment.' +
            this.typeAssessment +
            '.resultCategory.' +
            value;
        switch (this.typeAssessment) {
            case 'dass':
            case 'depression':
            case 'DASS':
            case 'PHQ-9':
                return Number(value) >= 0 && Number(value) <= 4
                    ? this.translate.instant(translationKey)
                    : ' ';
            case 'resilience':
            case 'alcoholuse':
            case 'BRCS':
            case 'mentalwellbeing':
            case 'Mental WellBeing':
            case 'AUDIT':
                return Number(value) >= 0 && Number(value) <= 2
                    ? this.translate.instant(translationKey)
                    : ' ';
            case 'anxiety':
            case 'GAD-7':
                return Number(value) >= 0 && Number(value) <= 3
                    ? this.translate.instant(translationKey)
                    : ' ';
        }
    }
    setMyCurrentGoal(recentAssessment) {
        this.myCurrentGoal = recentAssessment.Goal || '';
        this.assessmentService.setMWACurrentGoal(this.myCurrentGoal);
    }
    getDassPercentage() {
        this.results = this.utilityService.getPercentageChanges(
            this.assessmentInitial,
            this.assessmentMostRecent
        );
        if (this.results) {
            this.mentalWellBeingChange = this.results.mentalWellBeingChange;
            this.mentalWellBeingDifference =
                this.results.mentalWellBeingDifference;
        }
    }
    onRangeChange(setFilter: boolean) {
        this.rangeDate = new Date(
            new Date().setDate(
                this.currentDate.getDate() - this.dateRangeSelected
            )
        );
        setFilter &&
            this.utilityService.setUserDetailsPageDayFilterSubject(
                this.dateRangeSelected
            );
        this.updateCount();
        this.updateMoodcheck();
        this.getResourseData();
    }

    updateCount() {
        this.countDataLoaded = false;
        this.professionalService
            .getCounts(
                this.id,
                '1-3-13-21',
                this.rangeDate,
                this.currentDate,
                this.dateRangeSelected == 0
            )
            .subscribe(result => {
                this.countData = result;
                this.countDataLoaded = true;
            });
    }
    updateMoodcheck() {
        setTimeout(() => {
            this.dashboardMoodchecOverallViewComp.getMoodcheckData(
                this.dateRangeSelected
            );
        }, 100);
    }
    getAssignment() {
        this.assignedListLoaded = false;
        let payload = {
            Limit: 10,
        };
        if (this.selectedType != 'all') {
            payload['type'] = this.selectedType;
        }
        this.api.get('syncheduser/assignments/' + this.id, payload).subscribe(
            (result: any) => {
                this.assignmentList = result;
                this.assigned = AssignEventList.initializeArray(
                    this.assignmentList.assigned
                );
                this.completed = AssignEventList.initializeArray(
                    this.assignmentList.completed
                );
                // this.changeType(this.selectedType);
                this.log.debug('assignment fetch');
                this.assignedListLoaded = true;
            },
            (error: any) => {
                this.log.error('Error fetching activity. ' + error.message);
                this.assignedListLoaded = true;
            }
        );
    }

    deleteAssign(aId) {
        this.modalService
            .showConfirmation(
                'Delete',
                'Are you sure you want to delete this assignment?'
            )
            .afterClosed()
            .subscribe(result => {
                if (result) {
                    this.api
                        .delete(
                            `syncheduser/assignment/delete/` +
                                aId +
                                `?SynchedUserID=` +
                                this.id
                        )
                        .subscribe(
                            (result: any) => {
                                this.getAssignment();
                                this.isLoaded = true;
                                this.assignedListLoaded = true;
                                this.modalService.showAlert(
                                    'Success',
                                    'Assignment deleted successfully'
                                );
                            },
                            (error: any) => {
                                this.log.error(
                                    'Error getting delete.' + error.message
                                );
                                this.isLoaded = true;
                            }
                        );
                }
            });
    }

    showAssignModal(val) {
        this.showEventAssignModal = val;
    }
    getResourseData() {
        this.resourceClicksLoaded = false;
        let payload = {
            OrgId: this.organization.id,
        };
        if (this.dateRangeSelected != 0) {
            let currentDate = this.currentDate.toISOString().substring(0, 10);
            let rangeDate = this.rangeDate.toISOString().substring(0, 10);
            payload['From'] = rangeDate;
            payload['To'] = currentDate;
        }
        this.api
            .get(`syncheduser/resourceclicks/${this.id}`, payload)
            .subscribe(
                (result: any) => {
                    if (result) {
                        this.resourceData = result;
                        this.resourceClicksLoaded = true;
                    }
                },
                error => {
                    this.resourceClicksLoaded = true;
                    this.log.error(
                        'Error getting action plan. ' + error.message
                    );
                }
            );
    }
    goToActionPlan(plan?: any) {
        let url = '/app/practice/action-plan/create';
        this.router.navigate([url], { queryParams: { userId: this.id } });
    }

    ngOnDestroy() {
        if (this.dateFilterSubscriber) {
            this.dateFilterSubscriber.unsubscribe();
        }
    }
}
