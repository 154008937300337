import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject, Subject } from 'rxjs'
import { ApiService } from './api.service';
import { LogService } from './log.service';
import { ModalService } from './modal.service';
import { StorageService } from './storage.service';
import { ConfigService } from './config.service';
import { UserService } from './user.service';
@Injectable()
export class MessagingService {
    currentMessage =  new Subject();
    fcmToken =  new Subject();
     constructor(private angularFireMessaging: AngularFireMessaging,
        private storage: StorageService,
        private modalService: ModalService,
        private api: ApiService,
        private log: LogService,
        private configService: ConfigService,
        private userService: UserService) {
        this.angularFireMessaging.messages.subscribe(
            (_messaging: AngularFireMessaging) => {
                this.currentMessage.next(_messaging);
            }
          )
    
    }

    /**
     * Firebase request permission
     */
    requestPermission() {
        this.angularFireMessaging.requestPermission.subscribe(permission=>{
                this.angularFireMessaging.requestToken.subscribe(
                (token) => {
                    setTimeout(() => {
                        this.storage.set('fcmToken',token,true);
                        this.fcmToken.next(token);
                        var lastToken = this.storage.get('lastDeviceToken');
                        if (lastToken  !== token) {
                            this.deregister(lastToken);
                        }
                        this.register(token);
                        this.receiveMessage();
                        
                    }, 1000);
                },
                (err) => {
                   this.modalService.showAlert('Error', 'Unable to get permission to notify' + err);
                }
            );
        })
       
    }

    register(deviceToken?) {
        this.api.post('devices', {
            Token: deviceToken,
            ClientID: this.configService.apiClientId(),
        }).subscribe(
            (data: any) => {
                this.log.trace('Device registered: ' + deviceToken);
                this.userService.reloadUser();
                this.storage.set('lastDeviceToken', deviceToken);
            },
            (error: any) => {
                this.log.critical('Error registering device: ' + deviceToken);
                this.log.critical(JSON.stringify(error.message));
                this.log.error('Error registering device: ' + deviceToken);
            }
        );
    }

    deregister(deviceToken?) {
        deviceToken = deviceToken || this.storage.get('lastDeviceToken');

        if (deviceToken) {
            this.api.delete('devices/' + deviceToken).subscribe(
                (data: any) => {
                    this.log.trace('Device deregistered: ' + deviceToken);
                    this.storage.set('lastDeviceToken', null);
                },
                (error: any) => {
                    this.log.critical('Error deregistering device: ', error.message);
                    this.log.critical(deviceToken);
                    this.log.error('Error deregistering device: ' + deviceToken);
                }
            );
        }
    }

    /**
     * Firebase receive message
     */
    receiveMessage() {
        this.angularFireMessaging.messages.subscribe(
            (payload) => { 
                this.currentMessage.next(payload);
            })

            
    }

    /**
     * Firebase Logout
     */
     deleteFcmToken() {
        const token:any = this.storage.get('fcmToken');
        this.angularFireMessaging.deleteToken(token).subscribe(
            (payload) => { 
                
            })


            
    }
}


