import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PublicPortalTemplate } from './pages/public-portal/public-portal.component';
import { PublicPortalFullTemplate } from './pages/public-portal-full/public-portal-full';
import { DashboardPage } from './pages/dashboard/dashboard.component';
import { AuthGuard } from './guards/auth.guard';
import { AssociatedGuard } from './guards/associated.guard';
import { OrgConfigGuard } from './guards/orgConfig.guard';
import { AclGuard } from './guards/acl.guard';
import { environment } from '../environments/environment';
import { OrganizationEditPage } from './pages/organization-edit/organization-edit.component';
import { OrganizationViewPage } from './pages/organization-view/organization-view.component';
import { UserListingAdminPage } from './pages/user-listing-admin/user-listing-admin.component';
import { SettingsPage } from './pages/settings/settings';
import { OrgadminSettingComponent } from './pages/orgadmin-setting/orgadmin-setting.component';
import { EmergencyContactPage } from './pages/emergency-contact/emergency-contact';
import { ResourcesPage } from './pages/resources/resources';
import { ResourcesDetailComponent } from './pages/resources-detail/resources-detail.component';
import { CoreContributorsPage } from './pages/corecontributors/corecontributors';
import { CounselorSyncPage } from './pages/counselor-sync/counselor-sync';
import { AssessmentsPage } from './pages/assessments/assessments';
import { DasAssessmentPage } from './pages/das-assessment/das-assessment';
import { MentalWellbeingAssessmentComponent } from './pages/mental-wellbeing-assessment/mental-wellbeing-assessment.component';
import { CommunityComponent } from './pages/community/community.component';
import { TheoryComponent } from './pages/theory/theory.component';
import { CoursesComponent } from './pages/courses/courses.component';
import { CoursesListingComponent } from './pages/courses-listing/courses-listing.component';
import { AddictionListingPage } from './modules/practice/addiction-listing/addiction-listing';
import { DasAssessmentListingPage } from './pages/das-assessment-listing/das-assessment-listing.component';
import { DasAssessmentListingComponent } from './components/das-assessment-listing/das-assessment-listing.component';
import { AssessmentAlert } from './components/assessment-alert/assessment-alert';
import { ResilienceListingPage } from './pages/resilience-listing/resilience-listing';
import { ChallengeListingPage } from './pages/challenges/challenge-listing/challenge-listing.component';
import { UserProfileDetailsComponent } from './pages/user-profile-details/user-profile-details.component';
import { OrganizationListingPage } from './pages/organization-listing/organization-listing.component';
import { ActivityInfoPage } from './pages/activity-info/activity-info.component';
import { ChallengeListingAdminPage } from 'app/pages/challenges/challenge-listing-admin/challenge-listing-admin.component';
import { CalendarPage } from 'app/pages/calendar/calendar.component';
import { ReportsPage } from 'app/pages/reports/reports';
import { VideoShareComponent } from 'app/pages/video-share/video-share.component';
import { OnboardingPage } from 'app/pages/onboarding/onboarding';
import { DemographicPage } from 'app/pages/demographic/demographic';
import { ManageResourcesPage } from 'app/pages/manage-resources/manage-resources';
import { ResiliencePage } from './pages/resilience/resilience';
import { AssessmentsListingPage } from './pages/assessments-listing/assessments-listing.component';
import { ResourceImportPage } from './pages/resources-import/resources-import.component';
import { AdminCourseListingPage } from './pages/admin-course-listing/admin-course-listing.component';
import { PodcastsPage } from './pages/podcasts/podcasts';
import { AuthSideMenuTemplate } from './pages/auth-side-menu/auth-side-menu.component';
import { SchedulePushNotificationPage } from './pages/schedule-push-notifications/schedule-push-notifications.component';
import { UserDatedEventsListingPage } from './pages/user-dated-events-listing/user-dated-events-listing.component';
import { NotFoundPage } from './pages/not-found/not-found';
import { AdditionalResourcesPage } from './pages/additional-resources/additional-resources';
import { AdditionalResourceListingPage } from './pages/additional-resource-listing/additional-resource-listing.component';
import { ResourceView } from './pages/resource-view/resource-view.component';
import { FavouriteListingComponent } from './components/favourite-listing/favourite-listing.component';
import { ConfigListingComponent } from './components/config-listing/config-listing.component';
import { InternalUserListingComponent } from './pages/internal-user-listing/internal-user-listing.component';
import { AuditListingComponent } from './components/audit-listing/audit-listing.component';
import { AlertsListingComponent } from './components/alerts-listing/alerts-listing.component';
import { UserDetailsPage } from './modules/professional/user-details/user-details.component';
import { NpsSurveyComponent } from './components/nps-survey/nps-survey.component';
import { NpsSurveyListingComponent } from './components/nps-survey-listing/nps-survey-listing.component';
import { SystemPermissionComponent } from './components/system-permission/system-permission.component';
import { AssessmentViewComponent } from './pages/assessment-view/assessment-view.component';
import { MoodcheckModalComponent } from './components/moodcheck-modal/moodcheck-modal.component';
import { DynamicformPageComponent } from './components/dynamicform-page/dynamicform-page.component';
import { DynamicAssessmentComponent } from './components/dynamic-assessment/dynamic-assessment.component';
import { CourseVideoComponent } from './pages/course-video/course-video.component';
import { UserOnboardingComponent } from './components/user-onboarding/user-onboarding.component';
import { ConsentPageComponent } from './components/consent-page/consent-page.component';
import { UserCalendarComponent } from 'app/pages/user-calendar/user-calendar.component';
import { MoodcheckViewComponent } from './modules/professional/moodcheck-view/moodcheck-view.component';
import { UserDetailsDashboardComponent } from './modules/professional/user-details-dashboard/user-details-dashboard.component';
import { MysettingComponent } from './pages/mysetting/mysetting.component';
import { MyTeamComponent } from 'app/pages/my-team/my-team.component';
import { SeriesAccessGuard } from './guards/series-access.guard';
const appRoutes: Routes = [
    {
        path: '',
        component: PublicPortalTemplate,
        loadChildren: () =>
            import('./modules/auth/auth.module').then(m => m.AuthModule),
    },
    /*
     * Static page routing is coming from here
     */
    {
        path: '',
        component: PublicPortalTemplate,
        loadChildren: () =>
            import('./modules/public/public.module').then(m => m.PublicModule),
    },
    {
        path: 'app/practice',
        component: AuthSideMenuTemplate,
        canActivate: [AuthGuard, OrgConfigGuard],
        loadChildren: () =>
            import('./modules/practice/practice.module').then(
                m => m.PracticeModule
            ),
    },
    {
        path: 'professional',
        component: AuthSideMenuTemplate,
        canActivate: [AuthGuard, AclGuard],
        loadChildren: () =>
            import('./modules/professional/professional.module').then(
                m => m.ProfessionalModule
            ),
    },
    {
        path: '',
        component: PublicPortalTemplate,
        children: [
            {
                path: '404',
                component: NotFoundPage,
                data: { title: 'notFound' },
            },
        ],
    },
    {
        path: '',
        component: PublicPortalFullTemplate,
        children: [
            // {
            // 	path: 'purchase/:promo',
            // 	component: RegisterPaymentPage,
            // 	data: { title: 'payment' }
            // },
        ],
    },
    {
        path: 'app',
        component: AuthSideMenuTemplate,
        canActivate: [AuthGuard, OrgConfigGuard],
        children: [
            {
                path: '',
                component: DashboardPage,
                data: { title: 'dashboard' },
            },
            {
                path: 'load/:segment',
                component: DashboardPage,
                data: { title: 'moodcheck' },
            },
            {
                path: 'series',
                component: TheoryComponent,
                data: { title: 'series' },
            },
            {
                path: 'podcasts',
                component: PodcastsPage,
                data: { title: 'podcasts' },
            },
            {
                path: 'series/resiliences',
                component: ResiliencePage,
                data: { title: 'series' },
            },
            {
                path: 'community',
                component: CommunityComponent,
                data: { title: 'community' },
            },
            {
                path: 'emergency-contact',
                component: EmergencyContactPage,
                data: { title: 'emergencycontact' },
            },
            {
                path: 'resources',
                component: ResourcesPage,
                data: { title: 'resources' },
            },
            {
                path: 'resources-detail',
                component: ResourcesDetailComponent,
                data: { title: 'resource-detail' },
            },
            {
                path: 'corecontributors',
                component: CoreContributorsPage,
                data: { title: 'corecontributors' },
            },
            {
                path: 'counselor-sync',
                component: CounselorSyncPage,
                data: { title: 'counselors' },
            },
            {
                path: 'assessments',
                component: AssessmentsPage,
                data: { title: 'assessment' },
            },
            {
                path: 'assessments/listing',
                component: DasAssessmentListingPage,
                data: { title: 'assessmentlisting' },
            },
            {
                path: 'assessment/:typeAssessment/listing',
                component: DynamicAssessmentComponent,
                data: { title: 'dasassessmentlisting' },
            },
            {
                path: 'assessment/:typeAssessment/listing/:id',
                component: DynamicAssessmentComponent,
                data: { title: 'dasassessmentlisting' },
            },
            // {
            // 	path: 'assessment/:id/listing',
            // 	component: DasAssessmentListingComponent,
            // 	data: { title: 'dasassessmentlisting'}
            // },
            {
                path: 'assessment/result/:id/:type',
                component: AssessmentAlert,
                data: { title: 'dasassessmentresult' },
            },
            {
                path: 'assessment/result/:id/:type/:sid',
                component: AssessmentAlert,
                data: { title: 'dasassessmentresult' },
            },
            {
                path: 'alerts/listing',
                component: AlertsListingComponent,
                data: { title: 'alertslisting' },
            },
            {
                path: 'user-details/:id',
                component: UserDetailsPage,
                data: { title: 'userdetails' },
            },
            {
                path: 'assessments/resilience/listing',
                component: ResilienceListingPage,
                data: { title: 'resiliencelisting' },
            },
            {
                path: 'assessments/addiction/listing',
                component: AddictionListingPage,
                data: { title: 'resiliencelisting' },
            },
            {
                path: 'assessments/:assessment/new',
                component: DasAssessmentPage,
                data: { title: 'asessment' },
            },
            {
                path: 'wellbeing-assessments/:assessment/new',
                component: MentalWellbeingAssessmentComponent,
                data: { title: 'mentalwellbeingassessment' },
            },
            {
                path: 'settings',
                component: SettingsPage,
                data: { title: 'settings' },
            },
            {
                path: 'my-settings',
                component: MysettingComponent,
                data: { title: 'settings' },
            },
            {
                path: 'orgadmin-settings',
                component: OrgadminSettingComponent,
                data: { title: 'orgadmin-settings' },
            },
            {
                path: 'my-team',
                component: MyTeamComponent,
                data: { title: 'my-team' },
            },
            {
                path: 'series-listing/:series',
                component: CoursesListingComponent,
                data: { title: 'series' },
            },
            {
                path: 'series-listing/:series/:serieId',
                component: CoursesComponent,
                data: { title: 'series' },
            },
            {
                path: 'series-details/:series',
                component: CoursesComponent,
                canActivate: [SeriesAccessGuard],
                data: { title: 'series' },
            },
            {
                path: 'series-details/:series/:videoId/:autoplay',
                component: CourseVideoComponent,
                data: { title: 'series' },
            },
            {
                path: 'series-listing/:series/:serieId/:index',
                component: CoursesComponent,
                data: { title: 'series' },
            },
            {
                path: 'organizationprofile',
                component: OrganizationEditPage,
                data: { title: 'organizationprofile' },
            },
            {
                path: 'challenges',
                component: ChallengeListingPage,
                data: { title: 'challenges' },
            },
            {
                path: 'calendar',
                component: CalendarPage,
                data: { title: 'myCalendar' },
            },
            {
                path: 'user-calendar',
                component: UserCalendarComponent,
                data: { title: 'userCalendar' },
            },
            {
                path: 'videoshare/session/:id',
                component: VideoShareComponent,
                data: { title: 'videosession' },
            },
            {
                path: 'videoshare/open/new',
                component: VideoShareComponent,
                data: { title: 'videosessionopen' },
            },
            {
                path: 'videoshare/open/:id',
                component: VideoShareComponent,
                data: { title: 'videosessionshare' },
            },
            {
                path: 'demographic',
                component: DemographicPage,
                data: { title: 'demographicinfo' },
            },
            {
                path: 'scheduled-push-notification',
                component: SchedulePushNotificationPage,
                data: { title: 'schedulePushNotification' },
            },
            {
                path: 'dated-event-listing',
                component: UserDatedEventsListingPage,
                data: { title: 'userDatedEventsListing' },
            },
            {
                path: 'additional-resources',
                component: AdditionalResourcesPage,
                data: { title: 'additionalResources' },
            },
            {
                path: 'additional-resource-listing',
                component: AdditionalResourceListingPage,
                data: { title: 'additionalResources' },
            },
            {
                path: 'resource-view',
                component: ResourceView,
                data: { title: 'resourceView' },
            },
            {
                path: 'favourites',
                component: FavouriteListingComponent,
                data: { title: 'favouriteListing' },
            },
            {
                path: 'nps-survey',
                component: NpsSurveyComponent,
                data: { title: 'npsSurvey' },
            },
            {
                path: 'list-nps-survey',
                component: NpsSurveyListingComponent,
                data: { title: 'listNpsSurvey' },
            },
            {
                path: 'mood-check',
                component: MoodcheckModalComponent,
                data: {
                    title: 'MoodCheck',
                },
            },
            {
                path: 'surveys/:surveyId/:surveyType',
                component: DynamicformPageComponent,
                data: { title: 'dynamicformPage' },
            },
        ],
    },
    {
        path: 'onboarding-surveys/:surveyId/:surveyType',
        component: DynamicformPageComponent,
        data: { title: 'dynamicformPage' },
    },
    {
        path: 'onboarding-assessment/result/:id/:type',
        component: AssessmentAlert,
        data: { title: 'dasassessmentresult' },
    },
    {
        path: 'onboarding-assessments/:assessment/new',
        component: MentalWellbeingAssessmentComponent,
        data: { title: 'asessment' },
    },
    {
        path: 'user-onboarding',
        component: UserOnboardingComponent,
        data: { title: 'useronboarding' },
    },
    {
        path: 'onboarding-consentpage',
        component: ConsentPageComponent,
        data: { title: 'useronboarding' },
    },
    {
        path: 'professional',
        component: AuthSideMenuTemplate,
        canActivate: [AuthGuard, AclGuard],
        data: { role: ['professional', 'orgadmin'] },
        children: [
            {
                path: 'user-details-dashboard/:id',
                component: UserDetailsDashboardComponent,
                data: {
                    title: 'Overview',
                },
            },
        ],
    },
    {
        path: 'executive',
        component: AuthSideMenuTemplate,
        canActivate: [AuthGuard, AclGuard],
        data: { role: ['orgadmin'] },
        children: [
            {
                path: 'organization-edit',
                component: OrganizationEditPage,
                data: { title: 'organizationedit' },
            },
            {
                path: 'organization-setup',
                component: OnboardingPage,
                data: { title: 'organizationsetup' },
            },
        ],
    },
    {
        path: 'manage',
        component: AuthSideMenuTemplate,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'resources',
                component: ManageResourcesPage,
                data: { title: 'manageresources' },
            },
        ],
    },
    {
        path: 'admin',
        component: AuthSideMenuTemplate,
        canActivate: [AuthGuard, AclGuard],
        data: { role: ['admin', 'superadmin', 'relationshipmanager'] },
        children: [
            {
                path: 'user-listing',
                component: UserListingAdminPage,
                data: { title: 'userlisting' },
            },
            {
                path: 'user/:id',
                component: UserProfileDetailsComponent,
                data: { title: 'userprofiledetails' },
            },
            {
                path: 'organization/:id',
                component: OrganizationViewPage,
                data: { title: 'organizationview' },
            },
            {
                path: 'organization-listing',
                component: OrganizationListingPage,
                data: { title: 'organizationlisting' },
            },
            {
                path: 'activity',
                component: ActivityInfoPage,
                data: { title: 'activity' },
            },
            {
                path: 'challenges',
                component: ChallengeListingAdminPage,
                data: { title: 'managechallenges' },
            },
            {
                path: 'reports',
                component: ReportsPage,
                data: { title: 'reports' },
            },
            {
                path: 'system',
                component: ReportsPage,
                data: { title: 'system' },
            },
            {
                path: 'manage-resources',
                component: ManageResourcesPage,
                data: { title: 'manageresourcess' },
            },
            {
                path: 'assessments-listing',
                component: AssessmentsListingPage,
                data: { title: 'assessmentslisting' },
            },
            {
                path: 'list-nps-survey',
                component: NpsSurveyListingComponent,
                data: { title: 'listNpsSurvey' },
            },
            {
                path: 'resources-import',
                component: ResourceImportPage,
                data: { title: 'resourcessimport' },
            },
            {
                path: 'series-listing',
                component: AdminCourseListingPage,
                data: { title: 'adminResourceImport' },
            },
            {
                path: 'internal-user-listing',
                component: InternalUserListingComponent,
                data: { title: 'internalUserlisting' },
            },
            {
                path: 'assessments-view/:id',
                component: AssessmentViewComponent,
                data: { title: 'assessmentsView' },
            },
            {
                path: 'auditListing',
                component: AuditListingComponent,
                data: { title: 'auditListing' },
            },
            {
                path: 'system-permission',
                component: SystemPermissionComponent,
                data: { title: 'systemPermission' },
            },
            {
                path: 'relational-manager-user-listing',
                component: InternalUserListingComponent,
                data: { title: 'relationalManagerUserlisting' },
            },
            {
                path: 'configListing',
                component: ConfigListingComponent,
                data: { title: 'configListing' },
            },
        ],
    },
    {
        path: '**',
        redirectTo: '/404',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            enableTracing: !environment.production,
            relativeLinkResolution: 'legacy',
        }),
    ],
    exports: [RouterModule],
    declarations: [],
})
export class AppRoutingModule {}
