import {
	Component,
	ViewChild,
	ViewEncapsulation,
	OnInit,
	Inject,
} from '@angular/core';
import { User } from '../../models/user';
import { Assessment, AssessmentPDF } from '../../models/assessment';
import { UserService } from '../../lib/user.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
// import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LogService } from 'app/lib/log.service';
import { StorageService } from 'app/lib/storage.service';
import { LocationService, CrisisHotline } from 'app/lib/location.service';
import { ApiService } from 'app/lib/api.service';
import { Organization } from '../../../app/models/organization';
import { ModalService } from 'app/lib/modal.service';
import { AssessmentService } from 'app/lib/assessment.service';
import { AnalyticEvent } from 'app/lib/analytic-event';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { UtilityService } from 'app/lib/utility.service';
import * as moment from 'moment';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
import * as _ from "lodash";
import { Column } from 'ng2-smart-table/lib/lib/data-set/column';
import { Location } from '@angular/common';
import { AssessmentPdfService } from 'app/lib/assessment-pdf.service';

@Component({
	selector: 'page-assessment-alert',
	templateUrl: 'assessment-alert.html',
	styleUrls: ['./assessment-alert.scss'],
})
export class AssessmentAlert implements OnInit {
    type: string;
    message: string;
    email: string;
    success = false;
    share = true;
    user: User;
    assessment: Assessment[];
	assessmentPdfView: AssessmentPDF[];
    stresstitle: string;
    depressiontitle: string;
    anxietytitle: string;
    anxietylevel: number;
    depressionlevel: number;
    stresslevel: number;
    intrusive: number;
    avoidance: number;
    hyperarousal: number;
    ptsd: number;
    ptsdalert: boolean = false;
    ptsdtitle: string;
    ptsdbody: string;
    ptsdlevels: any;
    finish: boolean;
    showdas: boolean;
    enableResources: boolean = false;
    assessmentResult: Array<any> = [];
    depressiondescription = 'Your depression level is ';
    anxietydescription = 'Your anxiety level is ';
    stressdescription = 'Your stress level is ';
    buttonText: string;
    quizType: string;
    cdkScrollable;
    contactGroup: {};
    gethelpbutton: boolean = false;
    hotline: CrisisHotline;
    dialing: boolean;
    popupText: any;
    emergencyContact: string;
    organization: Organization;
    isloaded: boolean = false;
    contactGroupTelephone: string;
    showOther: boolean;
    id: string;
    resourcegroup: any;
    showall: boolean = true;
    selectedID: string;
    score: number;
    resourcesSetResult: boolean = false;
    previousUrl: string;
    levelScore: number;
    gethelpbuttondas: boolean = false;
    returnPath: any;
    showEmptyHeader: boolean = false;
	content: any[];
	base64LogoImage: any;
	base64HeaderBarImage: any;
	pdfObj = null;
	public dd = {
		content: [],
		styles: {
			header: {
				bold: true,
				fontSize: 15,
				margin: [0, 15]
			}
		},
		defaultStyle: {
			fontSize: 12
		}
	}

    syncid: string;
	translationData: any;
	translationResData: any;
	resourcesQuestions: any[];
    currentLanguage: any;
	translationResources: any;
	showMoreRecommendations: boolean = false;
	textLabel: string;
    constructor(
        private api: ApiService,
        private log: LogService,
        private storage: StorageService,
        private route: ActivatedRoute,
        private modalService: ModalService,
        private userService: UserService,
        private translate: TranslateService,
        private locationService: LocationService,
        private router: Router,
        private assessmentService: AssessmentService,
		private utilityService: UtilityService,
        private location: Location,
		public assessmentPdfService: AssessmentPdfService
    ) {
        this.user = this.userService.getUser();

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.currentLanguage = this.assessment?.length
                ? this.getLangForResourceList(this.assessment[0])
                : this.storage.get('lang');
        });
        this.id = this.route.snapshot.paramMap.get('id');
        if  (this.user.userType !== 'user')  {
            this.syncid = this.route.snapshot.paramMap.get('sid');
        }
        this.type = this.route.snapshot.paramMap.get('type');
        this.route.queryParams.subscribe(params => {
            this.previousUrl = params['prevpage'];
      });
		this.log.event(
			AnalyticEvent.event[
			this.type + "AssessmentResultView"
			]
		);
        this.selectedID = 'all';
        this.contactGroup = this.user.organizations[0]?.contactGroup;
        this.translate
            .stream('das.result.ptsd.levels')
            .subscribe((res: any) => {
                this.ptsdlevels = res;
            });
		this.translate.stream('dynamicAssessment').subscribe((res: any) => {
			this.translationData = res;
		});
		this.translate.stream('dynamicAssessments').subscribe((res: any) => {
			this.translationResData = res;
		});
		this.translate.stream('resources').subscribe((res: any) => {
			this.translationResources = res;
		});
		if (
			this.user.primaryOrganization &&
			this.user.primaryOrganization.enableResources
		) {
			this.enableResources =
				this.user.primaryOrganization.enableResources;
		}
		
		this.route.queryParams.subscribe(queryParams => {
			this.returnPath = queryParams['path'];
		});
		if (this.returnPath === 'onboarding') {
			this.showEmptyHeader = true;
		}
	}

    ngOnInit() {
        let url =
            this.type === 'dass'
                ? `assessment/${this.id}`
                : `assessment/${this.id}?type=${this.type}`;
        if  (this.syncid && this.user.userType !== 'user')  {
            url += `&SynchedUserID=${this.syncid}`;
        }
        this.api.get(url).subscribe(
            (result: any) => {
                this.isloaded = true;
                this.assessment = Assessment.initializeArray(result.data);
				if (result?.data) {
					this.assessmentPdfService.initialize(this.assessment, this.type);
				}
                this.showHelpNow();
                this.currentLanguage = this.getLangForResourceList(
                    this.assessment[0]
                );
                this.quizType = this.assessment[0].type;
                if (this.quizType === 'ptsd') {
                    this.finish = false;
                    this.showdas = true;
                    if (
                        this.assessment[0].intrusive >= 1 &&
                        this.assessment[0].avoidance >= 3 &&
                        this.assessment[0].hyperarousal >= 2
                    ) {
                        this.ptsdalert = true;
                        this.ptsdtitle = this.ptsdlevels.hightitle;
                        this.ptsdbody = this.ptsdlevels.highbody;
                    } else {
                        this.ptsdtitle = this.ptsdlevels.lowtitle;
                        this.ptsdbody = this.ptsdlevels.lowbody;
                    }
                } else {
                    this.finish = true;
                    this.showdas = true;
                }
                this.depressiontitle = this.assessment[0].depressionlevellabel;
                this.depressionlevel = this.assessment[0].depressionlevel;
                this.anxietytitle = this.assessment[0].anxietylevellabel;
                this.anxietylevel = this.assessment[0].anxietylevel;
                this.stresstitle = this.assessment[0].stresslevellabel;
                this.stresslevel = this.assessment[0].stresslevel;
                this.intrusive = this.assessment[0].intrusive;
                this.score = this.assessment[0].score;
                this.generateAssessmentResult();
            },
            (error: any) => {
                this.log.error('Error getting assessment. ' + error.message);
            },
            () => {
                this.isloaded = true;
            }
        );

		this.translate.get('contact').subscribe((res: any) => {
			this.popupText = res;
		});
		this.locationService.getLocation().subscribe((data: any) => {
			this.log.debug(data);
			this.hotline = this.locationService.getHelpline(
				data.country,
				data.regionName
			);
		});

		let orgId;
		if (this.storage.get('orgselect')) {
			orgId = this.storage.get('orgselect');
		} else {
			if (this.user.primaryOrganization) {
				orgId = this.user.primaryOrganization.id;
			}
		}

		if (orgId) {
			this.api.get('organizations/' + orgId).subscribe(
				(result: any) => {
					if (result) {
						this.organization = new Organization(result.data,
							'view');
						if (this.type === 'dass') {
							this.orgQuestions();
						}
						if (this.organization.contactGroup.telephone) {
							this.contactGroupTelephone =
								this.organization.contactGroup.telephone;
						} else {
							this.showOther = true;
						}
					}
				},
				(error: any) => {
					this.log.error(
						'Error getting organization. ' + error.message
					);
				},
				() => {
					this.isloaded = true;
				}
			);
		}
	}

	orgQuestions() {
		// for each on question set.  loop and add answers
		if (
			this.organization.resourceSet &&
			this.organization.enableResources && this.assessment[0]?.resourceSet.length != 0
		) {
			this.resourcesQuestions = [];
			const questionObject = {
				Question: this.translationResData.resourceQuestionTitle,
				OptionComments: []
			};
			this.assessment[0].resourceSet.forEach(qs => {
				questionObject.OptionComments.push(qs.title);
			});
			this.resourcesQuestions.push(questionObject);
		}
	}
	generateAssessmentResult() {
		let result = [];
		if (this.type === 'dass') {
			result.push(
				this.assessmentService.getDasAssessmentResultArray(
					'depression',
					this.depressiontitle,
					this.depressionlevel
				)
			);
			result.push(
				this.assessmentService.getDasAssessmentResultArray(
					'anxiety',
					this.anxietytitle,
					this.anxietylevel
				)
			);
			result.push(
				this.assessmentService.getDasAssessmentResultArray(
					'stress',
					this.stresstitle,
					this.stresslevel
				)
			);
		} else {
			// let resultData = [];
			let resultData = {
				title: "assessments." + this.type + ".result.title",
				level: this.type === 'mentalwellbeing'
					? Math.trunc(this.assessment[0].metricScore) 
					: this.assessment[0].resultScore,
				levelText: "",
				levelKey: 0,
				label: "",
				courseLink: "",
				content1: "",
				content1_1: "",
				content2: "",
				content3: "",
				courseTitle: "",
				showEmergencyContact: false,
				showDetailedEmergencyContact: true,
				recommendationList: [],
			  };
			switch (this.type) {
				case 'resilience': {
					result.push(
						this.assessmentService.getResilienceAssessmentResultArray(
							this.score,
							this.type
						)
					);
					break;
				}
				case 'alcoholuse': {
					result.push(
						this.assessmentService.getAlcoholUseAssessmentResultArray(
							this.score,
							this.type
						)
					);
					break;
				}
				case 'anxiety': {
					result.push(
						this.assessmentService.getAnxietyAssessmentResultArray(
							this.score,
							this.type
						)
					);
					break;
				}
				case 'depression': {
					result.push(
						this.assessmentService.getDepressionAssessmentResultArray(
							this.score,
							this.type
						)
					);
					break;
				}
				case "mentalwellbeing": {
					result.push(
					  this.assessmentService.getMentalWellbeingAssessmentResultArray(
						this.assessment[0].metricScore,
						resultData,
						this.assessment[0]
					  )
					);
					break;
				  }
				default: {
				}
			}
		}
		this.assessmentResult = [...result];
		console.log(this.assessmentResult,"welltrack link")
	}

	showHelpNow() {
		if (
			this.assessment[0].depressionlevel >= 3 ||
			this.assessment[0].anxietylevel >= 3 ||
			this.assessment[0].stresslevel >= 3
		) {
			this.gethelpbuttondas = true;
		}

		if (this.type === 'dass') {
			this.gethelpbuttondas = this.assessment.some(
				assess =>
					assess.depressionlevel >= 3 ||
					assess.anxietylevel >= 3 ||
					assess.stresslevel >= 3
			);
		} else {
			this.gethelpbutton = this.assessment.some(assess => {
				this.levelScore =
					this.assessmentService.getAssessmentScoreLevel(
						assess.type,
						assess.resultScore
					);
				switch (assess.type) {
					case 'resilience':
						return false;
					case 'alcoholuse':
						return this.levelScore >= 1;
					case 'anxiety':
						return this.levelScore >= 2;
					case 'depression':
						return this.levelScore >= 3;
					case 'mentalwellbeing':
						return this.levelScore < 2;
					default:
						return false;
				}
			});
		}
	}

	goNext() {
		this.showdas = false;
		this.finish = true;
	}
	showResourceSetGroup(resource, val) {
		if (val === 'true') {
			this.selectedID = 'all';
			this.showall = true;
		} else {
			this.selectedID = resource.id;
			this.showall = false;
			this.resourcegroup = [];
			this.resourcegroup = resource.resourcesetGroup;
		}
	}

	isConditionMet(type, label): boolean {
		this.translate.get(label).subscribe((translation: string) => {
			this.textLabel = translation;
		  });
		return type === 'getHelpNow' && this.textLabel !== 'Low';
	  }

    close() {
        if (this.router.url.search('user-details') !== -1) {
            this.success = false;
        } else if (this.quizType === 'ptsd') {
            this.success = false;
            this.router.navigateByUrl('/app/resources');
        } else if (this.router.url.search('/app/alerts/listing') !== -1) {
            this.success = false;
            this.router.navigateByUrl('/app/alerts/listing');
        } else if (
            this.returnPath === 'onboarding' &&
            this.user.onboardingStep >= 3
        ) {
            this.success = false;
            this.router.navigate(['app']);
            // this.router.navigateByUrl('/user-onboarding');
        } else if (this.previousUrl === 'adminlisting') {
            this.success = false;
            this.router.navigate(
				['/app/assessment/' + this.type + '/listing/' + this.syncid],
				{
					queryParams: {
						prevpage: 'clientlist'
					}
				}
			);
        } else if (this.previousUrl === 'clientlist') {
            this.success = false;
            this.router.navigateByUrl(
                '/professional/user-details-dashboard/' + this.syncid
            );
        } else {
			this.success = false;
			if (this.previousUrl === 'listing') {
				this.router.navigate(
					['/app/assessment/' + this.type + '/listing'],
					{
						queryParams: {
							prevpage: 'listing'
						}
					}
				);
			} else {
				this.router.navigate(
					['/app/assessment/' + this.type + '/listing'],
					{
						queryParams: {
							prevpage: 'listing'
						}
					}
				);
			}
        }
    }

	goResources() {
		// this.dialogRef.close();
		this.router.navigateByUrl('/app/resources');
	}

	onCallContact(event: MouseEvent) {
		if (this.dialing) {
			this.dialing = false;
		} else {
			event.preventDefault();
			const hotLineText =
				this.user.primaryOrganization &&
					this.user.primaryOrganization.settings['assessment'] ===
					'resilience'
					? this.popupText.popupEmergencyTextVeteran
					: this.organization.contactGroup.getHelpDescription
						? this.organization.contactGroup.getHelpDescription
						: this.popupText.popupEmergencyHotline;

			this.modalService
				.showConfirmation(this.popupText.popTitle, hotLineText)
				.afterClosed()
				.subscribe(result => {
					if (result) {
						if (this.contactGroupTelephone) {
							window.location.href =
								'tel:' + this.contactGroupTelephone;
						}

						this.api
							.post('analytics/supportlineclick', {
								phoneNumber: this.contactGroupTelephone,
								contactGroupId:
									this.organization.contactGroup.id,
							})
							.subscribe(
								(result: any) => {
									this.log.debug('Activity logged');
								},
								(error: any) => {
									this.log.debug(
										'Something went wrong with the activity logger.'
									);
								}
							);

						this.dialing = true;
						event.target.dispatchEvent(new MouseEvent('click'));
					}
				});
		}
	}

	onHotline(event: MouseEvent) {
		if (this.dialing) {
			this.dialing = false;
		} else {
			event.preventDefault();

			this.modalService
				.showConfirmation(
					this.popupText.popTitle,
					this.popupText.popUpHotline
				)
				.afterClosed()
				.subscribe(result => {
					if (result) {
						if (this.hotline.url) {
							window.location.href = this.hotline?.url;
						}
						/*
					this.api.post('analytics/supportlineclick', { phoneNumber: this.contactGroupTelephone, contactGroupId: this.organization.contactGroup.id }).subscribe(
						(result: any) => {
							this.log.debug('Activity logged');
						},
						(error: any) => {
							this.log.debug('Something went wrong with the activity logger.');
						}
					);
					*/
						//Add analytics event for general hotline dialed
						this.dialing = true;
						event.target.dispatchEvent(new MouseEvent('click'));
					}
				});
		}
	}

	delete() {
		this.modalService
			.showConfirmation(
				'Delete',
				'Are you sure you want to delete your assessment?'
			)
			.afterClosed()
			.subscribe(result => {
				if (result) {
					const Url =
						this.type === 'dass'
							? 'assessment/' + this.assessment[0].userQuizId
							: `assessment/` +
							this.assessment[0].userQuizId +
							`?Type=${this.type}`;
					this.api.delete(Url).subscribe(
						(result: any) => {
							this.log.event(
								AnalyticEvent.event[
								  this.type + "AssessmentDeleted"
								]
							  );
							this.modalService.showAlert(
								'Success',
								'Assessment has been deleted successfully.'
							);
							this.router.navigate([
								'/app/assessment/' + this.type + '/listing',
							]);
						},
						(error: any) => {
							this.log.event(
								AnalyticEvent.event.assessmentDeleteError
							  );
							this.log.error('Error deleting.');
						}
					);
				}
			});
	}

	onResourceClick(contact) {
		window.open(contact.website, '_blank');
		this.api
			.post('analytics/resourceclick', {
				resourceGroup: contact,
			})
			.subscribe(
				(result: any) => { },
				(error: any) => {
					this.log.error('Error logging link click');
				}
			);
	}

	onResourceInternalClick(contact) {
		this.api
			.post('analytics/resourceclick', {
				resourceGroup: contact,
			})
			.subscribe(
				(result: any) => {
					this.router.navigateByUrl(contact.internal);
				},
				(error: any) => {
					this.log.error('Error logging link click');
				}
			);
	}

	onLink(link: string) {
		this.router.navigate([link]);
	}

	onClickResource(resourceItem: any) {
		switch (resourceItem.type) {
			case "series": {
				this.router.navigateByUrl("/app/series-details/" + resourceItem.key);
				break;
			}
			case "page": {
				this.router.navigateByUrl(resourceItem.path);
				break;
			}
			case "tool": {
				const queryParams = { prevpage: 'result', type: this.type , id: String(this.assessment[0].id) };
				const queryString = new URLSearchParams(queryParams).toString();
				const fullPath = `${resourceItem.path}?${queryString}`;
				this.router.navigateByUrl(fullPath);
				// this.router.navigateByUrl(resourceItem.path);
				break;
			}
			case "welltrackConnect": {
				const welltrackUrl = resourceItem.path;
				if (welltrackUrl) {
				  window.open(welltrackUrl, '_blank');
				} else {
				  this.log.error('No URL provided for welltrackConnect');
				}
				break;
			}
			case "moodcheck": {
				this.goToMoodCheck();
			}
		}
	}

	  async goToMoodCheck() {
		this.router.navigate(['/app/mood-check',]);
	  }

	showConnect() {
		return (
			!!this.user.primaryOrganization &&
			this.keyProp(this.user.primaryOrganization.settings)
		);
	}

	keyProp(settings) {
		return settings?.hasOwnProperty('showWelltrackConnect')
			? settings['showWelltrackConnect']
			: true;
	}


	pdfDataResult(qId) {

		let url = 'assessment/answers?type='+this.type+'&userQuizId=' + qId
		if  (this.syncid && this.user.userType !== 'user')  {
			url += `&SynchedUserID=${this.syncid}`;
		}

		this.api.get(url).subscribe(
			(result: any) => {
				this.isloaded = true;
				this.assessmentPdfView = AssessmentPDF.initializeArray(result);
				this.assessmentPdfService.exportAssessemntResult(
					this.assessmentResult,
					this.assessmentPdfView
				  );
			},
			(error: any) => {
				this.log.error('Error getting assessment. ' + error.message);
			},
			() => {
				this.isloaded = true;
			});

	}


	async exportAssessemnt() {
		if (this.type === "mentalwellbeing") {
			this.assessmentPdfService.exportAssessemntResult(
			  this.assessmentResult,
			  []
			);
		  } else {
			this.pdfDataResult(this.assessment[0].userQuizId);
		  }
	}


    filteredResourceSet(resourceSet: any): any[] {
        return resourceSet
            ? resourceSet.filter(
                  resource => resource.language === this.currentLanguage
              ) // Replace 'status' with your actual condition
            : [];
    }

    getLangForResourceList(assessment: any) {
        let selectedLang = this.storage.get('lang');
        if (
            (selectedLang === 'fr' || selectedLang === 'es') &&
            !this.hasResourceByLangage(selectedLang, assessment.resourceSet)
        ) {
            selectedLang = 'en';
        }
        return selectedLang;
    }

    hasResourceByLangage(lang: string, resourceSet: any): boolean {
        return resourceSet.some(r => r['language'] === lang);
    }
    goBack() {
        this.location.back();
    }
}
