import {
	Component,
	OnInit,
	Inject,
	ViewChild,
	ElementRef,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ApiService } from "../../../lib/api.service";
import { LogService } from "../../../lib/log.service";
import { RoomForPositive } from "../../../models/roomforpositive";
import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ModalService } from "app/lib/modal.service";

@Component({
	selector: "app-room-for-positive-create",
	templateUrl: "./room-for-positive-create.component.html",
	styleUrls: ["./room-for-positive-create.component.scss"],
})
export class RoomForPositiveCreateComponent implements OnInit {
	@ViewChild("text") text: ElementRef;
	words: any;
	pageMode: string;
	pageData = {};
	rfpForm: FormGroup;
	currentItemIndex: number = 0;
	questions;
	questionTypes;
	wordCount: any;
	rfpdata: any;
	currentUrl: string;

	constructor(
		public dialogRef: MatDialogRef<RoomForPositiveCreateComponent>,
		private translate: TranslateService,
		private api: ApiService,
		private log: LogService,
		private router: Router,
		private modalService: ModalService,
		private formBuilder: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.questions = RoomForPositive.ROOM_FOR_POSITIVE_QUESTIONS;
		this.questionTypes = RoomForPositive.ROOM_FOR_POSITIVE_QUESTIONS_TYPES;
		dialogRef.disableClose = true;
		this.pageMode = data.data["data"].mode;
		this.pageData = data.data["data"].data;
	}

	ngOnInit(): void {
		this.currentUrl = this.router.url;
		this.translate.stream("practice.rfp").subscribe((res: any) => {
			this.rfpdata = res;
		});
		this.createForm();
	}
	createForm() {
		this.rfpForm = this.formBuilder.group({
			questions: this.formBuilder.array([]),
		});
		this.questions.forEach((item, index) => {
			this.itemForms.push(this.createFormItem(item.questionId, index));
		});
	}

	get itemForms() {
		return this.rfpForm.get("questions") as FormArray;
	}

	get currentFormGroup() {
		return this.itemForms.controls[this.currentItemIndex];
	}

	onSubmit() {
		if (this.rfpForm.valid) {
			this.pageMode == "edit" ? this.editRfpEntry() : this.saveRfpEntry();
		} else {
			this.log.error("Form is invalid");
		}
	}

	saveRfpEntry() {
		let apiData = this.generateDataForApi(this.rfpForm.value);
		this.api.post("roomforpositive", apiData).subscribe(
			(data: any) => {
				this.modalService.showAlert(
					this.rfpdata.header,
					this.rfpdata.successBody
				);
				this.dialogRef.close();
				this.router
					.navigateByUrl("/refresh", { skipLocationChange: true })
					.then(() => {
						this.router.navigate([this.currentUrl]);
					});
			},
			(error: any) => {
				this.log.error("Error registering. " + error.message);
				this.modalService.showAlert(this.rfpdata.error, error.message);
			}
		);
	}

	editRfpEntry() {
		let apiData = this.generateDataForApi(this.rfpForm.value);

		this.api
			.put("roomforpositive/" + this.pageData["ID"], apiData)
			.subscribe(
				(data: any) => {
					this.modalService.showAlert(
						this.rfpdata.header,
						this.rfpdata.successEditBody
					);
					this.dialogRef.close();
					this.router
						.navigateByUrl("/refresh", { skipLocationChange: true })
						.then(() => {
							this.router.navigate([this.currentUrl]);
						});
				},
				(error: any) => {
					this.log.error("Error registering. " + error.message);
					this.modalService.showAlert(
						this.rfpdata.error,
						error.message
					);
				}
			);
	}

	createFormItem(questionId: number, index: number): FormGroup {
		return this.formBuilder.group({
			questionId: [questionId],
			answerTitle: [
				this.pageData
					? this.pageData[this.questionTypes[index] + "Title"]
					: "",
				[Validators.required],
			],
			answerDescription: [
				this.pageData
					? this.pageData[this.questionTypes[index] + "Description"]
					: "",
				[Validators.required],
			],
		});
	}

	nextItem() {
		const currentFormGroup = this.itemForms.controls[this.currentItemIndex];
		if (currentFormGroup.valid) {
			if (this.currentItemIndex >= this.itemForms.length - 1) {
				this.onSubmit();
			} else {
				this.currentItemIndex++;
			}
		} else {
			currentFormGroup.markAllAsTouched();
		}
	}

	previousItem() {
		this.currentItemIndex--;
	}

	generateDataForApi(dataArray: any) {
		let apiData: Object = {};
		dataArray.questions.forEach((item, index) => {
			apiData[this.questionTypes[index] + "Title"] = item.answerTitle;
			apiData[this.questionTypes[index] + "Description"] =
				item.answerDescription;
		});
		return apiData;
	}

	onClose() {
		this.modalService
			.showConfirmation(
				this.rfpdata.title,
				this.rfpdata.quitcontent,
				this.rfpdata.ok,
				this.rfpdata.cancel
			)
			.afterClosed()
			.subscribe((data) => {
				if (data) {
					// this.router.navigate(["/app/practice/room-for-positive/listing"]);
					setTimeout(() => {
						this.dialogRef.close();
					}, 1000);
				}
			});
	}

	wordCounter(event: KeyboardEvent) {
		const value = this.text.nativeElement.value.trim();
		const words = value.split(/\s+/);
		const wordCount = words.length;

		if (
			wordCount >= 300 &&
			event.key !== "Backspace" &&
			event.key !== "Delete"
		) {
			event.preventDefault();
		}

		this.words = wordCount;
	}
}
