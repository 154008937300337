import {
    Component,
    OnInit,
    Input,
    ViewEncapsulation,
    ViewChild,
    ChangeDetectorRef,
    Output,
    EventEmitter,
    ElementRef,
} from '@angular/core';
import { Chart, ChartData, ChartConfiguration } from 'chart.js';
import * as _ from 'lodash';

import { ApiService } from '../../../lib/api.service';
import { User } from '../../../models/user';
import { UserService } from '../../../lib/user.service';
import { Moodcheck } from '../../../models/moodcheck';
import { LogService } from '../../../lib/log.service';
import { ModalService } from '../../../lib/modal.service';
import { AnalyticEvent } from 'app/lib/analytic-event';
import * as moment from 'moment';
import { DailyMoodcheckViewComponent } from '../../../components/daily-moodcheck-view/daily-moodcheck-view.component';
import { MonthlyMoodcheckViewComponent } from '../../../components/monthly-moodcheck-view/monthly-moodcheck-view.component';
import { WeeklyMoodcheckViewComponent } from '../../../components/weekly-moodcheck-view/weekly-moodcheck-view.component';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { OverallViewMoodcheckComponent } from '../../../components/overall-view-moodcheck/overall-view-moodcheck.component';
import { DateService } from 'app/lib/url.service';
import { ActivityScheduler } from '../../../models/activity-scheduler';
import { Assessment, ASSESSMENT_TYPES_CONFIG } from 'app/models/assessment';
import { TranslateService } from '@ngx-translate/core';
import { AssessmentService } from 'app/lib/assessment.service';
import { CrisisHotline, LocationService } from 'app/lib/location.service';
import { StorageService } from 'app/lib/storage.service';
import { Organization } from 'app/models/organization';
import { Router } from '@angular/router';
import { ASSESSMENT_TYPES_CONFIG_HOME } from '../../../models/assessment';
import { MatDatepicker } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-user-section-dashboard',
    templateUrl: './user-section-dashboard.component.html',
    styleUrls: ['./user-section-dashboard.component.scss'],
    providers: [DatePipe],
})
export class UserSectionDashboardComponent implements OnInit {
    @ViewChild('dasAssessmentChart', { static: false })
    canvasRef: ElementRef<HTMLCanvasElement>;
    assessmentList = ASSESSMENT_TYPES_CONFIG.ASSESSMENT_TYPE_LIST;
    selectedMode: any;
    selectedMood: any;
    selectedDate: Date = new Date();
    changeDate: any;
    currentDate: Date;
    user: User;
    moodchecks: Moodcheck[];
    isEmpty: boolean;
    today: Date = new Date();
    @ViewChild('moodcheckDailyViewComp')
    moodcheckDailyViewComp: DailyMoodcheckViewComponent;
    @ViewChild('moodcheckWeeklyViewComp')
    moodcheckWeeklyViewComp: WeeklyMoodcheckViewComponent;
    @ViewChild('moodcheckMonthlyViewComp')
    moodcheckMonthlyViewComp: MonthlyMoodcheckViewComponent;
    @ViewChild('moodchecOverallViewComp')
    moodchecOverallViewComp: OverallViewMoodcheckComponent;
    @Output() selectedDateChange: EventEmitter<Date> = new EventEmitter<Date>();
    dasAssessmentChart: Chart;
    chartData: ChartData;
    dateFilter: any = 30;
    dateFilterTitle: any = 'last30days';
    activeLink = 'last30days';
    typeAssessment: string;
    assessments: Assessment[];
    selectedChartLegend = 'mentalwellbeing';
    hotline: CrisisHotline;
    contactGroupTelephone: string;
    organization: Organization;
    showOther: boolean;
    dialing: boolean;
    popupText: any;
    questionIndex: number = 0;
    emergencyContact: string;
    recommendedcourses = [];
    dateFilterOptions = [
        {
            key: 'last30days',
            value: 30,
            isSelected: true,
        },
        {
            key: 'last3Months',
            value: 90,
            isSelected: false,
        },
        {
            key: 'last6Months',
            value: 180,
            isSelected: false,
        },
    ];
    gethelpbuttondas: boolean = false;

    firstName: string;
    translationData: any;
    assessmentTranslation: any;
    gethelpbutton: boolean = false;
    levelScore: number;
    requestOptions: { type: string };
    syncUserId: number;
    assessmentsList: Assessment[];
    myCurrentGoal: string = '';
    goalSubscription: Subscription;
    assessmentConfig = [];
    listingAssessments = [];

    constructor(
        private api: ApiService,
        private log: LogService,
        private storage: StorageService,
        private dateservice: DateService,
        private modalService: ModalService,
        private router: Router,
        private locationService: LocationService,
        private translate: TranslateService,
        private userService: UserService,
        private datePipe: DatePipe,
        private assessmentService: AssessmentService
    ) {
        this.currentDate = new Date();
        this.user = this.userService.getUser();
        this.firstName = this.user.firstName
            ? this.user.firstName
            : this.user.fullName;
    }

    ngOnInit(): void {
        this.selectedMode = 'summary';
        this.selectedMood = 'Weekly';
        this.dateservice.getSelectedDate().subscribe(date => {
            this.selectedDate = date;
        });
        this.refreshContent();

        this.setAssessmentConfig();
        this.typeAssessment = 'mentalwellbeing';
        this.selectedChartLegend = this.typeAssessment;
        this.getChartInit(this.dateFilter);
        this.log.event(AnalyticEvent.event.dashboard);
        this.translate.stream('dynamicAssessment').subscribe((res: any) => {
            this.translationData = res;
            this.getRoute();
        });
        this.translate.stream('lang').subscribe((res: any) => {
            if (res === 'en') {
                this.getChartInit(this.dateFilter);
            } else {
                this.getChartInit(this.dateFilter);
            }
        });
        if (this.typeAssessment == 'mentalwellbeing') {
            this.goalSubscription =
                this.assessmentService.MWAcurrentGoalSubject.subscribe(data => {
                    if (data) {
                        this.myCurrentGoal = data;
                    }
                });
        }
        this.getAssessmentList();
        this.user = this.userService.getUser();
        this.log.event(AnalyticEvent.event.hotlineCalled);

        this.translate.get('contact').subscribe((res: any) => {
            this.popupText = res;
        });
        this.locationService.getLocation().subscribe((data: any) => {
            this.log.debug(data);
            this.hotline = this.locationService.getHelpline(
                data.country,
                data.regionName
            );
        });

        let orgId;
        if (this.storage.get('orgselect')) {
            orgId = this.storage.get('orgselect');
        } else {
            if (this.user.primaryOrganization) {
                orgId = this.user.primaryOrganization.id;
            }
        }

        if (orgId) {
            this.api.get('organizations/' + orgId).subscribe(
                (result: any) => {
                    this.organization = new Organization(result.data);
                    if (this.organization.contactGroup.telephone) {
                        this.contactGroupTelephone =
                            this.organization.contactGroup.telephone;
                    } else {
                        this.showOther = true;
                    }
                },
                (error: any) => {
                    this.log.error(
                        'Error getting organization. ' + error.message
                    );
                },
                () => {}
            );
        }
    }

    openDatePicker(picker: MatDatepicker<Date>) {
        picker.open();
    }
    getRoute() {
        this.assessmentTranslation = this.translationData[this.typeAssessment];
    }
    getAssessmentList() {
        if (this.typeAssessment != 'dass') {
            this.requestOptions = {
                type: this.typeAssessment,
                // limit: this.itemsPerPage,
            };
        }
        let url = 'assessments';
        if (this.syncUserId && this.user.userType !== 'user') {
            url += `?UserID=${this.syncUserId}`;
        }
        this.api.get(url, this.requestOptions).subscribe(
            (result: any) => {
                let assessmentResult = Assessment.initializeArray(result.data);
                this.assessments = assessmentResult;
                this.typeAssessment == 'mentalwellbeing' &&
                    this.assessmentsList.length &&
                    this.setMyCurrentGoal();
                this.showHelpNow(this.assessments);
            },
            (error: any) => {
                this.log.error(
                    'Error getting assessment questions. ' + error.message
                );
            }
        );
    }
    setMyCurrentGoal() {
        let answers = this.assessmentsList[0].answers;
        let lastGoal = answers[answers.length - 1] as {
            Answers: {
                AnswerOptionID: number;
                AnswerValue: string;
                OptionComment: string | null;
            }[];
        };
        this.myCurrentGoal = lastGoal.Answers[0].AnswerValue || '';
        this.assessmentService.setMWACurrentGoal(this.myCurrentGoal);
    }
    showHelpNow(assessment) {
        if (this.typeAssessment === 'dass') {
            this.gethelpbuttondas = assessment.some(
                assess =>
                    assess.depressionlevel >= 3 ||
                    assess.anxietylevel >= 3 ||
                    assess.stresslevel >= 3
            );
        } else if (assessment) {
            this.gethelpbutton = assessment.some(assess => {
                this.levelScore =
                    this.assessmentService.getAssessmentScoreLevel(
                        assess.type,
                        assess.resultScore
                    );
                switch (assess.type) {
                    case 'resilience':
                        return false;
                    case 'alcoholuse':
                        return this.levelScore >= 1;
                    case 'anxiety':
                        return this.levelScore >= 2;
                    case 'depression':
                        return this.levelScore >= 3;
                    case 'mentalwellbeing':
                        return this.levelScore <= 2;
                    default:
                        return false;
                }
            });
        }
    }

    onHotline(event: MouseEvent) {
        if (this.dialing) {
            this.dialing = false;
        } else {
            event.preventDefault();

            this.modalService
                .showConfirmation(
                    this.popupText.popTitle,
                    this.popupText.popUpHotline
                )
                .afterClosed()
                .subscribe(result => {
                    if (result) {
                        this.log.event(AnalyticEvent.event.hotlineCalled);
                        this.dialing = true;
                        event.target.dispatchEvent(new MouseEvent('click'));
                    }
                });
        }
    }

    onCallContact(event: MouseEvent) {
        if (this.dialing) {
            this.dialing = false;
        } else {
            event.preventDefault();
            const hotLineText =
                this.user.primaryOrganization &&
                this.user.primaryOrganization.settings['assessment'] ===
                    'resilience'
                    ? this.popupText.popupEmergencyTextVeteran
                    : this.organization.contactGroup.getHelpDescription
                      ? this.organization.contactGroup.getHelpDescription
                      : this.popupText.popupEmergencyHotline;

            this.modalService
                .showConfirmation(this.popupText.popTitle, hotLineText)
                .afterClosed()
                .subscribe(result => {
                    if (result) {
                        if (this.contactGroupTelephone) {
                            window.location.href =
                                'tel:' + this.contactGroupTelephone;
                        }
                        this.log.event(
                            AnalyticEvent.event.protocallNumberCalled
                        );

                        this.api
                            .post('analytics/supportlineclick', {
                                phoneNumber: this.contactGroupTelephone,
                                contactGroupId:
                                    this.organization.contactGroup.id,
                            })
                            .subscribe(
                                (result: any) => {
                                    this.log.debug('Activity logged');
                                },
                                (error: any) => {
                                    this.log.debug(
                                        'Something went wrong with the activity logger.'
                                    );
                                }
                            );

                        this.dialing = true;
                        event.target.dispatchEvent(new MouseEvent('click'));
                    }
                });
        }
    }

    onSelectFilterData(selectedItem: any) {
        this.dateFilter = selectedItem.value;
        this.dateFilterTitle = selectedItem.key;
        this.activeLink = selectedItem.key;
        this.getChartInit(this.dateFilter);
    }

    selectLegend(item) {
        this.selectedChartLegend = item;
        this.updateChart();
    }

    getChartInit(datefilter: any) {
        const Url =
            this.typeAssessment === 'dass'
                ? 'assessments/graph/' + datefilter
                : `assessments/graph/` +
                  datefilter +
                  `?type=${this.typeAssessment}`;
        this.api.get(Url).subscribe(
            (result: any) => {
                this.assessments = Assessment.initializeArray(result.data);
                setTimeout(() => {
                    this.initDasAssessmentChart();
                    this.updateChart();
                }, 3000);
            },
            (error: any) => {
                this.log.error(
                    'Error getting assessment questions. ' + error.message
                );
            }
        );
    }

    initDasAssessmentChart() {
        if (this.assessments) {
            let canvas = this.canvasRef.nativeElement;
            let parent = canvas.parentElement;
            canvas.width = parent.clientWidth;
            canvas.height = parent.clientHeight;
            let chartOptions = {
                layout: {
                    padding: {
                        left: 10,
                        right: 10,
                        top: 0,
                        bottom: 0,
                    },
                },
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            gridLines: {
                                display: true,
                                lineWidth: 0,
                                zeroLineWidth: 1,
                                zeroLineBorderDash: [5, 5],
                                zeroLineBorderDashOffset: 0.0,
                            },
                            type: 'time',
                            time: {
                                unit: 'day' as const,
                                //   displayFormats: {
                                //     day: "MMM D",
                                //   },
                                min: moment(new Date())
                                    .subtract(this.dateFilter, 'days')
                                    .startOf('day')
                                    .format(),
                                max: moment(new Date()).format(),
                                stepSize: 10,
                            },
                            ticks: {
                                fontSize: 12,
                                fontColor: '#6F747D',
                                family: 'Roboto',
                                callback: (value, index) =>
                                    this.getXAxisLabel(value),
                            },
                        },
                    ],
                    yAxes: [
                        {
                            gridLines: {
                                drawBorder: false,
                                lineWidth: 1,
                            },
                            ticks: {
                                fontSize: 12,
                                fontColor: '#6F747D',
                                family: 'Roboto',
                                max: this.getMaxYaxisTicks(),
                                min:
                                    this.typeAssessment === 'mentalwellbeing'
                                        ? 0
                                        : -0.5,
                                stepSize:
                                    this.typeAssessment === 'mentalwellbeing'
                                        ? 10
                                        : 1,
                                callback: (value, index) =>
                                    this.getTranslatedLabelName(value, index),
                            },
                        },
                    ],
                },
            };
            this.chartData = {};
            let ctx = canvas.getContext('2d');
            this.dasAssessmentChart = new Chart(ctx, {
                type: 'line',
                data: this.chartData,
                options: chartOptions,
            });
        }
    }

    getTranslatedLabelName = (value: string, index: number) => {
        const translationKey =
            'dynamicAssessment.' +
            this.typeAssessment +
            '.resultCategory.' +
            value;
        switch (this.typeAssessment) {
            case 'dass':
            case 'depression':
                return Number(value) >= 0 && Number(value) <= 4
                    ? this.translate.instant(translationKey)
                    : ' ';
            case 'resilience':
            case 'alcoholuse':
                return Number(value) >= 0 && Number(value) <= 2
                    ? this.translate.instant(translationKey)
                    : ' ';
            case 'anxiety':
                return Number(value) >= 0 && Number(value) <= 3
                    ? this.translate.instant(translationKey)
                    : ' ';
            case 'mentalwellbeing':
                return Number(value) % 10 === 0 ? value : '';
        }
    };

    getXAxisLabel(value) {
        return moment(new Date(value)).format(
            this.dateFilter <= 30 ? 'MMM D' : 'MMM'
        );
    }

    getMaxYaxisTicks() {
        switch (this.typeAssessment) {
            case 'dass':
                return 4.5;
            case 'resilience':
            case 'mentalwellbeing':
                return this.typeAssessment === 'mentalwellbeing' ? 40 : 2.5;
            case 'alcoholuse':
                return 2.5;
            case 'anxiety':
                return 3.5;
            case 'depression':
                return 4.5;
        }
    }

    getChartData() {
        let dataset: any = [];
        if (this.assessments) {
            switch (this.typeAssessment) {
                case 'dass': {
                    dataset = this.getDassAssessmentDataSet();
                    break;
                }
                case 'mentalwellbeing': {
                    this.selectedChartLegend = 'menatlwellbeing';
                    dataset = this.getMentalWellbeingAssessmentDataSet();
                    break;
                }
            }

            return {
                labels: [],
                datasets: dataset,
            };
        } else {
            return {};
        }
    }
    getMentalWellbeingAssessmentDataSet() {
        const labelTranslation = this.translationData[this.typeAssessment];
        let mentalwellbeingData = [];
        let dataset: any = [];
        this.assessments.forEach((assessment: any) => {
            mentalwellbeingData.push({
                x: moment.parseZone(assessment.created).format('MM-DD-YYYY'),
                y: assessment.resultScore,
            });
        });
        dataset.push({
            label: labelTranslation[this.typeAssessment],
            data: mentalwellbeingData,
            pointStyle: 'circle',
            pointRadius: 1.5,
            borderWidth: 1,
            pointBackgroundColor: '#ED7AE1',
            pointBorderColor: '#ED7AE1',
            pointBorderWidth: 1,
            fill: true,
            lineTension: 0,
            borderColor: 'rgb(237, 122, 225)',
            backgroundColor: this.generateGradient('rgb(237, 122, 225)'),
        });
        return dataset;
    }

    getDassAssessmentDataSet() {
        if (this.assessments) {
            let depressionData = [];
            let anxityData = [];
            let stressData = [];
            let dataset: any = [];
            this.assessments.forEach((assessment: any) => {
                depressionData.push({
                    x: moment
                        .parseZone(assessment.created)
                        .format('YYYY-MM-DD'),
                    y: assessment.depressionlevel,
                });
                anxityData.push({
                    x: moment
                        .parseZone(assessment.created)
                        .format('YYYY-MM-DD'),
                    y: assessment.anxietylevel,
                });
                stressData.push({
                    x: moment
                        .parseZone(assessment.created)
                        .format('YYYY-MM-DD'),
                    y: assessment.stresslevel,
                });
            });

            (this.selectedChartLegend == 'depression' ||
                this.selectedChartLegend == 'all') &&
                dataset.push({
                    label: 'Depression',
                    data: depressionData,
                    pointRadius: 4,
                    pointBackgroundColor: '#53AADC',
                    pointBorderColor: '#53AADC',
                    pointBorderWidth: 1,
                    borderWidth: 1,
                    fill: true,
                    lineTension: 0,
                    backgroundColor: this.generateGradient('rgb(83, 170, 220)'),
                    backgroundImage: './assets/img/welltrack-boost-circle.png',
                    borderColor: 'rgb(83, 170, 220)',
                });
            (this.selectedChartLegend == 'anxity' ||
                this.selectedChartLegend == 'all') &&
                dataset.push({
                    label: 'Anxiety',
                    data: anxityData,
                    pointRadius: 4,
                    borderWidth: 1,
                    pointBackgroundColor: '#ED7AE1',
                    pointBorderColor: '#ED7AE1',
                    pointBorderWidth: 1,
                    fill: true,
                    lineTension: 0,
                    borderColor: 'rgb(237, 122, 225)',
                    backgroundColor:
                        this.generateGradient('rgb(237, 122, 225)'),
                });
            (this.selectedChartLegend == 'stress' ||
                this.selectedChartLegend == 'all') &&
                dataset.push({
                    label: 'Stress',
                    data: stressData,
                    pointRadius: 4,
                    borderWidth: 1,
                    pointBackgroundColor: '#F0AA77',
                    pointBorderColor: '#F0AA77',
                    pointBorderWidth: 1,
                    fill: true,
                    lineTension: 0,
                    borderColor: 'rgb(240, 170, 119)',
                    backgroundColor:
                        this.generateGradient('rgb(240, 170, 119)'),
                });
            return dataset;
        }
    }

    private generateGradient(color: string): CanvasGradient {
        const ctx = this.canvasRef.nativeElement.getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, 0, 250);
        gradient.addColorStop(1, `rgba(${color.slice(4, -1)}, 0)`);
        gradient.addColorStop(0, `rgba(${color.slice(4, -1)}, 0.3)`);
        return gradient;
    }

    updateChart() {
        this.dasAssessmentChart.data = this.getChartData();
        if (this.dateFilter <= 30) {
            this.dasAssessmentChart.options.scales.xAxes[0].time.unit = 'day';
            this.dasAssessmentChart.options.scales.xAxes[0].time.min = moment(
                new Date()
            )
                .subtract(this.dateFilter, 'days')
                .startOf('day')
                .format();
        } else {
            const count = this.dateFilter / 30;
            this.dasAssessmentChart.options.scales.xAxes[0].time.unit = 'month';
            this.dasAssessmentChart.options.scales.xAxes[0].time.min = moment(
                new Date()
            )
                .subtract(count + 1, 'months')
                .startOf('month')
                .format();
        }
        console.log(this.dasAssessmentChart);
        this.dasAssessmentChart.options.scales.xAxes[0].time.stepSize =
            this.dateFilter <= 30 ? 10 : 1;
        this.dasAssessmentChart.update();
    }

    onDateSelected(event: MatDatepickerInputEvent<Date>) {
        this.selectedDate = event.value;
    }

    setMoodPercentageMode(mode) {
        if (mode == 'summary') {
            this.selectedMode = mode;
            this.log.event(AnalyticEvent.event.dashboardMcSummaryView);
        } else if (mode == 'breakdown') {
            this.selectedMode = mode;
            this.log.event(AnalyticEvent.event.dashboardMcBreakdownView);
        }
    }

    setMoodMode(mode) {
        this.selectedMood = mode;
        this.refreshContent();
    }

    setCalendarDate() {
        this.changeDate = moment(this.selectedDate)
            .parseZone()
            .format('YYYY-MM-DD');
        this.refreshContent();
    }

    refreshContent() {
        if (!this.user) {
            return;
        }
        this.refreshGraphWithDate();
    }

    refreshGraphWithDate() {
        let from = moment()
            .add(-1, 'months')
            .startOf('month')
            .format('YYYY-MM-DD');
        let to = moment().add(1, 'months').endOf('month').format('YYYY-MM-DD');
        this.api
            .get(`moodcheck/${this.user.id}`, {
                From: from,
                To: to,
            })
            .subscribe(
                (result: any) => {
                    this.moodchecks = Moodcheck.initializeArray(result.data);
                    this.isEmpty = this.moodchecks.length > 0 ? false : true;
                    let currentDate = moment
                        .parseZone()
                        .format(this.changeDate);
                    if (this.selectedMood === 'Daily') {
                        this.moodcheckDailyViewComp.filterDailyMoodcheckData(
                            this.moodchecks,
                            currentDate
                        );
                        this.moodcheckDailyViewComp.setCalendarDate(
                            this.selectedDate
                        );
                    }
                    if (this.selectedMood === 'Weekly') {
                        setTimeout(() => {
                            this.moodcheckWeeklyViewComp.filterWeeklyMoodcheckData(
                                this.moodchecks,
                                currentDate
                            );
                        }, 2000);
                    }
                    if (this.selectedMood === 'Monthly') {
                        setTimeout(() => {
                            this.moodcheckMonthlyViewComp.filterMonthlyMoodcheckData(
                                this.moodchecks,
                                currentDate
                            );
                        }, 2000);
                    }
                },
                (error: any) => {
                    this.moodchecks = [];
                    this.moodcheckDailyViewComp.filterDailyMoodcheckData(
                        this.moodchecks
                    );
                    this.moodcheckWeeklyViewComp.filterWeeklyMoodcheckData(
                        this.moodchecks
                    );
                    this.moodcheckMonthlyViewComp.filterMonthlyMoodcheckData(
                        this.moodchecks
                    );
                    this.log.error(
                        'Error getting moodchecks. ' + error.message
                    );
                }
            );
    }
    formatDate(date: Date): string {
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
        };
        return date.toLocaleDateString('en-US', options);
    }
    getStartOfWeek(date: Date): string {
        const startOfWeek = new Date(date);
        startOfWeek.setDate(date.getDate() - date.getDay()); // Adjust for Sunday as the start of the week
        return this.datePipe.transform(startOfWeek, 'mediumDate');
    }

    getEndOfWeek(date: Date): string {
        const endOfWeek = new Date(date);
        endOfWeek.setDate(date.getDate() + (6 - date.getDay())); // Adjust for Saturday as the end of the week
        return this.datePipe.transform(endOfWeek, 'mediumDate');
    }

    focusNextItem(event: KeyboardEvent, currentIndex: number) {
        if (event.key === 'Tab') {
            event.preventDefault();
            const nextIndex = (currentIndex + 1) % this.assessmentList.length;

            if (nextIndex === 0) {
                const elementOutsideLoop =
                    document.querySelector('.app-l-dsh__rfp a');
                if (elementOutsideLoop) {
                    (elementOutsideLoop as HTMLElement).focus();
                }
            } else {
                const nextItemElement = document.querySelector(
                    '.app-l-ass__block:nth-child(' + (nextIndex + 1) + ') a'
                );
                if (nextItemElement) {
                    (nextItemElement as HTMLElement).focus();
                }
            }
        }
    }

    assessmentPath(assessmentslistKey) {
        this.router.navigate([
            '/app/assessment/' + assessmentslistKey + '/listing',
        ]);
    }

    goToAssessment() {
        this.router.navigate(['/app/assessments/dass/new']);
    }
    setAssessmentConfig() {
        if (this.user.primaryOrganization && this.user.primaryOrganization.id) {
            // this.isLoaded = true;
            this.assessmentConfig =
                this.user.primaryOrganization.assessmentConfig;
            this.setConfigList();
        }
    }
    setConfigList() {
        this.assessmentList.forEach((defaultAssessment: any) => {
            const assessment = this.assessmentConfig.find(
                item => item.key === defaultAssessment.key
            );
            if (assessment && assessment.enabled) {
                defaultAssessment.enabled = assessment.enabled;
                this.listingAssessments.push(defaultAssessment);
            }
        });
    }
}
